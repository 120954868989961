import React from "react";
import PropTypes from "prop-types";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { Button } from "modules/common/core-ui";

export const Pagination = ({
  onChange,
  value,
  small,
  disabled,
  variant,
  variantColor,
}) => {
  const handlePrev = () => {
    const newPage = value.current_page - 1;
    if (newPage <= 0) return;
    onChange(newPage);
  };
  const handleNext = () => {
    const newPage = value.current_page + 1;
    if (newPage > value.total_pages) return;
    onChange(newPage);
  };
  const commonClass = "h-8 border-2 text-xs";
  const commonProps = {
    size: "custom",
    disabled,
    variant,
    variantColor,
  };

  return (
    <div className="flex justify-between items-center">
      <div className="flex">
        <Button
          {...commonProps}
          className={`${commonClass} px-1 rounded-l rounded-r-none border-r-0`}
          onClick={handlePrev}
        >
          <div className="flex items-center mr-1">
            <FaAngleLeft />
            {!small && <span>Prev</span>}
          </div>
        </Button>

        <Button
          {...commonProps}
          className={`${commonClass} px-2 rounded-l-none rounded-r-none`}
        >
          {value.current_page || 1} of {value.total_pages || 1}
        </Button>

        <Button
          {...commonProps}
          className={`${commonClass} px-1 rounded-r rounded-l-none border-l-0`}
          onClick={handleNext}
        >
          <div className="flex items-center ml-1">
            {!small && <span>Next</span>}
            <FaAngleRight />
          </div>
        </Button>
      </div>
    </div>
  );
}

Pagination.defaultProps = {
  onChange: () => {},
  value: {
    current_page: 1,
    total_pages: 1,
  },
  small: false,
  disabled: false,
  variant: "outline",
  variantColor: "primary",
};

Pagination.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.instanceOf(Object),
  small: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  variantColor: PropTypes.string,
};
