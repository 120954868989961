import React from "react";
import has from "lodash/has";
import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";

export const DisplayErrorMessage = (message) => (
  <p className="text-red-500 text-xs">{message}</p>
);
function ShowError({ name }) {
  const { errors } = useFormContext();
  if (!has(errors, name)) return null;
  return DisplayErrorMessage(errors[name].message);
}

ShowError.defaultProps = {
  name: "",
};
ShowError.propTypes = {
  name: PropTypes.string,
};
export default ShowError;
