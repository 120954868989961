import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { badgeVariant, badgeSize } from "./theme";

const badgeIcon = (Icon, style) => {
  const iconProps = {
    className: style,
  };

  return React.isValidElement(Icon) ? (
    React.cloneElement(Icon, iconProps)
  ) : (
    <Icon {...iconProps} />
  );
};

export const Badge = ({
  children,
  size,
  variant,
  variantColor,
  className,
  leftIcon,
  rightIcon,
}) => {
  const classNames = clsx(
    badgeVariant(variantColor)[variant],
    badgeSize[size],
    className,
  );

  return (
    <span className={classNames}>
      {leftIcon && badgeIcon(leftIcon, "mr-2")}
      {children}
      {rightIcon && badgeIcon(rightIcon, "ml-2")}
    </span>
  );
};

Badge.defaultProps = {
  className: "",
};

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  variantColor: PropTypes.string.isRequired,
  className: PropTypes.string,
  leftIcon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]),
  rightIcon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]),
};
