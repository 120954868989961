import React from "react";
import APITable from "../components/APITable";
import { renderData } from "../renderHelper";
import {
  // API_HOST,
  API_HEADER,
} from "../constants";

const list = [
  {
    status: "REQUIRED",
    title: "Authorization",
    content: () => <>Basic Authorization containing admin credentials registered account in Cashbox. <span className="font-medium">Format: email:password</span></>,
  },
  {
    status: "REQUIRED",
    title: "x-auth-key",
    content: () => <>Dedicated key provided by CashBox team.</>,
  },
];

function SectionAPI() {
  return (
    <>
      {/* HOST */}
      {/* <div className="w-full flex flex-col md:flex-row md:space-x-8 space-y-8 md:space-y-0">
        <div className="w-full">
          <h2 id="api" className="main-title">API</h2>
          <h3 className="subtitle">Host</h3>
          <p className="text-sm">Required per transaction call for performance authorization validation</p>
        </div>
        <div className="w-full lg:sticky lg:top-0 lg:self-start lg:pt-4 lg:-mt-4">
          <APITable
            header="Host Environment"
            format={[
              {
                key: (item) => item.environment,
                label: "ENVIRONMENT",
              },
              {
                key: (item) => item.base_path,
                label: "BASE PATH",
              },
            ]}
            data={API_HOST}
          />
        </div>
      </div>

      <hr className="my-8" /> */}

      <div className="w-full flex flex-col md:flex-row md:space-x-8 space-y-8 md:space-y-0">
        <div className="w-full">
          {/* HEADER */}
          <h2 id="api" className="main-title">API</h2>
          <h3 className="subtitle">Header</h3>
          {renderData(list)}
        </div>

        <div className="w-full lg:sticky lg:top-0 lg:self-start lg:pt-4 lg:-mt-4">
          <APITable
            header="Sample Header"
            format={[
              {
                key: (item) => <p className="text">{item.key}</p>,
                label: "KEY",
              },
              {
                key: (item) => <p className="text">{item.value}</p>,
                label: "VALUE",
              },
            ]}
            data={API_HEADER}
          />
        </div>
      </div>
    </>
  )
}

export default SectionAPI;
