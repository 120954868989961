import { req } from "react-reqq";
import get from "lodash/get";
import { toastSuccess, toastError } from "react-geek-toast";
import Cookie from "js-cookie";
import firebase from "firebase/app";
import * as c from "./constants";
import { encryptObjectToString } from "./helpers";

export const login = (payload, callback = () => {}) => {
  req.post({
    key: c.LOGIN,
    url: "/api/user-profile/sign-in",
    payload,
    onSuccess: (res) => {
      const data = get(res, "response.data", {});
      const newData = {
        ...data,
        user_is_invalid:
          get(data, "role") !== "SUPER ADMIN" &&
          get(res, "response.application_status") !== "APPROVED",
      };
      const role = get(res, "response.role", {});
      const token = get(res, "response.token", {});
      const dataToken = encryptObjectToString(newData);
      const roleToken = encryptObjectToString(role);
      Cookie.set("_profileData", dataToken);
      Cookie.set("_profileRole", roleToken);
      Cookie.set("_token", token);
      toastSuccess(get(res, "response.message") || "Logged in!");
      // const type = _.get(res, "response.authorities.0.authority");
      // const profileData = {
      //   name: `${_.get(res, "response.firstName")}`,
      //   details: _.get(res, "response.subscriberInformation", {}),
      // };
      // Cookie.set("_profileData", profileData);
      // Cookie.set("_token", token);
      // Cookie.set("_userType", type);
      req.set(c.AUTHENTICATION, {
        isAuthenticated: true,
        type: data?.role || "",
      });
      if (callback) callback();
    },
  });
};

const LOGIN_URL_BY_TYPE = {
  OPERATOR: `${process.env.REACT_APP_OPERATOR_DOMAIN}/login`,
  ADMIN: "/login",
};

export const logout = () => {
  req.set(c.AUTHENTICATION, (state) => {
    const type = state[c.AUTHENTICATION]?.type;
    Cookie.remove("_token");
    Cookie.remove("_userType");
    req.reset();
    req.clearCache();
    try {
      firebase.auth().signOut();
    } catch (err) {
      console.log(err); // eslint-disable-line
    }
    window.location.href = LOGIN_URL_BY_TYPE[type] || "/";
    // return {
    //   isAuthenticated: false,
    //   type: null,
    // };
  });
};

export const confirmEmail = (params, onSuccess, onError) => {
  req.get({
    key: c.CONFIRM_EMAIL,
    url: "/api/v1/vendors/registration/confirm-verify-email",
    params,
    onSuccess: (res) => {
      toastSuccess(get(res, "response.message") || "Email has been Confirmed");
      onSuccess(res);
    },
    onError,
  });
};

export const register = (payload, callback) => {
  req.post({
    key: c.REGISTER,
    url: "/api/subscriber/public/sign-up",
    payload,
    onSuccess: (res) => {
      toastSuccess(
        "Registered Successfully! Please check your email to verify your account."
      );
      if (callback) callback();
    },
    onError: (res) => {
      toastError(get(res, "response.message") || "Error.");
    },
  });
};

export const forgotPassword = (email, callback) => {
  req.post({
    key: c.FORGOT_PASSWORD,
    url: `/api/user-profile/forgot-password/${email}`,
    onSuccess: (res) => {
      toastSuccess(get(res, "response.message") || "Email has been sent");
      if (callback) callback();
    }
  })
}

export const resetPassword = (token, payload, callback) => {
  req.put({
    key: c.RESET_PASSWORD,
    url: `/api/user-profile/reset/${token}`,
    headers: (headers) => ({
      ...headers,
      Authorization: `Bearer ${token}`,
    }),
    payload,
    onSuccess: (res) => {
      toastSuccess(get(res, "response.message") || "Password has been reset");
      if (callback) callback();
    },
  });
};
