import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { FaChevronLeft } from "react-icons/fa";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { Button } from "modules/common/core-ui";
// import { handleRegisterModal } from "modules/partial/components/PublicHeader";

const titleClass = "font-medium mb-3";
const contentClass = "text-sm text-gray-700";

const renderList = (list, className = "mb-8") => (
  <ul className={clsx("list-disc pl-8", className)}>
    {list.map(item => <li key={item} className="text-sm text-gray-700">{item}</li>)}
  </ul>
);

function TermsAndConditionModal({ onClose, type }) {
  const history = useHistory();

  const handleRegisterModal = () => {
    history.push("/contact-us");
  };

  return (
    <>
      <Helmet>
        <title>Cashbox - Terms and Condition</title>
      </Helmet>
      <div className="py-2 px-3 border-b">
        <Button
          variant="ghost"
          variantColor="primary"
          onClick={onClose}
        >
          <FaChevronLeft className="mr-2" />Close
        </Button>
      </div>
      <div className="py-4 md:py-6 px-0 md:px-8">
        <div className="text-primary-500 font-medium text-lg md:text-xl mb-8 md:mb-10 px-4">
          Terms and Conditions, and Data Privacy Act
        </div>

        <div className="terms-and-condition overflow-auto px-4">
          {/* TERMS AND CONDITION */}
          <div className={titleClass}>TERMS AND CONDITION</div>
          <p className="text-primary-500 text-sm font-medium mb-3">A. GENERAL</p>
          <p className={clsx(contentClass, "paragraph-indent mb-3")}>Terms and Conditions contained herein on this Web App (the “Web App”) shall govern your use of this WebApp, including all pages which you may be redirected to. These Terms apply in full force and effect to your use of this Web App and by using this Web App, you expressly accept all terms and conditions contained herein in full. You must not use this Web App, if you have any objection to any of these Terms and Conditions.</p>
          <p className={clsx(contentClass, "paragraph-indent mb-8")}>Whenever used in herein, “Web App Owner” shall mean Multisys Technologies Corporation</p>

          <p className="text-primary-500 text-sm font-medium mb-3">B. AUTHORITY</p>
          <p className={clsx(contentClass, "paragraph-indent mb-3")}>By registering in the Web App, you warrant that you are duly authorized to register, request access, and supply information on your behalf. The Web App Owner may at its sole discretion request for additional or supporting documents to prove such authority.</p>
          <p className={clsx(contentClass, "paragraph-indent mb-8")}>You also warrant that all information which will be supplied are verified and true and correct to the best of your knowledge.</p>

          <p className="text-primary-500 text-sm font-medium mb-3">C. USE</p>
          <p className={clsx(contentClass, "paragraph-indent mb-3")}>By having registering and having access to the App, you shall only use it for the following purpose:</p>
          <div className="ml-8">
            <p className={clsx(contentClass, "")}><span className="mr-4">1.</span>Accounts Management</p>
            <p className={clsx(contentClass, "ml-8 mb-3")}><span className="mr-4">a.</span>Creation of role-based subscriber accounts namely: subscriber admin, maker and approver.</p>

            <p className={clsx(contentClass, "")}><span className="mr-4">2.</span>Cash Disbursement</p>
            <p className={clsx(contentClass, "ml-8")}><span className="mr-4">a.</span>Creating disbursement requests for individuals or groups of recipients.</p>
            <p className={clsx(contentClass, "ml-8")}><span className="mr-4">b.</span>Disbursing/sending money to the requested individual or group of recipients.</p>
            <p className={clsx(contentClass, "ml-8 mb-8")}><span className="mr-4">c.</span>Choosing a partner that will process the transfer of cash.</p>
          </div>

          <p className="text-primary-500 text-sm font-medium mb-3">D. RESTRICTIONS</p>
          <p className={clsx(contentClass, "paragraph-indent mb-3")}>You are expressly and emphatically restricted from all of the following:</p>
          {renderList([
            "Selling, sublicensing and/or otherwise commercializing the Web App;",
            "Using the Web App or any of the information in illegal or unlawful activities;",
            "Publicly performing and/or showing any Web App material;",
            "Using this Web App in any way that is, or may be, damaging to this Web App;",
            "Using this Web App in any way that impacts user access to this Web App;",
            "Using this Web App contrary to applicable laws and regulations, or in a way that causes, or may cause, harm to the Web App, or to the Web App Owner or any person or organization;",
            "Engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Web App, or while using this Web App;",
            "Using this Web App to engage in any advertising or marketing;",
            "Certain areas of this Web App are restricted from access by you and by the Web App Owner may further restrict access by you to any areas of this Web App, at any time, in its sole and absolute discretion. Personal information you may have shared for this Web App are confidential.",
          ], "mb-8")}

          <p className="text-primary-500 text-sm font-medium mb-3">E. PROFILE</p>
          {renderList([
            "In this Terms and Conditions, all information shall be treated as confidential. With respect to the information you grant the  Web App Owner a full view of your information (as may be applicable based on the information supplied) and also allow the latter to use the information if needed according to the Data Privacy Act of 2012.",
            "By using and accessing this App, you also agree to the App’s PRIVACY NOTICE and that you agree to exercise the same diligence as the Web App Owner in implementing the App’s Privacy policy.",
          ], "mb-8")}

          <p className="text-primary-500 text-sm font-medium mb-3">F. LIMITATION OF LIABILITY</p>
          <p className={clsx(contentClass, "paragraph-indent mb-8")}>In no event shall the Web App Owner nor any of its officers, directors and employees, be liable to you for anything arising out of or in any way connected with your use of this Web App, whether such liability is under contract, tort or otherwise, and the Web App Owner, including its officers, directors and employees shall not be liable for any indirect, consequential or special liability arising out of or in any way related to your use of this App or any breach therein. The Web App owner is not Liable to the partner’s process of identification such as receivers showing requirements (ID cards) for claiming of cash disbursement. The Web App owner shall not be liable to the activities done on the subscriber’s end such as updating, deleting, adding, rejecting, and approving disbursement requests.</p>

          <p className="text-primary-500 text-sm font-medium mb-3">G. INDEMNIFICATION</p>
          <p className={clsx(contentClass, "paragraph-indent mb-8")}>You hereby indemnify to the fullest extent the Web App Owner from and against any and all liabilities, costs, demands, causes of action, damages and expenses (including reasonable attorney’s fees) arising out of or in any way related to your breach of any of the provisions of these Terms, the and the Privacy Notice.</p>

          <p className="text-primary-500 text-sm font-medium mb-3">H. UPDATE IN TERMS</p>
          <p className={clsx(contentClass, "paragraph-indent mb-8")}>The Web App Owner is permitted to revise these Terms at any time as it sees fit, and by using this App you are expected to review such Terms on a regular basis to ensure you understand all terms and conditions governing use of this Web App.</p>

          <p className="text-primary-500 text-sm font-medium mb-3">I. CONTACT US</p>
          <p className={clsx(contentClass, "paragraph-indent mb-10")}>If you have any question pertaining to this App or any of the Terms and Conditions contained herein, please contact the Web App Owner at inquiry@multisyscorp.com.</p>

          {/* DATA PRIVACY ACT */}
          <div className={titleClass}>DATA PRIVACY ACT</div>
          <p className={clsx(contentClass, "paragraph-indent mb-3")}>CashBox is a platform developed by Multisys Technologies Corporation that aims to help mitigate the spread of the coronavirus (COVID-19) by providing convenient, online methods for cash disbursement. Disbursement is the actual delivery of funds from one party's bank account to another. Bulk Transactions, as the name suggests, are payments that are made electronically to multiple recipients but in a single transaction. With Fidelity bulk transaction service, all our branches can now provide seamless online and real-time service to customers. On the other hand, Individual Transaction is a payment made to a single recipient in a single transaction. Our platform allows you to manage your cash payments in a few clicks. CashBox meets the constant demand for easy and efficient transfer of money through our different partners. With Fidelity bulk transaction service, all our branches can now provide seamless online and real-time service to customers.</p>
          <p className={clsx(contentClass, "paragraph-indent mb-8")}>At Multisys, we recognize the importance of data privacy and are committed to protecting your personal data. In line with this, we ensure that we comply with the Data Privacy Act of 2012 (RA 10173), along with its Implementing Rules and Regulations as well as any other relevant data privacy related laws and issuances by the National Privacy Commission. Furthermore, we only collect and process information that is necessary to fulfill the purpose of our platform.  This Privacy Notice explains what information we collect, process, and share. It also informs you why we do so, and your data privacy rights with regard to your information. </p>

          <div className={titleClass}>HOW YOUR PERSONAL DATA IS COLLECTED</div>
          <p className={clsx(contentClass, "paragraph-indent mb-3")}>No information from subscribers will be collected until they have given their express consent. During the initial registration process, clicking the checkbox will signify that the user has read and agrees to the General Terms and Privacy Conditions.</p>
          <p className={clsx(contentClass, "mb-3")}>The information we collect from our subscribers are as follow:</p>
          <p className={clsx(contentClass, "mb-3")}>Inquiry for Partners (Admin will create their account and login credentials)</p>
          {renderList([
            "Organization Name",
            "Organization Alias",
            "Description",
            "Mobile Number",
            "Valid Email Address",
            "Address",
            "Representative Name",
          ])}

          <p className={clsx(contentClass, "mb-3")}>Registrant for Subscribers</p>
          {renderList([
            "Individual/Organization Name",
            "Mobile Number",
            "Valid Email Address",
            "Address",
            "Representative Name",
          ])}

          <p className={clsx(contentClass, "mb-3")}>Registration for Approver</p>
          {renderList([
            "Full Name",
            "Mobile Number",
            "Email Address",
            "Address",
          ])}

          <p className={clsx(contentClass, "mb-3")}>Registration for Maker </p>
          {renderList([
            "Full Name",
            "Mobile Number",
            "Valid Email Address",
            "Address",
          ])}

          <p className={clsx(contentClass, "mb-3")}>When processing transactions the following information is provided to the receiver of a disbursement:</p>
          {renderList([
            "Reference Number",
            "Receiver Name",
            "Amount",
          ])}
          <div className={titleClass}>HOW WE USE YOUR PERSONAL DATA</div>
          <p className={clsx(contentClass, "paragraph-indent mb-3")}>We will only use your Personal Data if we have valid reasons for doing so. We may not process or use your information without your express consent.</p>
          <p className={clsx(contentClass, "paragraph-indent mb-8")}>Data will be used for the purpose of registering an account within the platform, so that subscribers may access our system where they can use the various functions including disbursement, and money transfer to your chosen recipients. </p>

          <div className={titleClass}>ROLES UNDER SUBSCRIBER</div>
          <p className="text-primary-500 text-sm font-medium mb-3">Subscriber Administrator</p>
          <p className={clsx(contentClass, "paragraph-indent mb-3")}>
            The administrator of the roles under each subscriber and the one who assigns credentials.
            <br />
            The Subscriber Administrator can add funds to the wallet.
          </p>

          <p className="text-primary-500 text-sm font-medium mb-3">Approver</p>
          <p className={clsx(contentClass, "paragraph-indent mb-3")}>
            The one who approves disbursement requests created by the Maker.
            <br />
            The approver can add funds to the wallet.
          </p>

          <p className="text-primary-500 text-sm font-medium mb-3">Maker</p>
          <p className={clsx(contentClass, "paragraph-indent mb-8")}>The one who inputs receiver details and creates disbursement requests.</p>

          <div className={titleClass}>PARTNER</div>
          <p className="text-primary-500 text-sm font-medium mb-3">Partner</p>
          <p className={clsx(contentClass, "paragraph-indent mb-8")}>The entity who will disburse the cashout requests.</p>

          <div className={titleClass}>WHO WE SHARE YOUR DATA WITH</div>
          <p className={clsx(contentClass, "paragraph-indent mb-8")}>The personal data you provide will only be shared with our partnered payment channels in order to process disbursement requests and will not be shared or disclosed to any other third parties or advertisers. All information shall be kept by Multisys Technologies Corporation and held with the utmost confidentiality. </p>

          <div className={titleClass}>HOW LONG YOUR INFORMATION WILL BE KEPT</div>
          <p className={clsx(contentClass, "paragraph-indent mb-3")}>Your data will be retained until 6 months of inactivity. Once your account has been inactive for more than 6 months, you will receive a notice that after 30 days, all of your information will be moved into the archives unless you wish to continue the usage of our system.</p>
          <p className={clsx(contentClass, "paragraph-indent mb-3")}>Your archived data will be kept for a period of 1 year and if there will be no request from the data owner to restore them or reactivate the account, then your data will be completely deleted.</p>
          <p className={clsx(contentClass, "paragraph-indent mb-8")}>We place great importance on the security of the Personal Data that we hold, including the use of physical, technological and organizational measures to ensure your information is protected from unauthorized access and against unlawful processing, accidental loss, alteration, disclosure, destruction and damage. Once the retention period is over or if the data is no longer needed, we will automatically delete your relevant personal information. </p>

          <div className={titleClass}>YOUR RIGHTS AS A DATA SUBJECT</div>
          <p className={clsx(contentClass, "paragraph-indent mb-3")}>As a subscriber of CashBox, you are entitled to the following data privacy rights:</p>
          {renderList([
            "To be informed whether your Personal Information shall be, are being, or have been processed;",
            "To reasonable access any Personal Information collected and processed in duration of employment;",
            "To object or withhold consent with regards to the collection and processing of your Personal Data.",
            "To suspend, withdraw, or order the blocking, removal, or destruction of Personal Data from the relevant company’s filing system;",
            "To dispute the inaccuracy or error in Personal Data, and the relevant company shall correct it immediately and accordingly, upon the request unless the request is vexatious or otherwise unreasonable;",
            "To obtain a copy of the data in an electronic or structured format that is commonly used and allows for further use by the Data Subject;",
            "To be indemnified for any damages sustained pursuant to the provisions of the Data Privacy Act or Other Privacy Laws",
          ])}

          <p className={clsx(contentClass, "paragraph-indent mb-8")}>If you wish to exercise any of the rights set out above or require further information, you may contact us.</p>
          <div className={titleClass}>CONTACT US</div>
          <p className={clsx(contentClass, "paragraph-indent")}>Multisys Technologies is fully committed to protecting and maintaining your privacy. For any questions, concerns or clarifications regarding this privacy policy and how to exercise your rights as a data subject, you may contact our Data Protection Officer at dpo@multisyscorp.com or send a text at 09296949028. You may also get in touch with us at inquiry@multisyscorp.com, or reach us at our main office at (02) 8835 9697.</p>
        </div>
      </div>
      <div className="py-4 md:py-6 px-4 md:px-8 border-t">
        {type === 'view' && (
          <div className="w-full flex flex-row justify-end space-x-4">
            <Button
              className="w-1/2 md:w-32 rounded-full"
              variant="gradient"
              variantColor="primary"
              onClick={onClose}
            >
              CLOSE
            </Button>
          </div>
        )}
        {type === 'register' && (
          <div className="w-full flex flex-row justify-end space-x-4">
            <Button
              className="w-1/2 md:w-32 rounded-full"
              variant="outline"
              variantColor="primary"
              onClick={onClose}
            >
              I DISAGREE
            </Button>
            <Button
              className="w-1/2 md:w-32 rounded-full"
              variant="gradient"
              variantColor="primary"
              onClick={() => {
                handleRegisterModal();
                onClose();
              }}
            >
              I AGREE
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

TermsAndConditionModal.defaultProps = {
  type: 'register', // register || view
};
TermsAndConditionModal.propTypes = {
  onClose: PropTypes.instanceOf(Function).isRequired,
  type: PropTypes.string,
};

export default TermsAndConditionModal;
