import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import clsx from "clsx";
import PhoneInput from "react-phone-input-2";
import { renderLabel } from "./FormHelper";

// const IconDeviceMobile = () => {
//   return (
//     <svg
//       className="w-6 h-6"
//       viewBox="0 0 24 24"
//       stroke="currentColor"
//       fill="none"
//     >
//       <path
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         strokeWidth={2}
//         d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
//       />
//     </svg>
//   );
// };

export const FormInputPhoneNumber = ({
  label,
  subLabel,
  tooltip,

  className,
  disabled,
  formOnChange,
  formValue,
  icon,
  invalidClassName,
  maxLength,
  name,
  required,
  validation,
  validClassName,
  wrapperClassName,
  ...rest
}) => {
  const props = {
    label,
    subLabel,
    tooltip,

    className,
    disabled,
    formOnChange,
    formValue,
    icon,
    invalidClassName,
    maxLength,
    name,
    required,
    validation,
    validClassName,
    wrapperClassName,
    ...rest
  };

  const [country] = React.useState("ph");

  const handleChange = (newValue) => {
    if (maxLength > 0 && `${newValue}`.length > maxLength) return;
    formOnChange(isEmpty(newValue) ? "" : `${newValue}`);
  };
  // const isActive = React.useMemo(() => {
  //   if (isEmpty(formValue)) return false;
  //   return true;
  // }, [formValue]);

  // const isValid = React.useMemo(() => {
  //   if (isEmpty(formValue) || isEmpty(validation)) return true;
  //   const regex = new RegExp(validation);
  //   return regex.test(formValue);
  // }, [formValue, validation]);

  const borderValidation = React.useMemo(() => {
    if (isEmpty(formValue) || isEmpty(validation)) return "";
    const regex = new RegExp(validation);
    return regex.test(formValue) ? validClassName : invalidClassName;
  }, [formValue, validation, validClassName, invalidClassName]);

  const otherProps = React.useMemo(
    () => ({
      // pattern: '[0-9]*',
      onKeyPress: (e) => {
        const charCode = typeof e.which === "undefined" ? e.keyCode : e.which;
        const charStr = String.fromCharCode(charCode);
        if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
      },
    }),
    []
  );

  return (
    <div className={clsx("w-full", wrapperClassName)}>
      {renderLabel(props)}
      <div
        className={`input-wrapper px-0 ${
          icon ? "with-icon" : ""
        } ${borderValidation} ${className}`}
      >
        <PhoneInput
          name={name}
          country={country}
          onChange={handleChange}
          placeholder=""
          value={formValue}
          masks={{
            ph: "...-...-....",
            gb: "....-......",
          }}
          priority={{ ph: 0 }}
          enableSearch={false}
          disableDropdown
          countryCodeEditable={false}
          specialLabel=""
          containerClass="w-full h-full flex items-center -mx-2"
          searchPlaceholder="Search..."
          inputProps={{
            ...otherProps,
            ...rest,
            className: "pl-12",
          }}
          buttonClass=""
        />
        {icon && <div className="icon">{icon}</div>}
      </div>
    </div>
  );
}

FormInputPhoneNumber.defaultProps = {
  label: "",
  subLabel: "",
  tooltip: "",

  className: "",
  disabled: false,
  formOnChange: () => {},
  formValue: "",
  icon: null, // <IconDeviceMobile />,
  invalidClassName: "invalid",
  maxLength: 0,
  required: false,
  validation: "",
  validClassName: "valid",
  wrapperClassName: "",
};

FormInputPhoneNumber.propTypes = {
  label: PropTypes.string,
  subLabel: PropTypes.string,
  tooltip: PropTypes.string,

  className: PropTypes.string,
  disabled: PropTypes.bool,
  formOnChange: PropTypes.func,
  formValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  name: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool]),
  invalidClassName: PropTypes.string,
  maxLength: PropTypes.number,
  required: PropTypes.bool,
  validation: PropTypes.string,
  validClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

export default FormInputPhoneNumber;
