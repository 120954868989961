import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "modules/common/core-ui";

export const ConfirmAlertModal = ({
  label,
  onCloseLabel,
  onYesLabel,
  onClose,
  onYes,
  disabled,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const handleProceed = () => {
    setIsDisabled(true);
    onYes();
    onClose();
  };
  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);
  return (
    <div className="w-full p-5">
      <div className="w-full">{label}</div>
      <div className="w-full mt-10 flex flex-row space-x-4">
        <Button
          className="w-full rounded-full"
          variant="outline"
          variantColor="primary"
          onClick={onClose}
        >
          {onCloseLabel}
        </Button>
        <Button
          className="w-full rounded-full"
          variant="gradient"
          variantColor="primary"
          onClick={handleProceed}
          disabled={isDisabled}
        >
          {onYesLabel}
        </Button>
      </div>
    </div>
  );
}

ConfirmAlertModal.defaultProps = {
  onCloseLabel: "Cancel",
  onYesLabel: "Proceed",
  disabled: false,
};
ConfirmAlertModal.propTypes = {
  label: PropTypes.string.isRequired,
  onCloseLabel: PropTypes.string.isRequired,
  onYesLabel: PropTypes.string.isRequired,
  onClose: PropTypes.instanceOf(Function).isRequired,
  onYes: PropTypes.instanceOf(Function).isRequired,
  disabled: PropTypes.bool.isRequired,
};
