import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  HiQuestionMarkCircle,
  HiInformationCircle,
  HiCheckCircle,
  HiExclamation,
  HiXCircle,
  HiX,
} from 'react-icons/hi';

export const variantColorOptions = [
  "primary",
  "secondary",
  "info",
  "success",
  "danger",
  "warning",
];

const defaultIcons = {
  primary: HiQuestionMarkCircle,
  secondary: HiQuestionMarkCircle,
  info: HiInformationCircle,
  success: HiCheckCircle,
  danger: HiXCircle,
  warning: HiExclamation,
};

export const MessageBox = ({
  content,
  icon,
  onClose,
  title,
  variantColor,
  withIcon,
}) => {
  const renderIcon = () => {
    if (withIcon || icon) {
      const DisplayIcon = icon ? icon : defaultIcons[variantColor];
      return <div><DisplayIcon className="icon text-xl" /></div>
    }
  };

  return (
    <div className={clsx("border rounded p-4 w-full", `message-box-${variantColor}`)}>
      <div className="w-full flex items-start space-x-2">
        {renderIcon()}
        <div className="flex-grow">
          {typeof title === "function" && title()}
          {typeof title === "string" && <p className="text-gray-900 text-md font-medium mb-2">{title}</p>}
          {typeof content === "function" && content()}
          {typeof content === "string" && <p className="text-sm container text-gray-500 leading-wide">{content}</p>}
        </div>
        {onClose && (
          <div>
            <button type="button" onClick={onClose}>
              <HiX className="text-xs text-gray-500" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

MessageBox.defaultProps = {
  content: () => {},
  icon: false,
  variantColor: "primary",
  onClose: false,
  title: () => {},
  withIcon: false,
};

MessageBox.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  icon: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.node,
  ]),
  onClose: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
  ]).isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  variantColor: PropTypes.oneOf(variantColorOptions),
  withIcon: PropTypes.bool,
};

export default MessageBox;
