import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { dark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { renderData, returnBadge } from "../renderHelper";

const list = [
  {
    title: "message",
    dataType: "string",
    content: () => <>Contains response message of Cashbox</>,
  },
  {
    title: "data",
    dataType: "array",
    content: () => <>Contains list of active partners of Cashbox</>,
  },
];

function SectionGetList() {
  return (
    <div className="w-full flex flex-col md:flex-row md:space-x-8 space-y-8 md:space-y-0">
      <div className="w-full">
        {/* GET PARTNER LIST */}
        <h2 id="get-list" className="main-title">Get list of partners</h2>
        <p className="content mb-10">This API will return all active partners of Cashbox to be used for disbursement</p>

        {/* RESPONSE BODY */}
        <h3 className="subtitle">Response Body</h3>
        {renderData(list)}
      </div>

      <div className="w-full lg:sticky lg:top-0 lg:self-start lg:pt-4 lg:-mt-4">
        <div className="rounded-lg overflow-hidden flex flex-col fira-code text-xs text-white">
          <div className="px-4 py-2 bg-primary-500 flex flex-row items-center">
            {returnBadge('GET')}
            <div className="ml-3 break-all">/private/api/subscriber/disbursement/partners</div>
          </div>
          <div className="p-6 code-gray">

          <SyntaxHighlighter style={dark}>
            {
`{
  "data": [
    {
      "provider_alias": "PR1",
      "provider_name": "PARTNER1"
    },
    {
      "provider_alias": "PR2",
      "provider_name": "PARTNER2"
    },
  ],
  "message": "Partner Listed"
}`
            }
          </SyntaxHighlighter>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionGetList;
