import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { get, isEmpty } from "lodash";
import { Pagination } from "modules/common/core-ui";
import { formatNumber } from "modules/common/helper";

export const renderNumber = (path, decimal) => (row) =>
  formatNumber(get(row, path), decimal);

export const Table = ({
  format,
  data,
  isLoading,
  pager,
  onChangePage,
  conditionalPager,
  variant,
  variantColor,
}) => {
  const renderContent = React.useCallback((row, col, rowIndex, colIndex) => {
    if (typeof col.key === "function") return col.key(row, rowIndex, colIndex);
    return get(row, col.key) ?? "";
  }, []);

  const pagerCondition = () => {
    if (!isEmpty(pager)) {
      if (conditionalPager) {
        if ((pager?.total || 0) > pager?.size) {
          return true;
        }
        return false;
      }
      return true;
    }
    return false;
  };
  const withPager = pagerCondition();

  return (
    <div className="w-full h-full relative overflow-hidden">
      <div
        className={clsx(
          "h-full max-w-full w-full overflow-auto relative",
          withPager && "pb-14"
        )}
      >
        <table className="table-auto w-full relative">
          <thead>
            <tr className="">
              {format.map((col, i) => {
                if (!col) return null;
                const key = i;
                return (
                  <th
                    className={clsx(
                      col.className,
                      col.headerClassName ||
                        `bg-gray-100 px-4 py-2 text-xs font-medium text-${variantColor}-500`,
                      col.alignment || "text-left",
                      "whitespace-nowrap sticky top-0"
                    )}
                    key={key}
                    style={{
                      width: col.width || "auto",
                      ...col?.style,
                    }}
                  >
                    {col.label}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td
                  colSpan={format.length}
                  className="px-4 py-2 text-xs text-center text-gray-800"
                >
                  Loading...
                </td>
              </tr>
            ) : (
              <>
                {data.length < 1 ? (
                  <tr>
                    <td
                      colSpan={format.length}
                      className="px-4 py-2 text-xs text-center text-gray-800"
                    >
                      No item
                    </td>
                  </tr>
                ) : (
                  data.map((row, i) => {
                    const rowKey = i;
                    return (
                      <tr
                        key={rowKey}
                        className="bg-white hover:bg-gray-100 focus:bg-gray-200"
                      >
                        {format.map((col, j) => {
                          if (!col) return null;
                          const colKey = j;
                          return (
                            <td
                              className={clsx(
                                col.className,
                                col.valueClassName ||
                                  "font-light px-4 py-2 text-sm border-b",
                                col.alignment || "text-left"
                              )}
                              key={`${rowKey}-${colKey}`}
                              style={{ width: col.width || "auto" }}
                            >
                              {renderContent(row, col, i, j)}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      {withPager && (
        <div className="bg-white sticky bottom-0 w-full flex justify-center py-3">
          <Pagination
            value={{
              current_page: pager?.current_page,
              total_pages: pager?.total_pages,
            }}
            onChange={onChangePage}
            disabled={isLoading}
            variant={variant}
            variantColor={variantColor}
          />
        </div>
      )}
    </div>
  );
}

Table.defaultProps = {
  isLoading: false,
  pager: {},
  onChangePage: () => {},
  conditionalPager: false,
  variant: "outline",
  variantColor: "primary",
};

Table.propTypes = {
  format: PropTypes.instanceOf(Array).isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  isLoading: PropTypes.bool,
  pager: PropTypes.instanceOf(Object),
  onChangePage: PropTypes.instanceOf(Function),
  conditionalPager: PropTypes.bool,
  variant: PropTypes.string,
  variantColor: PropTypes.string,
};
