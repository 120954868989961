import React, { useContext } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Button, showModal, SkeletonLoader } from "modules/common/core-ui";
import { renderLabel, ViewContext } from "./GenericView";
import { defaultProps } from "./constants";

export const ViewImage = ({
  label,
  labelClassName,
  name,
  className,
  cellClassName,
  isLoading,
}) => {
  const { data, labelWidth } = useContext(ViewContext);
  const val =
    typeof _.get(data, name) === "undefined" ? "-" : _.get(data, name);
  const value = typeof name === "function" ? name(data) : val;

  const handleViewImage = (img) => {
    showModal({
      content: () => (
        <div className="px-4 pb-4">
          <div className="w-full">
            <img
              alt="item"
              src={img}
              className="m-auto w-98 "
              style={{ maxHeight: "500px" }}
            />
          </div>
        </div>
      ),
    });
  };
  return (
    <tr>
      {renderLabel(
        {
          cellClassName,
          label,
          labelClassName,
          labelWidth,
        },
        { valign: "top" },
      )}

      <td className={`${className} ${cellClassName}`}>
        {isLoading
          ? <SkeletonLoader isLoaded={false} className="h-5 w-full rounded" />
          : ((_.isEmpty(`${value}`) || typeof value === "object") && typeof name !== "function")
              ? <span>&nbsp;</span>
              : (value !== "-" && (
                <Button
                  variant="solid"
                  variantColor="primary"
                  size="sm"
                  onClick={() => handleViewImage(value)}
                >
                  View Image
                </Button>
                )
              )
        }
        {value === "-" && "No Image Uploaded"}
      </td>
    </tr>
  );
};

ViewImage.defaultProps = defaultProps;

ViewImage.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  labelClassName: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  className: PropTypes.string,
  cellClassName: PropTypes.string,
  isLoading: PropTypes.bool,
};