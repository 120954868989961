import React from "react";
import PropTypes from "prop-types";
import TooltipTrigger from "./TooltipTrigger";

export const Tooltip = ({
  content,
  placement,
  trigger,
  triggerTypes,
  variantColor,
}) => {
  return (
    <TooltipTrigger
      placement={placement}
      trigger={triggerTypes}
      tooltip={({ tooltipRef, getArrowProps, getTooltipProps }) => (
        <div
          {...getTooltipProps({
            ref: tooltipRef,
            className: `tooltip-popper-container p-xl border-none ${variantColor}`,
          })}
        >
          <div
            {...getArrowProps({
              className: `arrow ${variantColor}`,
            })}
          />
          {typeof content === "function" && content()}
          {typeof content === "string" && content}
        </div>
      )}
    >
      {({ triggerRef }) => (
        <div ref={triggerRef}>
          {typeof trigger === "function" && trigger()}
          {typeof trigger === "string" && trigger}
        </div>
      )}
    </TooltipTrigger>
  );
}
Tooltip.defaultProps = {
  placement: "right",
  triggerTypes: "hover",
  variantColor: "dark",
};

Tooltip.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]).isRequired,
  placement: PropTypes.oneOf([
    "top",
    "left",
    "bottom",
    "right",
  ]),
  trigger: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]).isRequired,
  triggerTypes: PropTypes.oneOf([
    "click",
    "hover",
  ]),
  variantColor: PropTypes.string,
};

export default Tooltip;
