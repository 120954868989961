import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { dark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { returnBadge } from "../renderHelper";

function SectionCheckWallet() {
  return (
    <>
      <div className="w-full flex flex-col md:flex-row md:space-x-8 space-y-8 md:space-y-0">
        <div className="w-full">
          <h2 id="disbursement-api" className="main-title">Check Wallet Balance</h2>
          <p className="content">
            This API will return subscriber organization’s current running wallet account balance which can be used for checking of account ability before disbursing
          </p>
        </div>

        <div className="w-full lg:sticky lg:top-0 lg:self-start lg:pt-4 lg:-mt-4">
          <div className="rounded-lg overflow-hidden flex flex-col fira-code text-xs text-white">
            <div className="px-4 py-2 bg-primary-500 flex flex-row items-center">
              {returnBadge('GET')}
              <div className="ml-3 break-all">/private/api/subscriber/balance</div>
            </div>
            <div className="p-6 code-gray">
              <SyntaxHighlighter style={dark}>
                  {
`{
  "data": {
    "wallet_balance": 5000.0,
    "subscriber_name": "CASHBOX"
  },
  "message": "Balance sent"
}`
                  }
              </SyntaxHighlighter>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SectionCheckWallet;
