import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useApiLoading } from "react-reqq";
import {
  Button,
  Field,
  FormInputPassword,
  FormWrapper,
} from "modules/common/core-ui";
import * as actions from "../actions";
import * as c from "../constants";

const validationSchema = (yup) =>
  yup.object().shape({
    current_password: yup.string().required().label("Current password"),
    new_password: yup
      .string()
      .min(8)
      .max(20)
      .required()
      .label("New password"),
    confirm_new_password: yup
      .string()
      .min(8)
      .max(20)
      .required()
      .oneOf([yup.ref("new_password"), null], "Passwords must match")
      .label("Password confirmation"),
  })

function ChangePasswordModal({ onClose, type, callback }) {
  const isLoading = useApiLoading(c.CHANGE_PASSWORD, "patch");

  const handleSubmit = ({ confirm_new_password: _v, ...payload }) => {
    actions.changePassword(payload, (res) => {
      callback(res);
      onClose();
      _.delay(() => {
        window.location.reload();
      }, 3000);
    });
  };
  return (
    <FormWrapper
      defaultValues={{
        current_password: "",
        new_password: "",
        confirm_new_password: "",
      }}
      validation={validationSchema}
      onSubmit={handleSubmit}
      className="flex flex-col space-y-4 p-4"
    >
      {type === "INITIAL" && <p className="text-xs text-gray-500"><span className="font-medium text-red-500 mr-2">NOTE: </span>Please replace your auto generated password with your own password</p>}
      <Field
        component={FormInputPassword}
        name="current_password"
        type="password"
        label="Password"
        withIcon
        withShowPassword
        autoFocus
      />
      <Field
        component={FormInputPassword}
        name="new_password"
        type="password"
        label="New Password"
        withIcon
        withShowPassword
        maxLength={20}
        bottomLabel="(Password length should be between 8 to 20, One numeric character, at least One lowercase and One uppercase character)"
      />
      <Field
        component={FormInputPassword}
        name="confirm_new_password"
        type="password"
        label="Confirm New Password"
        withIcon
        withShowPassword
        maxLength={20}
      />

      <div className="w-full flex space-x-4">
        {type !== "INITIAL" && (
          <Button
            className="w-full rounded-full"
            variant="outline"
            variantColor="primary"
            onClick={onClose}
          >
            Close
          </Button>
        )}
        <Button
          className="w-full rounded-full"
          variant="gradient"
          variantColor="primary"
          type="submit"
          isLoading={isLoading}
        >
          Submit
        </Button>
      </div>
    </FormWrapper>
  );
}

ChangePasswordModal.defaultProps = {
  description: '',
  type: '',
  callback: () => {},
};

ChangePasswordModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  type: PropTypes.string,
  callback: PropTypes.func.isRequired,
};

export default ChangePasswordModal;
