import { req } from "react-reqq";
import { toastSuccess } from "react-geek-toast";
import * as c from "./constants";
import { getProfileData } from "modules/auth/helpers";

export const changePassword = (payload, onSuccess) => {
  req.put({
    key: c.CHANGE_PASSWORD,
    url: `/api/user-profile/update-password/${getProfileData()?.uuid}`,
    payload,
    onSuccess: (res) => {
      toastSuccess("Password Changed!");
      if (typeof onSuccess === "function") onSuccess(res);
    },
  });
};

export const verifyToken = (token = "", onSuccess, onError) => {
  req.put({
    key: c.VERIFY_EMAIL,
    url: `/api/client-subscriber/verify/${token}`,
    onSuccess: () => onSuccess(),
    onError,
  });
};

export const contactUs = (payload, callback) => {
  req.post({
    key: c.CONTACT_US,
    url: '/public/contact-us',
    payload,
    onSuccess: () => {
      if (callback) callback();
      toastSuccess("Message has been sent!");
    },
  });
};

export const temp = () => {};
