import React from "react";
import { Link as AnchorLink } from 'react-scroll';
import { useHistory } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import { Button } from "modules/common/core-ui";
import { ROUTES } from "../constants";

function Header() {
  const [toggle, setToggle] = React.useState(false);
  const history = useHistory();

  const handleBackPage = () => {
    history.push('/');
    setToggle(false);
  };

  const handleOnToggle = (value) => (e) => {
    e.preventDefault();
    setToggle(!value);
  };

  return (
    <header className="fixed top-0 w-full z-20">
      <nav className="flex items-center py-2 bg-primary-100 pr-4">
        <Button
          variant="gradient"
          variantColor="primary"
          className="pl-3 pr-5 py-3 rounded-l-none rounded-r-full"
          onClick={handleBackPage}
          size="sm"
        >
          <FaChevronLeft className="mr-2" />Back
        </Button>

        <h2 className="text-base ml-5">
          Cashbox <span className="text-primary-500 font-medium">API</span>
        </h2>

        <div className="block lg:hidden ml-auto">
          <div
            role="presentation"
            className={`hamburger${toggle ? " is-active" : ""}`}
            id="hamburger-1"
            onClick={handleOnToggle(toggle)}
          >
            <span className="line" />
            <span className="line" />
            <span className="line" />
          </div>
        </div>
      </nav>
      {toggle && (
        <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto bg-primary-100 p-6 h-screen overflow-auto pb-20">
          <div className="text-md lg:flex-grow">
            {ROUTES.map(route => (
              <div key={route.label} className="mb-2">
                <AnchorLink
                  activeClass="text-primary-500 font-medium"
                  to={route.url}
                  spy={true}
                  smooth={true}
                  duration={500}
                  delay={0}
                  containerId="for-developers"
                  offset={-100}
                  className="cursor-pointer text-sm text-gray-600 mb-2"
                  onClick={() => setToggle(false)}
                >
                  {route.label}
                </AnchorLink>
                {(route.list || []).map(item => (
                  <div key={item.label} className="mt-3 ml-3">
                    <AnchorLink
                      activeClass="text-primary-500 font-medium"
                      to={item.url}
                      spy={true}
                      smooth={true}
                      duration={500}
                      delay={0}
                      containerId="for-developers"
                      offset={-100}
                      className="cursor-pointer text-sm text-gray-600"
                      onClick={() => setToggle(false)}
                    >
                      {item.label}
                    </AnchorLink>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
    </header>
  );
}

export default Header;
