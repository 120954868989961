import React from "react";
import { Element as AnchorElement } from "react-scroll";
import { Helmet } from "react-helmet";
import SectionIntroduction from "../components/SectionIntroduction";
import SectionAPI from "../components/SectionAPI";
import SectionGetList from "../components/SectionGetList";
import SectionDisbursementAPI from "../components/SectionDisbursementAPI";
import SectionDisbursementItem from "../components/SectionDisbursementItem";
import SectionCheckWallet from "../components/SectionCheckWallet";
import SectionCallbackService from "../components/SectionCallbackService";
import SectionAppendix from "../components/SectionAppendix";

function ForDevelopersContent() {
  return (
    <>
      <Helmet>
        <title>Cashbox - For Developers</title>
      </Helmet>
      <div className="for-developers-container px-6 lg:px-10 pt-20 lg:pt-12 pb-20">
        {/* INTRODUCTION */}
        <AnchorElement name="section-1">
          <SectionIntroduction />
        </AnchorElement>

        <hr className="my-10" />

        {/* API */}
        <AnchorElement name="section-2">
          <SectionAPI />
        </AnchorElement>

        <hr className="my-10" />

        {/* GET LIST */}
        <AnchorElement name="section-3">
          <SectionGetList />
        </AnchorElement>

        <hr className="my-10" />

        {/* DISBURSEMENT API */}
        <AnchorElement name="section-4">
          <SectionDisbursementAPI />
        </AnchorElement>

        <hr className="my-10" />

        {/* DISBURSEMENT ITEM */}
        <AnchorElement name="section-5">
          <SectionDisbursementItem />
        </AnchorElement>

        <hr className="my-10" />

        {/* CHECK WALLET BALANCE */}
        <AnchorElement name="section-6">
          <SectionCheckWallet />
        </AnchorElement>

        <hr className="my-10" />

        {/* CALLBACK SERVICE */}
        <AnchorElement name="section-7">
          <SectionCallbackService />
        </AnchorElement>

        <hr className="my-10" />

        {/* APPENDIX */}
        <AnchorElement name="section-8">
          <SectionAppendix />
        </AnchorElement>
      </div>
    </>
  )
}

export default ForDevelopersContent;
