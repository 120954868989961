import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

export const SkeletonLoader = ({ isLoaded, color, className, children, ...rest }) => {
  const classNames = clsx("animate-pulse", color, className);

  if (isLoaded) return children;

  return (
    <div className={classNames} {...rest}>
      {children}
    </div>
  );
};

SkeletonLoader.defaultProps = {
  isLoaded: false,
  color: "bg-gray-300",
  className: "",
  children: null
};

SkeletonLoader.propTypes = {
  isLoaded: PropTypes.bool,
  color: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
};
