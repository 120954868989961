import React from "react";
import PropTypes from "prop-types";
import { FaSpinner } from "react-icons/fa";

export const LoadingContent = ({ isLoading, label }) => {
  return (
    isLoading && (
      <div className="h-full w-full flex absolute justify-center items-center z-20 top-0 left-0 text-gray-900">
        <div className="h-full w-full flex absolute bg-gray-400 top-0 left-0 opacity-25"/>
        <FaSpinner className="animate-spin" />
        {label && <span className="ml-2">{label}</span>}
      </div>
    )
  );
}

LoadingContent.defaultProps = {
  label: "Loading..."
};

LoadingContent.propTypes = {
  label: PropTypes.string,
  isLoading: PropTypes.bool.isRequired
};
