import React from "react";
import Buttons from "./components/Buttons";
import Badge from "./components/Badge";

function UIContainer() {
  return (
    <div className="p-10 mt-20">
      <Buttons />
      <Badge />
    </div>
  );
}

export default UIContainer;
