import React from "react";

function SectionIntroduction() {
  return (
    <div className="flex flex-col">
      <h2 id="introduction" className="main-title">Introduction</h2>

      <div className="w-full md:w-3/4 lg:w-2/3 xl:w-1/2 md:pr-4 mb-10">
        {/* Project Background */}
        <h3 className="text-lg font-semibold text-gray-800 mb-5">Project Background</h3>
        <p className="content">
          Cashbox is a cash disbursement platform by Multisys that exclusively integrates with payment gateway providers to enable seamless cash disbursement transactions through Cashbox Partners and Subscribers.
        </p>
      </div>

      {/* Purpose of the Document */}
      <div className="w-full md:w-3/4 lg:w-2/3 xl:w-1/2 md:pr-4 mb-10">
        <h3 className="text-lg font-semibold text-gray-800 mb-5">Purpose of the Document</h3>
        <p className="content">
          The purpose of this document is to provide a comprehensive API documentation for the intended audience of the overall process. This will also serve as a guide on developing the integrations between partners and subscribers to CashBox Platform.
        </p>
      </div>

      {/* Pre-requisite */}
      <div className="w-full md:w-3/4 lg:w-2/3 xl:w-1/2 md:pr-4">
        <h3 className="text-lg font-semibold text-gray-800 mb-5">Pre-requisite</h3>
        <p className="content">
          Before performing transactions with Cashbox, the following are required:
        </p>
        <ul className="mt-4 text-sm">
          <li className="flex mb-2"><div className="w-10 text-center">1.</div><div className="text-justify">Active admin account of organization registered in the system</div></li>
          <li className="flex mb-2"><div className="w-10 text-center">2.</div><div className="text-justify">Authorization key provided by Cashbox team</div></li>
          <li className="flex mb-2"><div className="w-10 text-center">3.</div><div className="text-justify">Sufficient organization wallet balance (for disbursement)</div></li>
        </ul>
      </div>
    </div>
  )
}

export default SectionIntroduction
