import React from "react";
import { renderTransactionStatus } from "modules/common/renderHelper";

const COMMON_CLASS = "mr-2";

function Badge() {
  return (
    <div className="w-full">
      <div className={COMMON_CLASS}>{renderTransactionStatus(null)}</div>
      <div className={COMMON_CLASS}>{renderTransactionStatus("S")}</div>
      <div className={COMMON_CLASS}>{renderTransactionStatus("P")}</div>
      <div className={COMMON_CLASS}>{renderTransactionStatus("R")}</div>
      <div className={COMMON_CLASS}>{renderTransactionStatus("V")}</div>
      <div className={COMMON_CLASS}>{renderTransactionStatus("F")}</div>
      <div className={COMMON_CLASS}>{renderTransactionStatus("D")}</div>
    </div>
  );
}

export default Badge;
