export const ROUTES = [
  {
    label: "Introduction",
    url: "section-1",
  },
  {
    label: "API",
    url: "section-2",
    list: [
      {
        label: "List of Partners",
        url: "section-3"
      },
      {
        label: "Disbursement",
        url: "section-4",
      },
      {
        label: "Get Items per disbursement",
        url: "section-5",
      },
      {
        label: "Check Wallet Balance",
        url: "section-6",
      },
    ]
  },
  {
    label: "Callback Service",
    url: "section-7",
  },
  {
    label: "Appendix",
    url: "section-8",
  },
];

export const API_HOST = [
  { environment: "UAT", base_path: "https://cashbox-ws.multidemos.com" },
  { environment: "PROD", base_path: "" },
];

export const API_HEADER = [
  { key: "Authorization", value: "Basic xxxxxxxxxxxxx" },
  { key: "x-auth-key", value: "xxxxxxxxxxxxx" },
];
