import React from "react";
import PropTypes from "prop-types";

export const NoSelected = ({ message }) => (
  <div className="text-center p-8">
    <div className="text-gray-600">{message}</div>
  </div>
);

NoSelected.propTypes = {
  message: PropTypes.string,
};

NoSelected.defaultProps = {
  message: "No item selected",
};
