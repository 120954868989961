import { parentDirectory as parent } from "../constants";

export const parentDirectory = `${parent}/GenericView`;

export const defaultProps = {
  labelClassName: "text-xs font-semibold",
  className: "text-sm text-gray-800",
  cellClassName: "pr-4 py-2",
  isLoading: false,
  renderContent: false,
};
