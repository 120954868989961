import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import uploadcare from "uploadcare-widget";
import { Button, ImageHolder } from "modules/common/core-ui";
import { renderLabel } from "./FormHelper";

export const FormUploadImage = ({
  label,
  subLabel,
  tooltip,

  crop,
  disabled,
  fileType,
  formOnChange,
  formValue,
  imageProps,
  isImageOnly,
  isMultiple,
  name,
  required,
  tabs,
  useInputFile,
  wrapperClassName,
  ...rest
}) => {
  const props = {
    label,
    subLabel,
    tooltip,

    crop,
    disabled,
    fileType,
    formOnChange,
    formValue,
    imageProps,
    isImageOnly,
    isMultiple,
    name,
    required,
    tabs,
    useInputFile,
    wrapperClassName,
    ...rest
  };

  const fileRef = React.createRef();
  const [isUploading, setIsUploading] = React.useState(false);
  const [progressCount, setProgressCount] = React.useState(0);
  const buttonLabel = _.isEmpty(formValue) ? "Upload Photo" : "Change Photo";

  const handleProgress = ({ state, progress }) => {
    if (state === "uploading") {
      setIsUploading(true);
      setProgressCount(Math.ceil(progress * 100));
    }
    if (state === "uploaded") {
      setIsUploading(true);
      setProgressCount(100);
    }
    if (state === "ready") {
      setIsUploading(false);
      setProgressCount(0);
    }
  };
  const handleDoneMultipleFiles = React.useCallback(
    async (data) => {
      const newData = await data.files();
      const process = (item) =>
        new Promise((r) => {
          item.done((file) => {
            r(file);
          });
        });
      const rawFiles = await Promise.all(newData.map((i) => process(i)));
      formOnChange(rawFiles);
    },
    [formOnChange]
  );
  const handleDone = React.useCallback(
    (data) => {
      const newValue = data?.cdnUrl;
      formOnChange(newValue);
    },
    [formOnChange]
  );
  const uploadingFile = React.useCallback(
    async (data) => {
      if (isMultiple) {
        const multipleProgress = data.promise();
        await multipleProgress.progress(handleProgress);
        handleDoneMultipleFiles(data);
        return;
      }
      data.progress(handleProgress);
      data.done(handleDone);
    },
    [handleDone, handleDoneMultipleFiles, isMultiple]
  );

  const uploadByUploadCare = React.useCallback(
    async (e) => {
      const files = e?.target?.files;
      const imagesOnly = (fileInfo) => {
        const { isImage, mimeType } = fileInfo;
        if (isImage === false) {
          setIsUploading(false);
          setProgressCount(0);
          throw new Error("image");
        }
        if (["image/gif"].indexOf(mimeType) > -1) {
          setIsUploading(false);
          setProgressCount(0);
          throw new Error("image");
        }
      };

      uploadcare
        .openDialog(
          useInputFile ? uploadcare.filesFrom("object", files) : null,
          {
            publicKey: process.env.REACT_APP_UPLOADCARE_KEY,
            tabs,
            previewStep: true,
            crop: crop === "face" ? false : crop,
            multiple: isMultiple,
            imageOnly: isImageOnly,
            validators: isImageOnly ? [imagesOnly] : [],
          }
        )
        .done(uploadingFile);
    },
    [isMultiple, uploadingFile, isImageOnly, fileType, crop, tabs, useInputFile] // eslint-disable-line
  );

  return (
    <div className={wrapperClassName}>
      {renderLabel(props)}
      <div className={imageProps?.wrapperClassName}>
        <ImageHolder className={imageProps?.className} src={formValue} />
      </div>
      <input
        ref={fileRef}
        accept={fileType}
        type="file"
        onChange={uploadByUploadCare}
        hidden
      />
      <div className="flex flex-row space-x-2 justify-center">
        <Button
          variant="solid"
          variantColor="primary"
          onClick={() =>
            useInputFile ? fileRef.current.click() : uploadByUploadCare()
          }
          disabled={disabled || progressCount !== 0}
          size="sm"
        >
          {isUploading ? `Uploading ${progressCount}%` : buttonLabel}
        </Button>
        {!_.isEmpty(formValue) && (
          <Button
            variant="solid"
            variantColor="danger"
            onClick={() => formOnChange("")}
            size="sm"
          >
            Remove Photo
          </Button>
        )}
      </div>
    </div>
  );
}

FormUploadImage.defaultProps = {
  label: "",
  subLabel: "",
  tooltip: "",

  isMultiple: false,
  isImageOnly: true,
  disabled: false,
  useInputFile: false,
  // tabs: ["file", "url", "camera", "facebook"],
  tabs: ["file"],
  fileType: "",
  formOnChange: () => {},
  formValue: "",
  crop: "1:1",
  wrapperClassName: "flex flex-col justify-center mx-2",
  imageProps: {
    wrapperClassName: "w-full flex justify-center mt-2 mb-4",
    className:
      "object-contain flex shadow-md items-center h-32 w-32 rounded-full",
  },
  required: false,
};

FormUploadImage.propTypes = {
  label: PropTypes.string,
  subLabel: PropTypes.string,
  tooltip: PropTypes.string,

  isMultiple: PropTypes.bool,
  isImageOnly: PropTypes.bool,
  useInputFile: PropTypes.bool,
  tabs: PropTypes.arrayOf(PropTypes.string),
  crop: PropTypes.string,
  disabled: PropTypes.bool,
  fileType: PropTypes.string,
  formOnChange: PropTypes.instanceOf(Function),
  formValue: PropTypes.string,
  wrapperClassName: PropTypes.string,
  onChange: PropTypes.instanceOf(Function),
  name: PropTypes.string.isRequired,
  imageProps: PropTypes.instanceOf(Object),
  required: PropTypes.bool,
};

export default FormUploadImage;
