export const defaultButtonStyle =
  "appearance-none select-none whitespace-nowrap leading-tight outline-none focus:outline-none";

export const buttonBaseStyle =
  "inline-flex items-center justify-center align-middle rounded border-solid";

export const solidVariant = {
  primary: "border-primary-500 bg-primary-500 hover:opacity-75 text-white",
  secondary: "border-secondary-500 bg-secondary-500 hover:opacity-75 text-white",
  success: "border-green-700 bg-green-700 hover:opacity-75 text-white",
  warning: "border-yellow-400 bg-yellow-400 hover:opacity-75 text-white",
  danger: "border-red-700 bg-red-700 hover:opacity-75 text-white",
  gray: "border-gray-300 bg-gray-300 hover:opacity-75 text-gray-500",
};

export const outlineVariant = {
  primary: "border-primary-500 bg-transparent text-primary-500 hover:bg-primary-500 hover:text-white active:bg-primary-500",
  secondary: "border-secondary-500 bg-transparent text-secondary-500 hover:bg-secondary-500 hover:text-white active:bg-secondary-500",
  success: "border-green-700 bg-transparent text-green-700 hover:bg-green-700 hover:text-white active:bg-green-700",
  warning: "border-yellow-400 bg-transparent text-yellow-400 hover:bg-yellow-400 hover:text-white active:bg-yellow-400",
  danger: "border-red-700 bg-transparent text-red-700 hover:bg-red-700 hover:text-white active:bg-red-700",
  gray: "border-gray-300 bg-transparent text-gray-400 hover:bg-gray-400 hover:text-white active:bg-gray-400",
};

export const gradientVariant = {
  primary: "text-white gradient-primary border-none",
  secondary: "text-white gradient-secondary border-none",
};

export const ghostVariant = {
  primary: "border-transparent bg-transparent text-primary-500 hover:text-primary-300",
  secondary: "border-transparent bg-transparent text-secondary-500 hover:text-secondary-300",
  success: "border-transparent bg-transparent text-green-700 hover:text-green-500",
  warning: "border-transparent bg-transparent text-yellow-400 hover:text-yellow-200",
  danger: "border-transparent bg-transparent text-red-700 hover:text-red-500",
  gray: "border-transparent bg-transparent text-gray-400 hover:text-gray-200",
  white: "border-transparent bg-transparent text-white hover:text-gray-200",
};

export const linkVariant = {
  primary: "border-transparent bg-transparent underline text-primary-500 hover:text-primary-300",
  secondary: "border-transparent bg-transparent underline text-secondary-500 hover:text-secondary-300",
  success: "border-transparent bg-transparent underline text-green-700 hover:text-green-500",
  warning: "border-transparent bg-transparent underline text-yellow-400 hover:text-yellow-200",
  danger: "border-transparent bg-transparent underline text-red-700 hover:text-red-500",
  gray: "border-transparent bg-transparent underline text-gray-400 hover:text-gray-200",
};

export const lightVariant = {
  primary: "border-primary-100 bg-primary-100 text-primary-500",
  secondary: "border-secondary-100 bg-secondary-100 text-secondary-500",
  success: "border-green-50 bg-green-50 text-green-600",
  warning: "border-yellow-50 bg-yellow-50 text-yellow-400",
  danger: "border-red-50 bg-red-50 text-red-600",
  gray: "border-gray-200 bg-gray-200 text-gray-500",
};

export const lightBorderedVariant = {
  primary: "border-primary-500 bg-primary-100 text-primary-500",
  secondary: "border-secondary-500 bg-secondary-100 text-secondary-500",
  success: "border-green-600 bg-green-50 text-green-600",
  warning: "border-yellow-400 bg-yellow-50 text-yellow-400",
  danger: "border-red-500 bg-red-50 text-red-500",
  gray: "border-gray-300 bg-gray-100 text-gray-500",
};

export const buttonVariant = (variantColor) => ({
  solid: solidVariant[variantColor] || '',
  outline: outlineVariant[variantColor] || '',
  ghost: ghostVariant[variantColor] || '',
  link: linkVariant[variantColor] || '',
  light: lightVariant[variantColor] || '',
  lightBordered: lightBorderedVariant[variantColor] || '',
  gradient: gradientVariant[variantColor] || '',
});

export const buttonColorOptionsByVariant = {
  solid: Object.keys(solidVariant),
  outline: Object.keys(outlineVariant),
  ghost: Object.keys(ghostVariant),
  light: Object.keys(lightVariant),
  lightBordered: Object.keys(lightBorderedVariant),
  link: Object.keys(linkVariant),
  gradient: Object.keys(gradientVariant),
};

export const buttonSize = {
  xs: "px-2 h-6 font-normal text-xs border",
  sm: "px-3 h-8 font-normal text-xs border-2",
  md: "px-4 h-10 font-normal text-sm border-2",
  lg: "px-6 h-12 font-medium text-base border-2",
  custom: "",
};
