import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { isEmpty } from "lodash";

export const FloatingTextArea = ({
  className,
  disabled,
  formOnChange,
  formValue,
  isLowerCase,
  icon,
  label,
  name,
  required,
  rows,
  value,
  type,
  wrapperClassName,
  ...rest
}) => {
  const isActive = React.useMemo(() => {
    if (isEmpty(formValue)) return false;
    return true;
  }, [formValue]);

  return (
    <div
      className={clsx(
        "w-full input-wrapper floating-textarea",
        isActive ? "active" : "",
        icon ? "with-icon" : "",
        wrapperClassName
      )}
    >
      {!isEmpty(label) && (
        <div className="label">
          <div className="content">{label}</div>
        </div>
      )}
      <textarea
        name={name}
        onKeyDown={(e) => {
          if (type === "number" && e.keyCode === 69) e.preventDefault();
        }}
        onChange={(e) => formOnChange(e.target.value, e)}
        value={formValue}
        rows={rows}
        style={{ resize: "none" }}
        {...rest}
      />
      {icon && <div className="icon">{icon}</div>}
    </div>
  );
};
FloatingTextArea.defaultProps = {
  className: "form-control w-full",
  disabled: false,
  formOnChange: () => {},
  formValue: "",
  isLowerCase: false,
  icon: null,
  label: "",
  required: false,
  rows: 5,
  type: "text",
  value: "",
  wrapperClassName: "",
};
FloatingTextArea.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  formOnChange: PropTypes.func,
  formValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  icon: PropTypes.instanceOf(Object),
  isLowerCase: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  rows: PropTypes.number,
  type: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

