/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import clsx from "clsx";
import camera from "assets/img/Icon awesome-camera.svg";

export const ImageHolder = ({ src, defaultSrc, lightBox, name, className, ...rest }) => {
  const [url, setUrl] = React.useState(src || defaultSrc);
  const handleError = () => {
    setUrl(defaultSrc);
  };
  useEffect(() => {
    setUrl(src || defaultSrc);
  }, [src]); // eslint-disable-line

  if (name) {
    return (
      <div className="bg-primary-500 rounded-full w-8 h-8 p-4 flex items-center justify-center text-white font-semibold text-sm ">
        {name.charAt(0).toUpperCase()}
      </div>
    );
  }
  return (
    <>
      {_.isEmpty(src) ? (
        <div className={clsx("bg-gray-300 flex", className)} {...rest}>
          <img
            alt="thumb"
            onError={handleError}
            role="presentation"
            style={{ cursor: lightBox ? "pointer" : "initial" }}
            className="m-auto h-1/4"
            src={url}
          />
        </div>
      ) : (
        <img
          alt="thumb"
          onError={handleError}
          role="presentation"
          style={{ cursor: lightBox ? "pointer" : "initial" }}
          {...rest}
          className={className}
          src={url}
        />
      )}
    </>
  );
}

ImageHolder.propTypes = {
  src: PropTypes.string,
  defaultSrc: PropTypes.string,
  lightBox: PropTypes.bool,
  name: PropTypes.string,
  className: PropTypes.string,
};

ImageHolder.defaultProps = {
  src: "",
  defaultSrc: camera,
  lightBox: false,
  name: "",
  className: "",
};
