import { Button } from "modules/common/core-ui";
import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import CashboxLogo from "assets/img/cashbox-logo.svg";
import { useValidateToken } from "../hooks";
import { Link, useHistory } from "react-router-dom";

function VerifyEmail() {
  const history = useHistory();
  const { isLoading, isVerified, isNotVerified } = useValidateToken();
  const handleOnReturn = () => {
    history.push("/");
  };

  const content = React.useMemo(() => {
    if (isVerified)
      return (
        <>
          <h1 className="text-4xl mt-10 mb-4">Email Verified</h1>
          <div className="text-center">
            <p>Your email address has been verified</p>
            <p>
              You may now close this page or{" "}
              <Link to="/" className="underline text-primary-500">
                back to Homepage
              </Link>{" "}
            </p>
          </div>
        </>
      );

    if (isNotVerified)
      return (
        <>
          <h1 className="text-4xl mt-10 mb-4">
            Failed to verify email address
          </h1>
          <div className="text-center">
            <p>
              You may now close this page or{" "}
              <Link to="/" className="underline text-primary-500">
                back to Homepage
              </Link>{" "}
            </p>
          </div>
        </>
      );

    return (
      <h1 className="text-4xl mt-10 mb-4">Verifying your email address</h1>
    );
  }, [isLoading, isVerified, isNotVerified]); // eslint-disable-line

  return (
    <div
      className="h-screen flex items-center relative bg-white"
      style={{
        zIndex: 99,
      }}
    >
      <div className="p-8 flex justify-between top-0 left-0 absolute w-full">
        <Button
          variant="ghost"
          variantColor="primary"
          leftIcon={FaArrowLeft}
          onClick={handleOnReturn}
        >
          Back to website
        </Button>
      </div>
      {/* BODY */}
      <div className="w-full flex flex-col justify-center items-center">
        <img src={CashboxLogo} alt="logo" className="h-12" />
        {content}
      </div>
    </div>
  );
}

export default VerifyEmail;
