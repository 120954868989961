import React from "react";
import {
  AccessDeniedModal,
  Badge,
  showModal,
  TooltipIcon,
} from "modules/common/core-ui";
import { formatCurrency } from "modules/common/helper";

export const commonStyles = {
  DATE: {
    minWidth: "120px",
  },
};

export const returnAccessDeniedModal = ({
  content,
  title = "Access Denied",
  ...rest
}) => {
  showModal({
    title,
    size: "md",
    content: (onClose) => (
      <AccessDeniedModal onClose={onClose} content={content} {...rest} />
    ),
  });
};

export const renderProfileStatus = (status) => {
  const returnBadge = (variantColor, label) => (
    <Badge
      size="xxs"
      variant="pill"
      variantColor={variantColor}
    >
      {label}
    </Badge>
  );

  const formattedStatus = status.toUpperCase();

  if (
    formattedStatus === "ACTIVE" ||
    formattedStatus === "Y" ||
    +formattedStatus === 1
  ) return returnBadge("success", "ACTIVE");
  if (
    formattedStatus === "INACTIVE" ||
    formattedStatus === "N" ||
    +formattedStatus === 0
  ) return returnBadge("danger", "INACTIVE");
  if (status === "COMPLETE") return returnBadge("success", "VERIFIED");
  if (status === "DISAPPROVED") return returnBadge("black", "DISAPPROVED");
  if (status === "INCOMPLETE") return returnBadge("pending", "UNVERIFIED");
  return <span>{status}</span>;
};

export const renderTransactionStatus = (status) => {
  const returnBadge = (variantColor, label) => (
    <Badge
      size="xs"
      variant="pill"
      variantColor={variantColor}
    >
      {label}
    </Badge>
  );

  const formattedStatus = status
    ? status.replace(" ", "_").toUpperCase()
    : status;

  switch (formattedStatus) {
    // BLACK
    case "CANCELLED":
      return returnBadge("black", "Cancelled");
    case "REFUND":
      return returnBadge("black", "Refund");
    case "DELETE":
      return returnBadge("black", "Deleted");
    case "DISCONTINUED":
      return returnBadge("black", "Discontinued");
    case "F":
      return returnBadge("black", "Failed");
    case "FAILED":
      return returnBadge("black", "Failed");
    case "POST_FAILED":
      return returnBadge("black", "Post Failed");
    case "V":
      return returnBadge("black", "Void");

    // DANGER
    case "REJECTED":
      return returnBadge("danger", "Rejected");
    case "D":
      return returnBadge("danger", "Rejected");

    // INFO
    case "R":
      return returnBadge("info", "Requested");

    // PENDING
    case null:
      return returnBadge("pending", "Pending");
    case "FOR_MAKER_VERIFICATION":
      return returnBadge("pending", "For Maker's Verification");
    case "PENDING":
      return returnBadge("pending", "Pending");
    case "P":
      return returnBadge("pending", "Pending");

    // SUCCESS
    case "APPROVED":
      return returnBadge("success", "Approved");
    case "CLAIMED":
      return returnBadge("success", "Claimed");
    case "COMPLETE":
      return returnBadge("success", "Complete");
    case "POST_READY_FOR_PAYOUT":
      return returnBadge("success", "For Payout");
    case "POST_STARTED":
      return returnBadge("success", "Post Started");
    case "POSTED":
      return returnBadge("success", "Posted");
    case "REASSIGNED":
      return returnBadge("success", "Reassigned");
    case "S":
      return returnBadge("success", "Successful");
    case "SUCCESSFUL":
      return returnBadge("success", "Successful");

    // WARNING
    case "FOR_APPROVER_DISBURSEMENT":
      return returnBadge("warning", "For Approver's Disbursement");

    default:
      return <span>{status}</span>;
  }
};

export const returnIncreasedDecreasedAmount = (
  value = 0,
  newValue = 0,
  oldValue = 0
) => {
  if (newValue > oldValue) {
    return <span className="text-green-500">+ {formatCurrency(value)}</span>;
  }
  if (newValue < oldValue) {
    return <span className="text-red-500">- {formatCurrency(value)}</span>;
  }
  return <span>{formatCurrency(value)}</span>;
};

export const statusTooltip = (status) => {
  const returnTooltipIcon = (label) => (
    <TooltipIcon
      tooltipType="QUESTION"
      content={label}
    />
  );

  const formattedStatus = status
    ? status.replace(" ", "_").toUpperCase()
    : status;

  switch (formattedStatus) {
    case "FOR_MAKER_VERIFICATION":
      return returnTooltipIcon("This group is not yet submitted for approval. The Maker is still capable of modifying the group (Adding, editing and deleting a receiver from the group)");
    case "FOR_APPROVER_DISBURSEMENT":
      return returnTooltipIcon("This group is already submitted for approval. The Maker has no capability of modifying the group (Adding, editing and deleting a receiver from the group)");
    case "POST_STARTED":
      return returnTooltipIcon("This group is already approved. The request was sent to the partner for processing.");
    case "REJECTED":
      return returnTooltipIcon("This group is already reviewed and rejected by the approver.");
    case "DISCONTINUED":
      return returnTooltipIcon("This group is dismissed by the maker. This means that the group can no longer be modified.");
    default:
      break;
  }
};
