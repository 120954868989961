import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import clsx from "clsx";
import {
  FaQuestionCircle,
  FaExclamationTriangle,
  FaExclamationCircle,
} from "react-icons/fa";

const defaultClassName = "cursor-pointer";

const sizes = {
  xs: "text-xs",
  sm: "text-sm",
  md: "text-base",
  lg: "text-lg",
  xl: "text-xl",
  "2xl": "text-2xl",
  "3xl": "text-3xl",
};

export const TooltipIcon = ({ tooltipType, className, size, name, content }) => {
  const baseClass = `${sizes[size]} ${defaultClassName}`;

  const returnIcon = () => {
    switch (tooltipType) {
      case "QUESTION":
        return (
          <FaQuestionCircle
            data-tip
            data-for={name}
            className={clsx(
              baseClass,
              className ? className : "text-primary-500"
            )}
          />
        );
      case "WARNING":
        return (
          <FaExclamationTriangle
            data-tip
            data-for={name}
            className={clsx(
              baseClass,
              className ? className : "text-yellow-500"
            )}
          />
        );
      case "ERROR":
        return (
          <FaExclamationCircle
            data-tip
            data-for={name}
            className={clsx(baseClass, className ? className : "text-red-500")}
          />
        );
      default:
        return (
          <FaQuestionCircle
            data-tip
            data-for={name}
            className={clsx(
              baseClass,
              className ? className : "text-primary-500"
            )}
          />
        );
    }
  };
  return (
    <>
      {returnIcon()}
      <ReactTooltip id={name}>
        <span className="break-words">{content}</span>
      </ReactTooltip>
    </>
  );
}

export const sizesOptions = Object.keys(sizes);
export const tooltipTypeOptions = ["QUESTION", "WARNING", "ERROR"];

TooltipIcon.defaultProps = {
  tooltipType: "QUESTION",
  className: "",
  size: "md",
  name: "tooltip",
  content: "",
};

TooltipIcon.propTypes = {
  tooltipType: PropTypes.oneOf(tooltipTypeOptions),
  className: PropTypes.string,
  size: PropTypes.oneOf(sizesOptions),
  name: PropTypes.string,
  content: PropTypes.string,
};
