import React from "react";
// import PropTypes from 'prop-types';
import app_logo from "assets/img/cashbox-logo.svg";

function Splash() {
  return (
    <div className="fixed h-screen w-screen flex bg-gradient-to-r from-secondary-300 to-primary-300" style={{ zIndex: 3000 }}>
      <div className="m-auto">
        <img className="w-full animate-pulse" src={app_logo} alt="splash" />
      </div>
    </div>
  );
}

export default Splash;
