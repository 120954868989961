import React from "react";
import { Helmet } from "react-helmet";
import { useApiLoading } from "react-reqq";
import {
  Button,
  ConfirmAlertModal,
  Field,
  FloatingInput,
  FloatingTextArea,
  FormInputPhoneNumber,
  FormWrapper,
  showModal,
} from "modules/common/core-ui";
import ContactIllustrator from "assets/img/contact-illustration.svg";
import * as actions from "../actions";
import * as c from "../constants";

const INIT_FORM = {
  name: "",
  email: "",
  mobile: "",
  subject: "CASHBOX Inquiry",
  message: "",
};

const validationSchema = (yup) =>
  yup.object().shape({
    name: yup.string().required().label("Name"),
    email: yup.string().email().required().label("Email"),
    mobile: yup.string().required().label("Mobile Number"),
    subject: yup.string().required().label("Subject"),
    message: yup.string().required().label("Message"),
  });

function ContactUs() {
  const isLoading = useApiLoading(c.CONTACT_US, "post");

  const handleSubmit = (data, { resetValues }) => {
    if (isLoading) return;

    showModal({
      title: "Confirmation",
      size: "md",
      content: (close) => (
        <ConfirmAlertModal
          onClose={close}
          onYes={() => {
            actions.contactUs(data, () => {
              resetValues();
            });
          }}
          label="Are you sure you want to submit this?"
        />
      ),
    });
  };

  return (
    <>
      <Helmet>
        <title>Cashbox - Contact Us</title>
      </Helmet>
      <div className="contact-us flex flex-wrap min-h-screen">
        <div className="w-full container px-10 mx-auto">
          <div className="flex flex-wrap mb-10">
            <div className="w-full md:w-3/5 md:pr-5">
              <h1 className="title-primary tracking-wide font-semibold leading-none mb-4">
                Contact Us!
              </h1>
              <p className="text-gray-600 text-sm">
                Have an inquiry or some feedback for us? Fill out the form to
                contact our team.
              </p>
              <img className="w-full" src={ContactIllustrator} alt="" />
            </div>
            <FormWrapper
              className="w-full md:w-2/5"
              validation={validationSchema}
              onSubmit={handleSubmit}
              defaultValues={INIT_FORM}
            >
              <p className="text-gray-600 text-sm mb-5">
                Fill out this form to get in touch.
              </p>
              <div className="mb-2">
                <Field
                  component={FloatingInput}
                  name="name"
                  label="Name"
                  autoFocus
                />
              </div>
              <div className="mb-2">
                <Field
                  component={FloatingInput}
                  name="email"
                  label="Email Address"
                  type="email"
                />
              </div>
              <div className="mb-2">
                <Field
                  component={FormInputPhoneNumber}
                  name="mobile"
                  className="floating-input"
                  required
                />
              </div>
              <div className="mb-2">
                <Field
                  component={FloatingInput}
                  name="subject"
                  label="Subject"
                  required
                />
              </div>
              <div className="mb-2">
                <Field
                  component={FloatingTextArea}
                  name="message"
                  label="Message"
                  rows={3}
                  required
                />
              </div>

              <div className="flex mt-4">
                <Button
                  className="w-full rounded-full mx-auto"
                  variant="gradient"
                  variantColor="primary"
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </FormWrapper>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
