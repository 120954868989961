import React from "react";
import PropTypes from "prop-types";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import clsx from "clsx";
import { isEmpty } from "lodash";

export const FloatingInput = ({
  className,
  disabled,
  formOnChange,
  formValue,
  icon,
  label,
  name,
  noSymbol,
  required,
  type,
  value,
  withIcon,
  withShowPassword,
  wrapperClassName,
  ...rest
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const isActive = React.useMemo(() => {
    if (isEmpty(formValue)) return false;
    return true;
  }, [formValue]);

  const handleOnShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const returnType = () => {
    if (withShowPassword) {
      return showPassword ? "text" : "password";
    }
    return type;
  };

  return (
    <div
      className={clsx(
        "w-full input-wrapper floating-input",
        isActive ? "active" : "",
        icon ? "with-icon" : "",
        wrapperClassName
      )}
    >
      {!isEmpty(label) && (
        <div className="label">
          <div className="content">{label}</div>
        </div>
      )}
      <input
        name={name}
        onKeyDown={(e) => {
          if (type === "number" && e.keyCode === 69) e.preventDefault();
        }}
        type={returnType()}
        onChange={(e) => {
          const value = e.target.value;

          !disabled &&
            formOnChange(
              noSymbol ? value.replace(/[^a-z0-9_]/gi, "") : value,
              e
            );
        }}
        value={formValue}
        {...rest}
      />
      {icon && <div className="icon">{icon}</div>}
      {withIcon && (
        <>
          {withShowPassword && type === "password" ? (
            <span
              onClick={handleOnShowPassword}
              role="presentation"
              className="cursor-pointer absolute right-2 z-10 text-gray-800 mr-2"
            >
              {showPassword ? (
                <FaEye className="w-5 h-5" />
              ) : (
                <FaEyeSlash className="w-5 h-5" />
              )}
            </span>
          ) : (
            <span className="absolute right-2 top-7">{withIcon}</span>
          )}
        </>
      )}
    </div>
  );
};
FloatingInput.defaultProps = {
  className: "form-control w-full",
  disabled: false,
  formOnChange: () => {},
  formValue: "",
  icon: null,
  label: "",
  noSymbol: false,
  required: false,
  value: "",
  type: "text",
  withIcon: false,
  withShowPassword: false,
  wrapperClassName: "",
};
FloatingInput.propTypes = {
  className: PropTypes.string,
  formOnChange: PropTypes.func,
  formValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  disabled: PropTypes.bool,
  icon: PropTypes.instanceOf(Object),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  noSymbol: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  wrapperClassName: PropTypes.string,
  withIcon: PropTypes.bool,
  withShowPassword: PropTypes.bool,
};
