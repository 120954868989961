import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { formatNumberComma } from "modules/common/helper";
import {
  renderLabel,
  isNumberValid,
  removeNonNumeric,
  withSingleDecimal,
  convertToLimitedDecimalNumber,
  removeZeroFromStart,
  applyValueLimit,
} from "./FormHelper";

export const FormInputAmount = ({
  label,
  subLabel,
  tooltip,

  disabled,
  formOnChange,
  formValue,
  name,
  required,
  value,
  type,
  wrapperClassName,
  ...rest
}) => {
  const props = {
    label,
    subLabel,
    tooltip,

    disabled,
    formOnChange,
    formValue,
    name,
    required,
    value,
    type,
    wrapperClassName,
    ...rest
  };
  const handleChange = (value, e) => {
    const currentValue = value;

    // check number validity
    const isValid = isNumberValid(currentValue);
    if (!isValid) return;

    // restrict to single decimal
    if (!withSingleDecimal(currentValue)) return;
    let newValue = removeNonNumeric(currentValue);

    // add value limit
    newValue = applyValueLimit(newValue, true);

    // restrict to 2 decimal places
    newValue = convertToLimitedDecimalNumber(newValue);

    // remove zero from start
    newValue = removeZeroFromStart(newValue);

    if (newValue === "") newValue = 0;
    formOnChange(newValue, e);
  };

  const formattedDisplay = formatNumberComma(formValue || 0.0);

  return (
    <div className={clsx("w-full", wrapperClassName)}>
      {renderLabel(props)}
      <div
        className={`${
          disabled ? `bg-gray-200` : `bg-white border`
        } flex rounded-lg items-center justify-between w-full input-wrapper`}
      >
        <input
          name={name}
          className={disabled ? "text-gray-600" : "text-gray-900"}
          value={formattedDisplay}
          type={type}
          onChange={(e) => !disabled && handleChange(e.target.value, e)}
          onKeyDown={(e) => {
            if (type === "number" && e.keyCode === 69) e.preventDefault();
          }}
          disabled={disabled}
          {...rest}
        />
      </div>
    </div>
  );
};
FormInputAmount.defaultProps = {
  label: "",
  subLabel: "",
  tooltip: "",

  disabled: false,
  formOnChange: () => {},
  formValue: "",
  required: false,
  type: "text",
  value: "",
  wrapperClassName: "",
};
FormInputAmount.propTypes = {
  label: PropTypes.string,
  subLabel: PropTypes.string,
  tooltip: PropTypes.string,

  disabled: PropTypes.bool,
  formOnChange: PropTypes.func,
  formValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  type: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

export default FormInputAmount;
