import React from "react";
import PropTypes from "prop-types";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export const FormWrapper = ({
  render,
  children,
  validation,
  onSubmit,
  className,
  defaultValues,
}) => {
  const helper = {
    transform: {
      number: (cv) => (!Number.isNaN(cv) ? cv : undefined),
      date: (curr, orig) => (orig === "" ? null : curr),
    },
    decimal:
      (decimals = 2) =>
      (val) => {
        if (!val) return true;

        const value = val.toString().split(".");
        if (value.length > 1) {
          return value[1]?.length < decimals + 1;
        }
        return true;
      },
  };
  const schema =
    validation && typeof validation === "function"
      ? validation(yup, helper)
      : null;
  const attrib = schema
    ? {
        resolver: yupResolver(schema),
      }
    : {};
  const methods = useForm({
    ...attrib,
    defaultValues,
    shouldUnregister: false,
  });
  const { handleSubmit, errors, control, watch, reset, getValues, setValue } =
    methods;
  const resetValues = () => reset(defaultValues);
  const setAllValues = (newValues) => reset(newValues);
  const values = { ...watch() };

  const renderChildren = () => {
    if (render && typeof render === "function" && !children) {
      return render({
        errors,
        control,
        values,
        getValues,
        setAllValues,
        setValue,
        resetValues,
      });
    }

    if (children && typeof children === "function") {
      return children({
        errors,
        control,
        values,
        getValues,
        setAllValues,
        setValue,
        resetValues,
      });
    }
    if (children && typeof children === "object") {
      return children;
    }

    return "";
  };

  const spreadProps = {
    ...methods,
    values,
    defaultValues,
  };
  return (
    <FormProvider {...spreadProps}>
      <form
        className={className}
        onSubmit={handleSubmit(() =>
          onSubmit({ ...defaultValues, ...getValues() }, { resetValues })
        )}
      >
        {renderChildren()}
      </form>
    </FormProvider>
  );
}

FormWrapper.defaultProps = {
  validation: () => {},
  className: "",
  defaultValues: {},
  render: () => null,
  onSubmit: () => {},
  children: null,
};

FormWrapper.propTypes = {
  render: PropTypes.func,
  validation: PropTypes.func,
  onSubmit: PropTypes.func,
  className: PropTypes.string,
  defaultValues: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.node,
  ]),
};
