import { parentDirectory as parent } from "../constants";
import {
  buttonVariant,
  buttonSize,
} from './theme';

export const parentDirectory = `${parent}/Button`;

export const disabledClass = "opacity-25 cursor-not-allowed";
export const sizeOptions = Object.keys(buttonSize);
export const typeOptions = ["button", "submit"];
export const variantOptions = Object.keys(buttonVariant());
export const variantColorOptions = [
  "primary",
  "secondary",
  "success",
  "warning",
  "danger",
  "gray",
];
