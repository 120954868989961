import React, { useState, useCallback, useEffect } from "react";
import moment from "moment";
import Cookie from "js-cookie";
import { debounce, isEmpty, forOwn, get, omitBy, isNil } from "lodash";
import { toastWarning, toastError } from "react-geek-toast";
import { startOfMonth, endOfMonth } from "date-fns";
import * as icn from "./icons";
import { req } from "react-reqq";
import { getProfileData } from "modules/auth/helpers";
import DOMPurify from "dompurify";

export const getCurrentUserDetails = () => {
  const { uuid, full_name } = getProfileData();
  return {
    owner_id: uuid,
    owner_name: full_name,
  };
};

export const addRecordToArray = (
  key = "",
  data = {},
  insertPosition = "start"
) => {
  const newValue = (state) => {
    const existingData = Array.isArray(state[key]) ? state[key] : [];
    return insertPosition === "start"
      ? [data, ...existingData]
      : [...existingData, data];
  };

  req.set(key, newValue);
};

export const updateRecordOnArray = (key = "", data = {}, idKey = "uuid") => {
  req.set(key, (state) => {
    const copyRecord = [...state[key]];
    const index = copyRecord.findIndex((val) => val[idKey] === data[idKey]);
    if (index !== -1) copyRecord[index] = data;
    return copyRecord;
  });
};

export const deleteRecordOnArray = (key = "", id = 0, idKey = "uuid") => {
  req.set(key, (state) =>
    (Array.isArray(state[key]) ? state[key] : []).filter(
      (data) => data[idKey] !== id
    )
  );
};

export const formatNumberComma = (number) => {
  // allows negative values
  const count = (s1, letter) => s1.split(letter).length - 1;
  if (isNil(number)) return 0;
  if ((Number.isNaN(number) && !isEmpty(number)) || count(`${number}`, ".") > 1)
    return number;

  const initValue = number.toString();
  const wholeValue = initValue.split(".")[0];
  const decimalValue = initValue.split(".")[1];
  const processedWholeValue = wholeValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // return if whole number or with decimal
  const newValue =
    count(`${initValue}`, ".") > 0
      ? `${processedWholeValue}.${decimalValue}`
      : processedWholeValue;
  return newValue;
};

export const formatCurrency = (number) => {
  // allows negative values
  const count = (s1, letter) => s1.split(letter).length - 1;
  if (isNil(number)) return 0;
  if ((Number.isNaN(number) && !isEmpty(number)) || count(`${number}`, ".") > 1)
    return number;

  const initValue = number.toString();
  const wholeValue = initValue.split(".")[0];
  const decimalValue = initValue.split(".")[1];
  const processedWholeValue = wholeValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // return if whole number or with decimal
  const newValue =
    count(`${initValue}`, ".") > 0
      ? `${processedWholeValue}.${decimalValue}`
      : processedWholeValue;
  return `₱ ${newValue}`;
};

// export const formatCurrency = (number) =>
//   new Intl.NumberFormat("en", { minimumFractionDigits: 2 }).format(number);

export const parseNumber = (str, default_value = false) => {
  const v = parseFloat(`${str}`.replace(/,/g, ""));
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(v)) return typeof default_value !== "boolean" ? default_value : str;
  return v;
};

export const randomNumber = (min, max) =>
  Math.floor(Math.random() * (max - min) + min);

export const formatNumber = (v, decimal = 2) => {
  try {
    const n = parseNumber(v);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(n)) return v;
    return n.toLocaleString(undefined, {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    });
  } catch (err) {
    return v;
  }
};

export const jsUcOnlyFirst = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const transformIncluded = (x, included) => {
  if (!included || isEmpty(included)) return x;
  const rowIncluded = {};
  forOwn(x.relationships, (v, k) => {
    rowIncluded[k] = Array.isArray(v.data)
      ? v.data.map(
          (z) =>
            included.find(
              (y) => y.type === get(z, "type") && y.id === get(z, "id")
            ) || {}
        )
      : included.find(
          (y) => y.type === get(v, "data.type") && y.id === get(v, "data.id")
        ) || {};
  });
  const { links, relationships, type, ...rest } = x;
  return { ...rest, included: rowIncluded };
};

export const storage = {
  get: (key, defaultValue = false) => {
    try {
      return JSON.parse(sessionStorage.getItem(key));
    } catch (err) {
      return defaultValue;
    }
  },
  set: (key, value) => {
    const newValue = JSON.stringify(value);
    sessionStorage.setItem(key, newValue);
  },
  remove: (key) => {
    sessionStorage.removeItem(key);
  },
};

export const usePersistState = (key, state, config = {}) => {
  const [value, setValue] = useState(storage.get(key) || state);
  const updateState = useCallback(
    debounce((newState) => {
      storage.set(key, newState);
    }, 500),
    []
  );
  useEffect(() => {
    updateState(value);
  }, [value]); // eslint-disable-line
  useEffect(() => {
    return () => {
      if (!config.keepOnUnmount) {
        storage.remove(key);
      }
    };
  }, []); // eslint-disable-line
  return [value, setValue];
};

export const removePersistState = (key) => {
  storage.remove(key);
};

export const toGeoArr = (str, default_value = false) => {
  const arr = (str || "").split(",").map((l) => +l);
  if (Number.isNaN(Number(arr[0])) || Number.isNaN(Number(arr[1])))
    return default_value;
  return arr;
};

export const toGeoStr = (arr) => arr.join(",");

export const loadAPI = (id, src) =>
  new Promise((cb) => {
    const fjs = document.getElementsByTagName("script")[0];
    const js = document.createElement("script");

    if (document.getElementById(id)) {
      cb();
      return;
    }

    js.id = id;
    js.src = src;
    js.onload = cb;
    fjs.parentNode.insertBefore(js, fjs);
  });

/* eslint-disable */
export const csvToJson = (csv) => {
  var lines = csv.split("\n");
  var result = [];
  var headers = lines[0].split(",");

  for (var i = 1; i < lines.length; i++) {
    var obj = {};

    var row = lines[i],
      queryIdx = 0,
      startValueIdx = 0,
      idx = 0;

    if (row.trim() === "") {
      continue;
    }

    while (idx < row.length) {
      /* if we meet a double quote we skip until the next one */
      var c = row[idx];

      if (c === '"') {
        do {
          c = row[++idx];
        } while (c !== '"' && idx < row.length - 1);
      }

      if (
        c === "," ||
        /* handle end of line with no comma */ idx === row.length - 1
      ) {
        /* we've got a value */
        var value = row.substr(startValueIdx, idx - startValueIdx).trim();

        /* skip first double quote */
        if (value[0] === '"') {
          value = value.substr(1);
        }
        /* skip last comma */
        if (value[value.length - 1] === ",") {
          value = value.substr(0, value.length - 1);
        }
        /* skip last double quote */
        if (value[value.length - 1] === '"') {
          value = value.substr(0, value.length - 1);
        }

        var key = headers[queryIdx++];
        obj[key] = value;
        startValueIdx = idx + 1;
      }

      ++idx;
    }

    result.push(obj);
  }
  return result;
};

export const focusElement = (id) => {
  try {
    document.getElementById(id).focus();
  } catch (err) {
    // do nothing...
  }
};

export const latLngDistance = (latLngA, latLngB, unit = "M") => {
  const lat1 = get(latLngA, "0") || "undefined";
  const lon1 = get(latLngA, "1") || "undefined";
  const lat2 = get(latLngB, "0") || "undefined";
  const lon2 = get(latLngB, "1") || "undefined";
  if (
    Number.isNaN(Number(lat1)) ||
    Number.isNaN(Number(lat1)) ||
    Number.isNaN(Number(lat1)) ||
    Number.isNaN(Number(lat1))
  )
    return 0;
  if (lat1 === lat2 && lon1 === lon2) return 0;

  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit === "K") {
    dist *= 1.609344;
  }
  if (unit === "N") {
    dist *= 0.8684;
  }
  return dist;
};

export const transformIncludedDeep = (x, included) => {
  if (!included || isEmpty(included)) return x;
  const rowIncluded = {};
  forOwn(x.relationships, (v, k) => {
    rowIncluded[k] = Array.isArray(v.data)
      ? v.data.map((z) =>
          transformIncludedDeep(
            included.find(
              (y) => y.type === get(z, "type") && y.id === get(z, "id")
            ) || {},
            included
          )
        )
      : transformIncludedDeep(
          included.find(
            (y) => y.type === get(v, "data.type") && y.id === get(v, "data.id")
          ) || {},
          included
        );
  });
  const { links, relationships, type, ...rest } = x;
  return { ...rest, included: rowIncluded };
};

export const removeNull = (obj) =>
  omitBy(obj, (x) => typeof x === "undefined" || x === null);

export const removeEmpty = (obj) => omitBy(obj, (x) => isEmpty(`${x}`));

export const loadAutocompleteApi = debounce(async (onLoad, onError) => {
  try {
    if (!process.env.REACT_APP_GOOGLE_MAP_KEY) {
      toastWarning("API key not provided, autocomplete feature is disabled!");
    }
    await loadAPI(
      "google-maps-autocomplete",
      `https://maps.googleapis.com/maps/api/js?libraries=places&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`
    );
    onLoad();
  } catch (err) {
    onError();
  }
}, 200);

export const generate = (length = 10) => {
  const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let retVal = "";
  for (let i = 0, n = charset.length; i < length; i += 1) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

export const renderMarker = (value) => (
  <div className="text-center">
    <img className="h-10" src={icn.MARKER_ICON[value]} alt="marker" />
  </div>
);

export const openPgiUrl = (url, closeCallback) => {
  const title = "PAYMENT GATEWAY";
  const h = 675;
  const w = 775;
  // Fixes dual-screen position                         Most browsers      Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;

  const prevClassName = document.body.className;
  document.body.className = "pgi-open";
  const infoWrapper = document.createElement("div");
  infoWrapper.innerHTML = DOMPurify.sanitize(
    '<div class="pgi-info"><div>Please complete the payment on the other window.</div></div>'
  );
  document.body.appendChild(infoWrapper);

  const newWindow = window.open(
    url,
    title,
    `scrollbars=yes, width=${w / systemZoom}, height=${
      h / systemZoom
    }, top=${top}, left=${left}`
  );

  const timer = setInterval(() => {
    if (!newWindow || newWindow.closed) {
      clearInterval(timer);
      document.body.className = prevClassName;
      document.body.removeChild(infoWrapper);
      closeCallback();
    }
  }, 500);

  // Puts focus on the newWindow
  if (window.focus && newWindow) newWindow.focus();
};

export const downloadFile = async (endpoint, fileName, callback, method = 'GET', payload) => {
  const baseURL = process.env.REACT_APP_END_POINT;
  const token = Cookie.get("_token");
  try {
    const raw = (method === 'POST' && !_.isNil(payload))
      ? (
        await fetch(`${baseURL}${endpoint}`, {
          method,
          headers: new Headers({
            Authorization: `${token}`,
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify(payload),
        })
      ) : (
        await fetch(`${baseURL}${endpoint}`, {
          method,
          headers: new Headers({
            Authorization: `${token}`,
          }),
        })
      );

    const blob = await raw.blob();

    const successAction = () => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${!_.isNil(fileName) ? fileName : 'filename'}.csv`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      if (!_.isNil(callback)) callback();
    };

    switch (raw.status) {
      case 200:
        successAction();
        break;
      case 401:
        toastError('Unauthorized.');
        window.location = `${window.location.origin}/logout`;
        break;
      case 406:
        toastError('No logs found.');
        if (!_.isNil(callback)) callback();
        break;
      case 500:
        toastError('Internal Error!');
        if (!_.isNil(callback)) callback();
        break;
      default:
        toastError('Error');
        if (!_.isNil(callback)) callback();
    }
  } catch (err) {
    toastError('Download Failed!');
    if (!_.isNil(callback)) callback();
  }
};

export const buildParams = (data) => {
  if (_.isEmpty(data)) return "";
  const newUrl = `?${Object.keys(data).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key]) || ''}`).join('&')}`;
  return newUrl;
};

// DATE ================================================================================================================
export const formatDate = (date, format = "ll", defaultValue = "-") => {
  if (!date) return defaultValue;
  const d = new Date(date);
  if (d.toString() === "Invalid Date") return defaultValue;
  return moment(d).format(format);
};

export const returnDateTimeFormat = "yyyy-MM-DD HH:mm:ss";

export const yearOptions = (minDate) => {
  const d = new Date();
  const startYear = !_.isNil(minDate) ? minDate - 1 : 2000;

  let currYear = moment(d).format("YYYY");
  let yearList = [];

  while (currYear > startYear) {
    const newItem = { value: +currYear, label: +currYear };
    yearList = _.concat(yearList, newItem);
    currYear -= 1;
  }
  return yearList;
};

export const formatDateString = (date) =>
  date ? moment(date).format("YYYY-MM-DD") : "";

export const startOfTheMonth = startOfMonth(new Date());
export const endOfTheMonth = endOfMonth(new Date());

// MONTH
export const returnStartOfMonth = (date = new Date()) =>
  moment(date).startOf("month").format(returnDateTimeFormat);
export const returnEndOfMonth = (date = new Date()) =>
  moment(date).startOf("month").format(returnDateTimeFormat);

// DAY
export const returnStartOfDate = (date = new Date()) =>
  moment(date).startOf("day").format(returnDateTimeFormat);
export const returnEndOfDate = (date = new Date()) =>
  moment(date).endOf("day").format(returnDateTimeFormat);

export const handleUploadFile = async ({
  endpoint,
  payload,
  onSuccess,
  onError,
}) => {
  const token = Cookie.get("_token");
  const fullEndpoint = `${process.env.REACT_APP_END_POINT}${endpoint}`;

  try {
    await fetch(fullEndpoint, {
      method: "POST",
      headers: {
        Authorization: token,
      },
      body: payload,
    })
      .then((res) => res.json())
      .then((res) => {
        onSuccess(res);
      });
  } catch (e) {
    onError();
  }
};

export const getYear = (date) => moment(date).format("YYYY");
export const getMonth = (date) => moment(date).format("MM");
export const getDate = (date) => moment(date).format("DD");
