import React from "react";
import { Helmet } from "react-helmet";
import Banner from "modules/landing/Banner";
import Instructions from "modules/landing/Instructions";
import WhoCanUse from "modules/landing/WhoCanUse";
// import Testimonial from "modules/landing/Testimonial";
function Landing() {
  return (
    <>
      <Helmet>
        <title>Cashbox - Home</title>
      </Helmet>
      <Banner />
      <Instructions />
      <WhoCanUse />
      {/* <Testimonial /> */}
    </>
  );
}

export default Landing;
