export const monthLabels = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

export const SUBSCRIBER_TYPE_OPTIONS = [
  { label: "Organization", value: "ORGANIZATION" },
  { label: "Individual", value: "INDIVIDUAL" },
];

// need for un uniformed status
export const DISBURSEMENT_STATUS_OPTIONS = [
  { label: "Pending", value: "PENDING" },
  { label: "Post ready for payout", value: "POST_READY_FOR_PAYOUT" },
  { label: "Post started", value: "POST_STARTED" },
  { label: "Deleted", value: "delete" },
];

export const RETURN_DISBURSEMENT_STATUS = (status) => {
  const formattedStatus = status
    ? status.replace(" ", "_").toUpperCase()
    : status;

  switch (formattedStatus) {
    case undefined:
      return "PENDING";
    case null:
      return "PENDING";
    case "PENDING":
      return "PENDING";

    case "POST_READY_FOR_PAYOUT":
      return "POST_READY_FOR_PAYOUT";

    case "CANCELLED":
      return "DELETED";
    case "delete":
      return "DELETED";

    case "POST_STARTED":
      return "POST_STARTED";

    case "REJECTED":
      return "REJECTED";

    case "FOR_MAKER_VERIFICATION":
      return "FOR_MAKER_VERIFICATION";
    case "FOR_APPROVER_DISBURSEMENT":
      return "FOR_APPROVER_DISBURSEMENT";
    default:
      return status;
  }
};

export const INCOME_SOURCE_OPTIONS = [
  { label: "Borrowed Funds/Loan", value: "Borrowed Funds/Loan" },
  { label: "Cash Tips", value: "Cash Tips" },
  { label: "Charitable Donations", value: "Charitable Donations" },
  { label: "Gift", value: "Gift" },
  { label: "Inheritance", value: "Inheritance" },
  { label: "Investment Income", value: "Investment Income" },
  { label: "Pension/Government/Welfare", value: "Pension/Government/Welfare" },
  { label: "Salary", value: "Salary" },
  { label: "Sale of Goods/Property/Services", value: "Sale of Goods/Property/Services" },
  { label: "Savings", value: "Savings" },
];

export const ID_TYPE_OPTIONS = [
  { label: "UMID / SSS Card", value: "UMID / SSS Card", requiresExpiry: false },
  { label: "GSIS E-Card", value: "GSIS E-Card", requiresExpiry: false },
  { label: "Government Office and GOCC IDs (AFP, NBI, etc.)", value: "Government Office and GOCC IDs (AFP, NBI, etc.)", requiresExpiry: false },
  { label: "Integrated Bar of the Philippines ID", value: "Integrated Bar of the Philippines ID", requiresExpiry: false },
  { label: "Employee/Company ID", value: "Employee/Company ID", requiresExpiry: false },
  { label: "School ID", value: "School ID", requiresExpiry: false },
  { label: "Senior Citizen ID", value: "Senior Citizen ID", requiresExpiry: false },
  { label: "Voter’s ID", value: "Voter’s ID", requiresExpiry: false },
  { label: "TIN Card", value: "TIN Card", requiresExpiry: false },
  { label: "Certification from NCWDP", value: "Certification from NCWDP", requiresExpiry: false },
  { label: "DSWD Certification", value: "DSWD Certification", requiresExpiry: false },
  { label: "PhilSys ID/PhilID", value: "PhilSys ID/PhilID", requiresExpiry: false },
  { label: "PhilHealth ID", value: "PhilHealth ID", requiresExpiry: false },
  { label: "Passport", value: "Passport", requiresExpiry: true },
  { label: "Driver’s License", value: "Driver’s License", requiresExpiry: true },
  { label: "PRC License", value: "PRC License", requiresExpiry: true },
  { label: "OWWA ID", value: "OWWA ID", requiresExpiry: true },
  { label: "Alien Certification of Registration (ACR)", value: "Alien Certification of Registration (ACR)", requiresExpiry: true },
  { label: "Seaman’s Book", value: "Seaman’s Book", requiresExpiry: true },
  { label: "NBI Clearance", value: "NBI Clearance", requiresExpiry: true },
  { label: "Police Clearance", value: "Police Clearance", requiresExpiry: true },
  { label: "Postal ID", value: "Postal ID", requiresExpiry: true },
  { label: "Barangay Certification", value: "Barangay Certification", requiresExpiry: true },
];

export const OCCUPATION_OPTIONS = [
  { label: "Airline/Maritime Employee", value: "Airline/Maritime Employee" },
  { label: "Art/Entertainment/Media", value: "Art/Entertainment/Media" },
  { label: "Civil/Government Employee", value: "Civil/Government Employee" },
  { label: "Cosmetic/Personal Care", value: "Cosmetic/Personal Care" },
  { label: "Domestic Helper", value: "Domestic Helper" },
  { label: "Driver", value: "Driver" },
  { label: "Hotel/Restaurant/Leisure", value: "Hotel/Restaurant/Leisure" },
  { label: "Housewife/Child Care", value: "Housewife/Child Care" },
  { label: "IT and Tech Professional", value: "IT and Tech Professional" },
  { label: "Laborer- Oil/Gas/Mining", value: "Laborer- Oil/Gas/Mining" },
  { label: "Laborer-Agriculture", value: "Laborer-Agriculture" },
  { label: "Laborer-Construction", value: "Laborer-Construction" },
  { label: "Laborer-Manufacturing", value: "Laborer-Manufacturing" },
  { label: "Law Enforcement/Military", value: "Law Enforcement/Military" },
  { label: "Medical/Health Care", value: "Medical/Health Care" },
  { label: "Non-profit, Volunteer", value: "Non-profit, Volunteer" },
  { label: "Office Professional", value: "Office Professional" },
  { label: "Religious/Church Servant", value: "Religious/Church Servant" },
  { label: "Retail Sales", value: "Retail Sales" },
  { label: "Retired", value: "Retired" },
  { label: "Sales/Insurance/Real Estate", value: "Sales/Insurance/Real Estate" },
  { label: "Science/Research Professional", value: "Science/Research Professional" },
  { label: "Security Guard", value: "Security Guard" },
  { label: "Self-Employed", value: "Self-Employed" },
  { label: "Skilled Trade/Specialist", value: "Skilled Trade/Specialist" },
  { label: "Student", value: "Student" },
  { label: "Teacher/Educator", value: "Teacher/Educator" },
  { label: "Unemployed", value: "Unemployed" },
];

export const POSITION_LEVEL_OPTIONS = [
  { label: "Entry Level", value: "Entry Level" },
  { label: "Mid-Level/Supervisory/Management", value: "Mid-Level/Supervisory/Management" },
  { label: "Owner", value: "Owner" },
  { label: "Senior Level/Executive", value: "Senior Level/Executive" },
];

export const COUNTRY_OPTIONS = [
  { label: "Afghanistan", value: "AF" },
  { label: "Albania", value: "AL" },
  { label: "Algeria", value: "DZ" },
  { label: "American Samoa", value: "AS" },
  { label: "Andorra", value: "AD" },
  { label: "Angola", value: "AO" },
  { label: "Anguilla", value: "AI" },
  { label: "Antarctica", value: "AQ" },
  { label: "Antigua and Barbuda", value: "AG" },
  { label: "Argentina", value: "AR" },
  { label: "Armenia", value: "AM" },
  { label: "Aruba", value: "AW" },
  { label: "Australia", value: "AU" },
  { label: "Austria", value: "AT" },
  { label: "Azerbaijan", value: "AZ" },
  { label: "Bahamas (the)", value: "BS" },
  { label: "Bahrain", value: "BH" },
  { label: "Bangladesh", value: "BD" },
  { label: "Barbados", value: "BB" },
  { label: "Belarus", value: "BY" },
  { label: "Belgium", value: "BE" },
  { label: "Belize", value: "BZ" },
  { label: "Benin", value: "BJ" },
  { label: "Bermuda", value: "BM" },
  { label: "Bhutan", value: "BT" },
  { label: "Bolivia (Plurinational State of)", value: "BO" },
  { label: "Bonaire, Sint Eustatius and Saba", value: "BQ" },
  { label: "Bosnia and Herzegovina", value: "BA" },
  { label: "Botswana", value: "BW" },
  { label: "Bouvet Island", value: "BV" },
  { label: "Brazil", value: "BR" },
  { label: "British Indian Ocean Territory (the)", value: "IO" },
  { label: "Brunei Darussalam", value: "BN" },
  { label: "Bulgaria", value: "BG" },
  { label: "Burkina Faso", value: "BF" },
  { label: "Burundi", value: "BI" },
  { label: "Cabo Verde", value: "CV" },
  { label: "Cambodia", value: "KH" },
  { label: "Cameroon", value: "CM" },
  { label: "Canada", value: "CA" },
  { label: "Cayman Islands (the)", value: "KY" },
  { label: "Central African Republic (the)", value: "CF" },
  { label: "Chad", value: "TD" },
  { label: "Chile", value: "CL" },
  { label: "China", value: "CN" },
  { label: "Christmas Island", value: "CX" },
  { label: "Cocos (Keeling) Islands (the)", value: "CC" },
  { label: "Colombia", value: "CO" },
  { label: "Comoros (the)", value: "KM" },
  { label: "Congo (the Democratic Republic of the)", value: "CD" },
  { label: "Congo (the)", value: "CG" },
  { label: "Cook Islands (the)", value: "CK" },
  { label: "Costa Rica", value: "CR" },
  { label: "Croatia", value: "HR" },
  { label: "Cuba", value: "CU" },
  { label: "Curaçao", value: "CW" },
  { label: "Cyprus", value: "CY" },
  { label: "Czechia", value: "CZ" },
  { label: "Côte d'Ivoire", value: "CI" },
  { label: "Denmark", value: "DK" },
  { label: "Djibouti", value: "DJ" },
  { label: "Dominica", value: "DM" },
  { label: "Dominican Republic (the)", value: "DO" },
  { label: "Ecuador", value: "EC" },
  { label: "Egypt", value: "EG" },
  { label: "El Salvador", value: "SV" },
  { label: "Equatorial Guinea", value: "GQ" },
  { label: "Eritrea", value: "ER" },
  { label: "Estonia", value: "EE" },
  { label: "Eswatini", value: "SZ" },
  { label: "Ethiopia", value: "ET" },
  { label: "Falkland Islands (the) [Malvinas]", value: "FK" },
  { label: "Faroe Islands (the)", value: "FO" },
  { label: "Fiji", value: "FJ" },
  { label: "Finland", value: "FI" },
  { label: "France", value: "FR" },
  { label: "French Guiana", value: "GF" },
  { label: "French Polynesia", value: "PF" },
  { label: "French Southern Territories (the)", value: "TF" },
  { label: "Gabon", value: "GA" },
  { label: "Gambia (the)", value: "GM" },
  { label: "Georgia", value: "GE" },
  { label: "Germany", value: "DE" },
  { label: "Ghana", value: "GH" },
  { label: "Gibraltar", value: "GI" },
  { label: "Greece", value: "GR" },
  { label: "Greenland", value: "GL" },
  { label: "Grenada", value: "GD" },
  { label: "Guadeloupe", value: "GP" },
  { label: "Guam", value: "GU" },
  { label: "Guatemala", value: "GT" },
  { label: "Guernsey", value: "GG" },
  { label: "Guinea", value: "GN" },
  { label: "Guinea-Bissau", value: "GW" },
  { label: "Guyana", value: "GY" },
  { label: "Haiti", value: "HT" },
  { label: "Heard Island and McDonald Islands", value: "HM" },
  { label: "Holy See (the)", value: "VA" },
  { label: "Honduras", value: "HN" },
  { label: "Hong Kong", value: "HK" },
  { label: "Hungary", value: "HU" },
  { label: "Iceland", value: "IS" },
  { label: "India", value: "IN" },
  { label: "Indonesia", value: "ID" },
  { label: "Iran (Islamic Republic of)", value: "IR" },
  { label: "Iraq", value: "IQ" },
  { label: "Ireland", value: "IE" },
  { label: "Isle of Man", value: "IM" },
  { label: "Israel", value: "IL" },
  { label: "Italy", value: "IT" },
  { label: "Jamaica", value: "JM" },
  { label: "Japan", value: "JP" },
  { label: "Jersey", value: "JE" },
  { label: "Jordan", value: "JO" },
  { label: "Kazakhstan", value: "KZ" },
  { label: "Kenya", value: "KE" },
  { label: "Kiribati", value: "KI" },
  { label: "Korea (the Democratic People's Republic of)", value: "KP" },
  { label: "Korea (the Republic of)", value: "KR" },
  { label: "Kuwait", value: "KW" },
  { label: "Kyrgyzstan", value: "KG" },
  { label: "Lao People's Democratic Republic (the)", value: "LA" },
  { label: "Latvia", value: "LV" },
  { label: "Lebanon", value: "LB" },
  { label: "Lesotho", value: "LS" },
  { label: "Liberia", value: "LR" },
  { label: "Libya", value: "LY" },
  { label: "Liechtenstein", value: "LI" },
  { label: "Lithuania", value: "LT" },
  { label: "Luxembourg", value: "LU" },
  { label: "Macao", value: "MO" },
  { label: "Madagascar", value: "MG" },
  { label: "Malawi", value: "MW" },
  { label: "Malaysia", value: "MY" },
  { label: "Maldives", value: "MV" },
  { label: "Mali", value: "ML" },
  { label: "Malta", value: "MT" },
  { label: "Marshall Islands (the)", value: "MH" },
  { label: "Martinique", value: "MQ" },
  { label: "Mauritania", value: "MR" },
  { label: "Mauritius", value: "MU" },
  { label: "Mayotte", value: "YT" },
  { label: "Mexico", value: "MX" },
  { label: "Micronesia (Federated States of)", value: "FM" },
  { label: "Moldova (the Republic of)", value: "MD" },
  { label: "Monaco", value: "MC" },
  { label: "Mongolia", value: "MN" },
  { label: "Montenegro", value: "ME" },
  { label: "Montserrat", value: "MS" },
  { label: "Morocco", value: "MA" },
  { label: "Mozambique", value: "MZ" },
  { label: "Myanmar", value: "MM" },
  { label: "Namibia", value: "NA" },
  { label: "Nauru", value: "NR" },
  { label: "Nepal", value: "NP" },
  { label: "Netherlands (the)", value: "NL" },
  { label: "New Caledonia", value: "NC" },
  { label: "New Zealand", value: "NZ" },
  { label: "Nicaragua", value: "NI" },
  { label: "Niger (the)", value: "NE" },
  { label: "Nigeria", value: "NG" },
  { label: "Niue", value: "NU" },
  { label: "Norfolk Island", value: "NF" },
  { label: "Northern Mariana Islands (the)", value: "MP" },
  { label: "Norway", value: "NO" },
  { label: "Oman", value: "OM" },
  { label: "Pakistan", value: "PK" },
  { label: "Palau", value: "PW" },
  { label: "Palestine, State of", value: "PS" },
  { label: "Panama", value: "PA" },
  { label: "Papua New Guinea", value: "PG" },
  { label: "Paraguay", value: "PY" },
  { label: "Peru", value: "PE" },
  { label: "Philippines", value: "PH" },
  { label: "Pitcairn", value: "PN" },
  { label: "Poland", value: "PL" },
  { label: "Portugal", value: "PT" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Qatar", value: "QA" },
  { label: "Republic of North Macedonia", value: "MK" },
  { label: "Romania", value: "RO" },
  { label: "Russian Federation (the)", value: "RU" },
  { label: "Rwanda", value: "RW" },
  { label: "Réunion", value: "RE" },
  { label: "Saint Barthélemy", value: "BL" },
  { label: "Saint Helena, Ascension and Tristan da Cunha", value: "SH" },
  { label: "Saint Kitts and Nevis", value: "KN" },
  { label: "Saint Lucia", value: "LC" },
  { label: "Saint Martin (French part)", value: "MF" },
  { label: "Saint Pierre and Miquelon", value: "PM" },
  { label: "Saint Vincent and the Grenadines", value: "VC" },
  { label: "Samoa", value: "WS" },
  { label: "San Marino", value: "SM" },
  { label: "Sao Tome and Principe", value: "ST" },
  { label: "Saudi Arabia", value: "SA" },
  { label: "Senegal", value: "SN" },
  { label: "Serbia", value: "RS" },
  { label: "Seychelles", value: "SC" },
  { label: "Sierra Leone", value: "SL" },
  { label: "Singapore", value: "SG" },
  { label: "Sint Maarten (Dutch part)", value: "SX" },
  { label: "Slovakia", value: "SK" },
  { label: "Slovenia", value: "SI" },
  { label: "Solomon Islands", value: "SB" },
  { label: "Somalia", value: "SO" },
  { label: "South Africa", value: "ZA" },
  { label: "South Georgia and the South Sandwich Islands", value: "GS" },
  { label: "South Sudan", value: "SS" },
  { label: "Spain", value: "ES" },
  { label: "Sri Lanka", value: "LK" },
  { label: "Sudan (the)", value: "SD" },
  { label: "Suriname", value: "SR" },
  { label: "Svalbard and Jan Mayen", value: "SJ" },
  { label: "Sweden", value: "SE" },
  { label: "Switzerland", value: "CH" },
  { label: "Syrian Arab Republic", value: "SY" },
  { label: "Taiwan (Province of China)", value: "TW" },
  { label: "Tajikistan", value: "TJ" },
  { label: "Tanzania, United Republic of", value: "TZ" },
  { label: "Thailand", value: "TH" },
  { label: "Timor-Leste", value: "TL" },
  { label: "Togo", value: "TG" },
  { label: "Tokelau", value: "TK" },
  { label: "Tonga", value: "TO" },
  { label: "Trinidad and Tobago", value: "TT" },
  { label: "Tunisia", value: "TN" },
  { label: "Turkey", value: "TR" },
  { label: "Turkmenistan", value: "TM" },
  { label: "Turks and Caicos Islands (the)", value: "TC" },
  { label: "Tuvalu", value: "TV" },
  { label: "Uganda", value: "UG" },
  { label: "Ukraine", value: "UA" },
  { label: "United Arab Emirates (the)", value: "AE" },
  { label: "United Kingdom of Great Britain and Northern Ireland (the)", value: "GB" },
  { label: "United States Minor Outlying Islands (the)", value: "UM" },
  { label: "United States of America (the)", value: "US" },
  { label: "Uruguay", value: "UY" },
  { label: "Uzbekistan", value: "UZ" },
  { label: "Vanuatu", value: "VU" },
  { label: "Venezuela (Bolivarian Republic of)", value: "VE" },
  { label: "Viet Nam", value: "VN" },
  { label: "Virgin Islands (British)", value: "VG" },
  { label: "Virgin Islands (U.S.)", value: "VI" },
  { label: "Wallis and Futuna", value: "WF" },
  { label: "Western Sahara", value: "EH" },
  { label: "Yemen", value: "YE" },
  { label: "Zambia", value: "ZM" },
  { label: "Zimbabwe", value: "ZW" },
  { label: "Åland Islands", value: "AX" },
];
