import React from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import PropTypes from "prop-types";
import clsx from "clsx";
import { renderLabel } from "./FormHelper";

export const FormInputPassword = ({
  label,
  subLabel,
  tooltip,

  disabled,
  formOnChange,
  formValue,
  noSymbol,
  name,
  required,
  type,
  value,
  withIcon,
  withShowPassword,
  wrapperClassName,
  ...rest
}) => {
  const props = {
    label,
    subLabel,
    tooltip,

    disabled,
    formOnChange,
    formValue,
    noSymbol,
    name,
    required,
    type,
    value,
    withIcon,
    withShowPassword,
    wrapperClassName,
    ...rest
  };
  const [showPassword, setShowPassword] = React.useState(false);

  const handleOnShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const returnType = () => {
    if (withShowPassword) {
      return showPassword ? "text" : "password";
    }
    return type;
  };

  return (
    <div className={clsx("w-full", wrapperClassName)}>
      {renderLabel(props)}
      <div
        className={`${
          disabled ? `bg-gray-200` : `bg-white border`
        } flex rounded-lg items-center justify-between w-full input-wrapper`}
      >
        <input
          name={name}
          className={disabled ? "text-gray-600" : "text-gray-900"}
          value={formValue}
          type={returnType()}
          autofill="none"
          onChange={(e) => {
            const value = e.target.value;

            !disabled &&
              formOnChange(
                noSymbol ? value.replace(/[^a-z0-9_]/gi, "") : value,
                e
              );
          }}
          onKeyDown={(e) => {
            if (type === "number" && e.keyCode === 69) e.preventDefault();
          }}
          disabled={disabled}
          {...rest}
        />
        {withIcon && (
          <>
            {withShowPassword && type === "password" ? (
              <span
                onClick={handleOnShowPassword}
                role="presentation"
                className="cursor-pointer absolute right-2 z-10 text-gray-800 mr-2"
              >
                {showPassword ? (
                  <FaEye className="w-5 h-5" />
                ) : (
                  <FaEyeSlash className="w-5 h-5" />
                )}
              </span>
            ) : (
              <span className="absolute right-2 top-7">{withIcon}</span>
            )}
          </>
        )}
      </div>
    </div>
  );
};
FormInputPassword.defaultProps = {
  label: "",
  subLabel: "",
  tooltip: "",

  disabled: false,
  formOnChange: () => {},
  formValue: "",
  noSymbol: false,
  required: false,
  type: "password",
  value: "",
  withIcon: false,
  withShowPassword: false,
  wrapperClassName: "",
};
FormInputPassword.propTypes = {
  label: PropTypes.string,
  subLabel: PropTypes.string,
  tooltip: PropTypes.string,

  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  formOnChange: PropTypes.func,
  formValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  noSymbol: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  withIcon: PropTypes.bool,
  withShowPassword: PropTypes.bool,
  wrapperClassName: PropTypes.string,
};

export default FormInputPassword;
