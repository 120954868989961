import React from "react";
import PropTypes from "prop-types";
import EmptyBox from "./empty-box.png";

export const EmptyState = ({ label, className, size }) => {
  return (
    <div
      className={`flex flex-wrap h-full w-full items-center justify-center ${className}`}
    >
      {size === "lg" && (
        <div style={{ maxWidth: "300px" }} className="mb-10">
          <img alt="" src={EmptyBox} className="w-full mb-3" />
          <div className="text-center w-full">{label}</div>
        </div>
      )}
      {size === "md" && label}
    </div>
  );
}

EmptyState.defaultProps = {
  label: "There are no items found.",
  className: "",
  size: "md"
};

EmptyState.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string
};

