import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { get } from "lodash";

function APITable({
  format,
  data,
  header,
}) {
  const renderContent = React.useCallback((row, col, rowIndex, colIndex) => {
    if (typeof col.key === "function") return col.key(row, rowIndex, colIndex);
    return get(row, col.key) ?? "";
  }, []);

  return (
    <div className="w-full relative overflow-hidden rounded-lg">
      <div className="max-w-full w-full relative">
        {header && (
          <div
            className="px-4 py-2 rounded-t-lg border-t border-l border-r border-gray-300"
            style={{ background: "transparent linear-gradient(180deg, #FAFAFA 0%, #F4F4F4 100%) 0% 0% no-repeat padding-box" }}
          >
            <span className="text-primary-500 text-sm">{header}</span>
          </div>
        )}
        <table className="table-auto w-full relative">
          <thead>
            <tr className="">
              {format.map((col, i) => {
                if (!col) return null;
                const key = i;
                return (
                  <th
                    className={clsx(
                      col.className,
                      col.headerClassName ||
                        `code-gray px-4 py-2 text-sm text-gray-100 font-normal border border-gray-500`,
                      col.alignment || "text-left",
                      "whitespace-nowrap sticky top-0"
                    )}
                    key={key}
                    style={{
                      width: col.width || "auto",
                      ...col?.style,
                    }}
                  >
                    {col.label}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data.length < 1 ? (
              <tr>
                <td
                  colSpan={format.length}
                  className="px-4 py-2 text-xs text-center text-gray-100"
                >
                  No item
                </td>
              </tr>
            ) : (
              data.map((row, i) => {
                const rowKey = i;
                return (
                  <tr
                    key={rowKey}
                    className="code-gray"
                  >
                    {format.map((col, j) => {
                      if (!col) return null;
                      const colKey = j;
                      return (
                        <td
                          className={clsx(
                            col.className,
                            col.valueClassName ||
                              "text-gray-100 font-light px-4 py-2 text-sm border border-gray-500",
                            col.alignment || "text-left"
                          )}
                          key={`${rowKey}-${colKey}`}
                          style={{ width: col.width || "auto" }}
                        >
                          {renderContent(row, col, i, j)}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

APITable.propTypes = {
  format: PropTypes.instanceOf(Array).isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  header: PropTypes.string.isRequired,
};

export default React.memo(APITable);
