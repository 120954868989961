import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { FaChevronDown } from "react-icons/fa";
import camera from "assets/img/Icon awesome-camera.svg";
import { renderLabel } from "./FormHelper";

const renderContent = (row) => (
  <div className="w-full flex flex-row items-center p-2">
    {row.image ? (
      <div className="rounded-full h-10 w-10 overflow-hidden">
        <img alt="" src={row?.image} className="h-10 w-10" />
      </div>
    ) : (
      <div className="bg-gray-300 p-2 rounded-full h-10 w-10">
        <img alt="" src={camera} />
      </div>
    )}
    <div>
      <div className="w-full h-full flex flex-col ml-3">
        <p className="break-all text-sm text-gray-700 text-left">
          {row?.label}
        </p>
      </div>
    </div>
  </div>
);

export const FormInputSelectImage = ({
  label,
  subLabel,
  tooltip,

  disabled,
  emptyPlaceholder,
  formOnChange,
  formValue,
  name,
  options,
  placeholder,
  required,
  wrapperClassName,
  ...rest
}) => {
  const props = {
    label,
    subLabel,
    tooltip,

    disabled,
    emptyPlaceholder,
    formOnChange,
    formValue,
    name,
    options,
    placeholder,
    required,
    wrapperClassName,
    ...rest
  };
  const [isToggled, setIsToggled] = useState(false);

  const currentValue = options.find((item) => item.value === formValue);

  const clicker = React.useCallback((e) => {
    try {
      const elem = document.getElementById(name);
      if (e.target !== elem && !elem.contains(e.target)) {
        setIsToggled(false);
        document.removeEventListener("click", clicker);
      }
    } catch (err) {
      // do nothing...
    }
  }, []); // eslint-disable-line

  const handleOpenToggle = (evt) => {
    if (disabled) return;
    evt.preventDefault();
    setIsToggled(!isToggled);
    document.addEventListener("click", clicker);
  };
  const handleCloseToggle = () => {
    setIsToggled(false);
    document.removeEventListener("click", clicker);
  };

  const handleOnChange = (data) => {
    if (disabled) return;
    formOnChange(data?.value);
    handleCloseToggle();
  };

  return (
    <div className={clsx("w-full", wrapperClassName)}>
      {renderLabel(props)}
      <div
        className={`${
          disabled ? `bg-gray-200` : `bg-white border`
        } rounded w-full relative h-14`}
      >
        <button
          className={`w-full text-gray-700 text-left text-sm focus:outline-none h-full ${
            disabled && "bg-gray-100 pointer-events-none"
          }`}
          onClick={handleOpenToggle}
          disabled={disabled}
        >
          <div className="w-full flex items-center my-auto">
            {currentValue ? (
              <div className="w-full flex items-center">
                {renderContent(currentValue)}
                <FaChevronDown className="ml-auto mr-3" />
              </div>
            ) : (
              <>
                <div className="py-1 px-3">
                  <p className="break-all">{placeholder}</p>
                </div>
                <FaChevronDown className="ml-auto mr-3" />
              </>
            )}
          </div>
        </button>
        {isToggled && (
          <div
            id={name}
            className="w-full flex flex-col absolute z-20 bg-white rounded-lg p-2 max-h-72 overflow-y-auto shadow-inner"
          >
            {options.length === 0 ? (
              <button
                type="button"
                className="shadow-sm mt-1 border border-solid rounded-lg transition duration-300 ease-in-out hover:shadow-lg hover:border-primary-500 focus:outline-none h-10"
                onClick={handleCloseToggle}
              >
                <p className="break-all text-sm text-gray-700">
                  {emptyPlaceholder}
                </p>
              </button>
            ) : (
              options.map((item) => (
                <button
                  key={item?.value}
                  type="button"
                  className="shadow-sm mt-1 border border-solid rounded-lg transition duration-300 ease-in-out hover:shadow-lg hover:border-primary-500 focus:outline-none"
                  onClick={() => handleOnChange(item)}
                >
                  {renderContent(item)}
                </button>
              ))
            )}
          </div>
        )}
      </div>
    </div>
  );
};

FormInputSelectImage.defaultProps = {
  label: "",
  subLabel: "",
  tooltip: "",

  disabled: false,
  emptyPlaceholder: "- No options available -",
  formOnChange: () => {},
  formValue: "",
  placeholder: "- Select -",
  required: false,
  value: "",
  wrapperClassName: "",
};

FormInputSelectImage.propTypes = {
  label: PropTypes.string,
  subLabel: PropTypes.string,
  tooltip: PropTypes.string,

  disabled: PropTypes.bool,
  emptyPlaceholder: PropTypes.string,
  formOnChange: PropTypes.func,
  formValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  name: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Array).isRequired,
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.instanceOf(Object),
  ]),
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  wrapperClassName: PropTypes.string,
};

export default FormInputSelectImage;
