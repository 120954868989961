import { parentDirectory as parent } from "../constants";

export const parentDirectory = `${parent}/Modal`;

export const modalSize = {
  sm: "modal-sm",
  md: "modal-md",
  lg: "modal-lg",
  xl: "modal-xl",
};
export const sizeOptions = Object.keys(modalSize);
