import React from "react";
import PropTypes from "prop-types";
import { FaExclamationTriangle } from "react-icons/fa";


export const WorkInProgress = ({ background }) => {
  return (
    <div className="h-full w-full flex relative">
      <div className="flex flex-col m-auto bg-white border shadow-lg rounded-lg p-3 z-20">
        <FaExclamationTriangle className="text-yellow-500 m-auto text-4xl mb-2" />
        <div className="text-gray-600">Work in Progress</div>
      </div>
      {background && (
        <div
          className="absolute h-full w-full bg-contain bg-no-repeat z-10"
          style={{ backgroundImage: `url(${background})`, filter: "blur(3px)" }}
        />
      )}
    </div>
  );
}

WorkInProgress.propTypes = {
  background: PropTypes.string,
};

WorkInProgress.defaultProps = {
  background: "",
};
