import React from "react";
import PropTypes from "prop-types";
import { useApiLoading } from "react-reqq";
import { Helmet } from "react-helmet";
import {
  Button,
  Field,
  FloatingInput,
  FormWrapper,
} from "modules/common/core-ui";
import AppBrand from "modules/partial/components/AppBrand";
import AppFooter from "modules/partial/components/AppFooter";
// import { handlePartnerWithUs } from "modules/partial/components/PublicHeader";
import { handleForgotPasswordModal } from "modules/partial/components/PublicHeader";
import * as actions from "../actions";
import * as c from "../constants";

const validationSchema = (yup) =>
  yup.object().shape({
    email: yup.string().required().label("Email"),
    password: yup.string().required().label("Password"),
  });

function LoginModal({ onClose }) {
  const isLoading = useApiLoading(c.LOGIN, "post");
  const handleSubmit = (values) => {
    actions.login(values, () => onClose());
  };

  return (
    <>
      <Helmet>
        <title>Cashbox - Login</title>
      </Helmet>
      <div className="flex items-center justify-center  flex-col w-full px-2">
        <div className="w-full max-w-lg bg-white rounded-xl relative">
          <div className="w-full pt-12">
            <AppBrand />
          </div>

          <div className="pt-8 pb-10 px-12">
            <div className="text-gray-800 font-medium mt-3 text-2xl text-center mb-8">
              Login
            </div>
            <div className="text-gray-500 mb-3 text-center text-sm">
              Login your details below
            </div>
            <FormWrapper
              defaultValues={{
                email: "",
                password: "",
              }}
              validation={validationSchema}
              onSubmit={handleSubmit}
            >
              <div className="mb-2">
                <Field
                  component={FloatingInput}
                  name="email"
                  type="email"
                  label="Email"
                  autoFocus
                />
              </div>
              <div className="mb-2">
                <Field
                  component={FloatingInput}
                  name="password"
                  label="Password"
                  type="password"
                  withIcon
                  withShowPassword
                />
              </div>
              <div className="flex justify-end">
                {handleForgotPasswordModal && (
                  <button
                    type="button"
                    className="text-gray-500 text-sm"
                    onClick={handleForgotPasswordModal}
                  >
                    Forgot Password?
                  </button>
                )}
              </div>
              <div className="pt-5 w-48 md:w-96 mx-auto">
                <Button
                  className="w-full rounded-full mb-2"
                  variant="gradient"
                  variantColor="primary"
                  type="submit"
                  disabled={isLoading}
                >
                  Login
                </Button>
                <Button
                  className="rounded-full w-full"
                  variant="outline"
                  variantColor="primary"
                  onClick={onClose}
                  disabled={isLoading}
                >
                  Close
                </Button>
              </div>
            </FormWrapper>
            {/* <div className="text-center mt-5 text-sm mb-2">
              No account yet?
              {handlePartnerWithUs && (
                <button
                  type="button"
                  className="text-secondary-500 ml-1"
                  onClick={handlePartnerWithUs}
                >
                  Sign up here
                </button>
              )}
            </div> */}
          </div>
        </div>
        <div className="text-gray-200 text-xs mt-4">
          <AppFooter />
        </div>
      </div>
    </>
  );
}

LoginModal.propTypes = {
  onClose: PropTypes.instanceOf(Function).isRequired,
};

export default LoginModal;
