import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useApiLoading } from "react-reqq";
import { useHistory, useParams } from "react-router-dom";
import CashboxLogo from "assets/img/cashbox-logo.svg";
import {
  Button,
  Field,
  FormInputPassword,
  FormWrapper,
} from "modules/common/core-ui";
import * as actions from "../actions";
import * as c from "../constants";

const INIT_FORM = {
  new_password: "",
  confirm_new_password: "",
};

const validationSchema = (yup) =>
  yup.object().shape({
    new_password: yup.string().min(3).required().label("New password"),
    confirm_new_password: yup
      .string()
      .required()
      .oneOf([yup.ref("new_password"), null], "Passwords must match")
      .label("Password confirmation"),
  })

function ResetPassword() {
  const params = useParams();
  const history = useHistory();
  const isLoading = useApiLoading(c.RESET_PASSWORD, "put");

  const handleOnReturn = () => {
    history.push("/");
  };
  const handleSubmit = (data) => {
    actions.resetPassword(
      params?.token,
      { new_password: data?.new_password },
      () => {
        history.push("/");
      },
    )
  };

  return (
    <div
      className="h-screen flex items-center relative bg-white"
      style={{
        zIndex: 99,
      }}
    >
      <div className="p-8 flex justify-between top-0 left-0 absolute w-full">
        <Button
          variant="ghost"
          variantColor="primary"
          leftIcon={FaArrowLeft}
          onClick={handleOnReturn}
        >
          Back to website
        </Button>
      </div>
      {/* BODY */}
      <div className="w-full flex flex-col justify-center items-center">
        <img src={CashboxLogo} alt="logo" className="h-12" />
        <h1 className="text-3xl mt-10 mb-8">Reset Password</h1>

        <FormWrapper
          defaultValues={INIT_FORM}
          validation={validationSchema}
          onSubmit={handleSubmit}
          className="flex flex-col space-y-4 p-4 w-72"
        >
          <Field
            component={FormInputPassword}
            name="new_password"
            type="password"
            label="New Password"
            withIcon
            withShowPassword
          />
          <Field
            component={FormInputPassword}
            name="confirm_new_password"
            type="password"
            label="Confirm New Password"
            withIcon
            withShowPassword
          />
          <Button
            className="w-full rounded-full mt-8"
            variant="gradient"
            variantColor="primary"
            type="submit"
            isLoading={isLoading}
          >
            Submit
          </Button>
          </FormWrapper>
      </div>
    </div>
  );
}

export default ResetPassword;
