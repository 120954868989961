import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { renderLabel } from "./FormHelper";

export const FormInputTextArea = ({
  label,
  subLabel,
  tooltip,

  className,
  disabled,
  formOnChange,
  formValue,
  noSymbol,
  name,
  placeholder,
  required,
  rows,
  value,
  wrapperClassName,
  ...rest
}) => {
  const props = {
    label,
    subLabel,
    tooltip,

    className,
    disabled,
    formOnChange,
    formValue,
    noSymbol,
    name,
    placeholder,
    required,
    rows,
    value,
    wrapperClassName,
    ...rest
  };
  return (
    <div className={clsx("w-full", wrapperClassName)}>
      {renderLabel(props)}
      <textarea
        name={name}
        className={clsx(
          className,
          "h-auto form-control w-full input-wrapper form-textarea",
          disabled ? "bg-gray-200 text-gray-600" : "text-gray-900"
        )}
        value={formValue}
        rows={rows}
        onChange={(e) => {
            const value = e.target.value;

            !disabled &&
              formOnChange(
                noSymbol ? value.replace(/[^a-z0-9_]/gi, "") : value,
                e
              );
          }}
        placeholder={placeholder}
        disabled={disabled}
        style={{ resize: "none" }}
        {...rest}
      />
    </div>
  );
}

FormInputTextArea.defaultProps = {
  label: "",
  subLabel: "",
  tooltip: "",

  className: "",
  disabled: false,
  formOnChange: () => {},
  formValue: "",
  noSymbol: false,
  placeholder: "",
  required: false,
  rows: 3,
  value: "",
  wrapperClassName: "",
};

FormInputTextArea.propTypes = {
  label: PropTypes.string,
  subLabel: PropTypes.string,
  tooltip: PropTypes.string,

  className: PropTypes.string,
  disabled: PropTypes.bool,
  formOnChange: PropTypes.func,
  formValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  noSymbol: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  wrapperClassName: PropTypes.string,
};

export default FormInputTextArea;
