import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Pagination } from "modules/common/core-ui";

const ItemLoading = () => (
  <div className="relative pr-1">
    <div className="list-item with-arrow">
      <div className="flex px-4 py-2 rounded-lg items-center w-full shadow-md border animate-pulse">
        <div className="mr-2 relative">
          <div className="bg-gray-400 w-12 h-12 rounded-full mr-2" />
        </div>
        <div>
          <div className="w-2/3 h-4 bg-gray-400 mb-1" />
          <div className="w-1/2 h-3 bg-gray-400" />
        </div>
      </div>
    </div>
  </div>
);

export const Listing = ({
  name,
  selected,
  selectedKey,
  wrapperClassName,
  filterAndAction,
  isLoading,
  list,
  listItem,
  onRowClick,
  pager,
  onChangePage,
  variant,
  variantColor,
}) => {
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [size, setSize] = useState(0);

  const handleRowClick = (row) => (e) => {
    e.preventDefault();
    onRowClick(row);
  };

  useEffect(() => {
    if (!_.isEmpty(pager)) {
      setTotalPage(pager?.total_pages);
      setCurrentPage(pager?.current_page);
      setSize(pager?.size);
    }
  }, [list, pager]);

  const renderList = () =>
    _.isEmpty(list) ? (
      <div className="flex items-center justify-center text-gray-800 text-xs h-full">
        No items found
      </div>
    ) : (
      (list || []).map((item, i) => {
        const isActive = `${_.get(item, selectedKey)}` === `${selected}`;
        return (
          <div className="relative pr-1 mr-1" key={_.get(item, selectedKey)}>
            <a
              className={`list-item ${variantColor} with-arrow${
                isActive ? " active" : ""
              }`}
              onClick={handleRowClick(item)}
              href="/"
            >
              {listItem(item, i)}
            </a>
          </div>
        );
      })
    );

  return (
    <div className="flex flex-col rounded h-full">
      <div className={wrapperClassName}>
        <div>{filterAndAction}</div>
      </div>
      {!_.isEmpty(name) && (
        <div className="bg-white border-solid border-l border-r border-b px-4 py-3 mr-2 mb-2">
          <h2 className="font-medium text-sm">{name}</h2>
        </div>
      )}

      <div className="flex-grow overflow-y-auto border-gray-300 relative">
        <ul className="absolute top-0 bottom-0 left-0 right-0 pt-1">
          {isLoading
            ? [...new Array(3)].map((_, index) => <ItemLoading key={index} />)
            : renderList()}
        </ul>
      </div>

      <div className="flex justify-center bg-white border border-solid border-gray-300 rounded-b-lg p-4 mr-2">
        <Pagination
          value={{
            current_page: currentPage,
            total_pages: totalPage,
            total: pager?.total,
            size,
          }}
          onChange={onChangePage}
          disabled={isLoading}
          hideFirstAndLastPageLinks
          variant={variant}
          variantColor={variantColor}
        />
      </div>
    </div>
  );
};

Listing.defaultProps = {
  name: "",
  selected: null,
  selectedKey: "id",
  wrapperClassName: "bg-white border rounded-t-lg p-4 mr-2",
  filterAndAction: <></>,
  isLoading: false,
  pager: {},
  onChangePage: () => {},
  variant: "outline",
  variantColor: "primary",
};

Listing.propTypes = {
  name: PropTypes.string,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  wrapperClassName: PropTypes.string,
  filterAndAction: PropTypes.node,
  isLoading: PropTypes.bool,
  list: PropTypes.instanceOf(Array).isRequired,
  listItem: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  pager: PropTypes.instanceOf(Object),
  onChangePage: PropTypes.func,
  variant: PropTypes.string,
  variantColor: PropTypes.string,
};
