export const commonClass = {
  pill: "whitespace-nowrap font-medium rounded-full",
  outlinePill: "whitespace-nowrap font-medium rounded-full bg-white",
};

export const pillVariant = {
  success: `${commonClass.pill} text-white bg-green-500`,
  warning: `${commonClass.pill} text-white bg-yellow-500`,
  danger: `${commonClass.pill} text-white bg-red-500`,
  pending: `${commonClass.pill} text-white bg-gray-400`,
  black: `${commonClass.pill} text-white bg-gray-900`,
  info: `${commonClass.pill} text-white bg-blue-400`,
};

export const outlinePillVariant = {
  success: `${commonClass.outlinePill} text-green-500 border-2 border-green-500`,
  warning: `${commonClass.outlinePill} text-yellow-500 border-2 border-yellow-500`,
  danger: `${commonClass.outlinePill} text-red-500 border-2 border-red-500`,
  pending: `${commonClass.outlinePill} text-gray-400 border-2 border-gray-400`,
  black: `${commonClass.outlinePill} text-gray-900 border-2 border-gray-900`,
  info: `${commonClass.outlinePill} text-blue-400 border-2 border-blue-400`,
};

export const badgeVariant = (variantColor) => ({
  pill: pillVariant[variantColor] || '',
  outlinePill: outlinePillVariant[variantColor] || '',
});

export const badgeColorOptionsByVariant = {
  pill: Object.keys(pillVariant),
  outlinePill: Object.keys(outlinePillVariant),
};

export const badgeSize = {
  xxs: "text-xxs px-2",
  xs: "text-xs px-2",
  sm: "text-sm px-3",
  md: "text-md px-3",
  lg: "text-lg px-4",
};
