import React from "react";
import { Link as AnchorLink } from "react-scroll";
import SyntaxHighlighter from "react-syntax-highlighter";
import { dark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { renderData, returnBadge } from "../renderHelper";

const list = [
  {
    status: "REQUIRED",
    title: "reference_number",
    dataType: "string",
  },
  {
    status: "REQUIRED",
    title: "partner_alias",
    dataType: "string",
  },
  {
    status: "REQUIRED",
    title: "receivers",
    dataType: "array",
  },
  {
    status: "REQUIRED",
    title: "mode",
    dataType: "object",
    list: [
      {
        status: "REQUIRED",
        title: "notification_mode",
        dataType: "string",
      },
      {
        status: "REQUIRED",
        title: "disbursement_mode",
        dataType: "string",
      },
      {
        status: "OPTIONAL",
        title: "callback_url",
        dataType: "string",
        content: () => (
          <>
            <span className="text-red-500">NOTE: </span>
              Due to current active partner, attributes for mode object is automatically set to only notification and disbursement mode. However, future developments may change the structure above as each partner requires different set of parameters to be sent upon calling their system. Usage of parameters will be dependent on chosen partner. See list of active parameters and their definition in the
              <AnchorLink
                to="section-8"
                spy={true}
                smooth={true}
                duration={500}
                delay={0}
                containerId="for-developers"
                offset={-100}
                className="cursor-pointer text-sm text-primary-500 font-medium"
              >
                {" "}Parameter list.{" "}
              </AnchorLink>
              For disbursements with callback functionality, please communicate first the designated url to cashbox team for setting it in the system - this is for additional security measures of the system to ensure the safety of data cashbox returns
          </>
        ),
      },
    ]
  },
  {
    status: "REQUIRED",
    title: "receivers",
    dataType: "array",
    list: [
      {
        status: "REQUIRED",
        title: "identifier",
        dataType: "string",
      },
      {
        status: "REQUIRED",
        title: "last_name",
        dataType: "string",
      },
      {
        status: "OPTIONAL",
        title: "middle_name",
        dataType: "string",
      },
      {
        status: "REQUIRED",
        title: "first_name",
        dataType: "string",
      },
      {
        status: "REQUIRED",
        title: "mobile_number",
        dataType: "string",
        content: () => <>format: 639XXXXXXXXX</>
      },
      {
        status: "REQUIRED",
        title: "amount",
        dataType: "double",
      },
      {
        status: "REQUIRED",
        title: "address",
        dataType: "string",
      },
    ],
  }
];

const list1 = [
  {
    title: "message",
    dataType: "string",
    content: () => <>Contains response message of Cashbox</>
  },
  {
    title: "data",
    dataType: "array",
    content: () => <>Contains list of active partners of Cashbox</>
  },
];

const list2 = [
  {
    title: "id",
    dataType: "long",
    content: () => <>Identical to transaction_id, cashbox indicator for transaction logs</>
  },
  {
    title: "transaction_id",
    dataType: "long",
    content: () => <>Identical to id, cashbox indicator for transaction logs</>
  },
  {
    title: "response_status",
    dataType: "string",
    content: () => <>Contains response message of Cashbox</>
  },
  {
    title: "batch_number",
    dataType: "string",
    content: () => <>Contains list of active partners of Cashbox</>
  },
];

function SectionDisbursementAPI() {
  return (
    <>
      <div className="w-full flex flex-col md:flex-row md:space-x-8 space-y-8 md:space-y-0">
        <div className="w-full">
          <h2 id="disbursement-api" className="main-title">Disbursement</h2>

          <p className="content mb-10">
            This API will allow system to disburse using cashbox API. This requires chosen active partner from
            <AnchorLink
              to="section-3"
              spy={true}
              smooth={true}
              duration={500}
              delay={0}
              containerId="for-developers"
              offset={-100}
              className="cursor-pointer text-sm text-primary-500 font-medium"
            >
              {" "}Get list of partners API{" "}
            </AnchorLink>
            for processing.
          </p>
          <hr className="my-8" />

          {/* BODY */}
          <h3 className="subtitle">Body</h3>
          {renderData(list)}
        </div>

        <div className="w-full lg:sticky lg:top-0 lg:self-start lg:pt-4 lg:-mt-4">
          <div className="rounded-lg overflow-hidden flex flex-col fira-code text-xs text-white">
            <div className="px-4 py-2 bg-primary-500 flex flex-row items-center">
              {returnBadge('POST')}
              <div className="ml-3 break-all">/private/api/subscriber/disbursement</div>
            </div>
            <div className="p-6 code-gray">
              <SyntaxHighlighter style={dark}>
                  {
`{
  "reference_number": "37Z2EKQE5L",
  "partner_alias": "CEBUANA",
  "mode": {
    "notification_mode": "1",
    "disbursement_mode": "2",
  },
  "receivers": [
    {
      "identifier": "LUTQFXQNZWBXHLPDEGSQ",
      "last_name": "John",
      "middle_name": "Doe",
      "first_name": "Santos",
      "mobile": "639123456789",
      "address": "Manila",
      "amount": 1000.50
    }
  ]
}`
                  }
              </SyntaxHighlighter>
            </div>
          </div>
        </div>
      </div>

      <hr className="my-8" />

      <div className="w-full flex flex-col md:flex-row md:space-x-8 space-y-8 md:space-y-0">
        <div className="w-full">
          <h3 className="subtitle">Response Body</h3>
          {renderData(list1)}

          <hr className="my-8" />

          <h3 className="subtitle">Data</h3>
          {renderData(list2)}
        </div>

        <div className="w-full lg:sticky lg:top-0 lg:self-start lg:pt-4 lg:-mt-4">
          <div className="rounded-lg overflow-hidden flex flex-col fira-code text-xs text-white">
            <div className="px-4 py-2 bg-primary-500 flex flex-row items-center">
              Sample Response
            </div>
            <div className="p-6 code-gray">
              <SyntaxHighlighter style={dark}>
                {
`{
  "data": {
    "id": 1031,
    "batch_number": "XCBDNK5v1e108838",
    "transaction_id": 1031,
    "response_status": "Successful"
  },
  "message": "Disbursement batch sent for posting"
}`
                }
              </SyntaxHighlighter>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default SectionDisbursementAPI
