import icon_marker_black from "assets/img/markers/black-marker.png";
import icon_marker_blue from "assets/img/markers/blue-marker.png";
import icon_marker_green from "assets/img/markers/green-marker.png";
import icon_marker_orange from "assets/img/markers/orange-marker.png";
import icon_marker_red from "assets/img/markers/red-marker.png";
import icon_marker_violet from "assets/img/markers/violet-marker.png";
import icon_marker_white from "assets/img/markers/white-marker.png";
import icon_marker_yellow from "assets/img/markers/yellow-marker.png";

export const MARKER_TYPES = [
  "black",
  "blue",
  "green",
  "orange",
  "red",
  "violet",
  "white",
  "yellow",
];

export const MARKER_ICON = {
  black: icon_marker_black,
  blue: icon_marker_blue,
  green: icon_marker_green,
  orange: icon_marker_orange,
  red: icon_marker_red,
  violet: icon_marker_violet,
  white: icon_marker_white,
  yellow: icon_marker_yellow,
};
