import React from "react";
import { Link as AnchorLink } from "react-scroll";
import SyntaxHighlighter from "react-syntax-highlighter";
import { dark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { renderData, returnBadge } from "../renderHelper";

const list = [
  {
    title: "batch_number",
    dataType: "string",
    content: () => <>Batch number of disbursement transaction</>
  },
  {
    title: "digest",
    dataType: "string",
    content: () => <><span className="font-medium">HMAC-SHA256</span> encrypted data for security validation</>
  },
  {
    title: "result",
    dataType: "array",
    content: () => <>Array of objects containing result of receiver disbursement update</>
  },
];

const list1 = [
  {
    title: "identifier",
    dataType: "string",
    content: () => <>Key identifier of record sent for disbursement</>
  },
  {
    title: "status",
    dataType: "string",
    content: () => <>Status of single receiver</>
  },
  {
    title: "control_number",
    dataType: "array",
    content: () => <>Transaction reference key provided by partner</>
  },
];

function SectionCallbackService() {
  return (
    <>
      <div className="w-full flex flex-col md:flex-row md:space-x-8 space-y-8 md:space-y-0">
        <div className="w-full">
          <h2 id="disbursement-api" className="main-title">Callback Service</h2>

          <p className="content mb-10">
            Synchronous function of Cashbox on updating subscribers with their disbursement. Dependent on inclusion of <span className="text-red-500">callback_url</span> parameter, see
            <AnchorLink
              to="section-4"
              spy={true}
              smooth={true}
              duration={500}
              delay={0}
              containerId="for-developers"
              offset={-100}
              className="cursor-pointer text-sm text-primary-500 font-medium"
            >
              {" "}Disbursement API{" "}
            </AnchorLink>
            . This function will automatically call url given to supply updates on their disbursement provided that the url is validated to be registered in cashbox system for security purposes.
          </p>

          <hr className="my-8" />

          {/* BODY */}
          <h3 className="subtitle">Response Body</h3>
          {renderData(list)}
          <hr className="my-8" />

          {/* RESULT */}
          <h3 className="subtitle">Result</h3>
          {renderData(list1)}
        </div>

        <div className="w-full lg:sticky lg:top-0 lg:self-start lg:pt-4 lg:-mt-4">
          <div className="rounded-lg overflow-hidden flex flex-col fira-code text-xs text-white">
            <div className="px-4 py-2 bg-primary-500 flex flex-row items-center">
              {returnBadge('POST')}
            </div>
            <div className="p-6 code-gray">
              <SyntaxHighlighter style={dark}>
                  {
`{
  "result": [
    {
      "identifier": "A1B2C3D4",
      "control_number": "CTRLN0111",
      "status": "POST_READY_FOR_PAYOUT"
    }
  ],
  "batch_number": "XCBQQMLN1234567",
  "digest": "XXXXXXXXXX"
}`
                  }
              </SyntaxHighlighter>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SectionCallbackService;
