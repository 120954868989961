import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { renderLabel } from "./FormHelper";

export const FormInputSelect = ({
  label,
  subLabel,
  tooltip,

  disabled,
  formOnChange,
  formValue,
  isLoading,
  name,
  options,
  placeholder,
  required,
  value,
  wrapperClassName,
  ...rest
}) => {
  const props = {
    label,
    subLabel,
    tooltip,

    disabled,
    formOnChange,
    formValue,
    isLoading,
    name,
    options,
    placeholder,
    required,
    value,
    wrapperClassName,
    ...rest
  };

  return (
    <div className={clsx("w-full", wrapperClassName)}>
      {renderLabel(props)}
      <div
        className={`${
          disabled ? `bg-gray-200` : `bg-white border`
        } flex rounded-lg items-center justify-between w-full input-wrapper`}
      >
        <select
          name={name}
          onChange={(e) => (!disabled && !isLoading) && formOnChange(e.target.value, e)}
          className={(disabled || isLoading) ? "text-gray-600" : "text-gray-900"}
          value={formValue}
          disabled={disabled}
          {...rest}
        >
          {typeof placeholder !== "boolean" && placeholder && (
            <option value="">{(isLoading ? "Loading..." : placeholder) || "- Select -"}</option>
          )}
          {options.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

FormInputSelect.defaultProps = {
  label: "",
  subLabel: "",
  tooltip: "",

  disabled: false,
  formOnChange: () => {},
  formValue: "",
  isLoading: false,
  placeholder: "- Select -",
  required: false,
  value: "",
  wrapperClassName: "",
};

FormInputSelect.propTypes = {
  label: PropTypes.string,
  subLabel: PropTypes.string,
  tooltip: PropTypes.string,

  disabled: PropTypes.bool,
  formOnChange: PropTypes.func,
  formValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  isLoading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Array).isRequired,
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.instanceOf(Object),
  ]),
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  wrapperClassName: PropTypes.string,
};

export default FormInputSelect;
