import React, { forwardRef } from "react";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";
import clsx from "clsx";
import { renderLabel } from "./FormHelper";

export const FormInputAsyncSelect = forwardRef((props, ref) => {
  const {
    label,
    subLabel,
    tooltip,

    cacheOptions,
    defaultOptions,
    isSearchable,
    loadOptions,

    disabled,
    formOnChange,
    formValue,
    isLoading,
    name,
    placeholder,
    required,
    value,
    wrapperClassName,
    ...rest
  } = props;

  const handleOnFocus = () => {
    ref.current.focus();
  };

  return (
    <div className={clsx("w-full", wrapperClassName)}>
      {renderLabel(props)}
      <AsyncSelect
        cacheOptions={cacheOptions}
        defaultOptions={defaultOptions}
        isSearchable={isSearchable}
        loadOptions={loadOptions}

        classNamePrefix="react-select"
        className={`w-full react-select-container ${(disabled || isLoading) ? "text-gray-600" : "text-gray-900"}`}
        isDisabled={disabled || isLoading}
        isLoading={isLoading}
        placeholder={placeholder}
        onChange={formOnChange}
        onFocus={handleOnFocus}
        openMenuOnFocus
        ref={ref}
        value={formValue}
        {...rest}
      />
    </div>
  );
});

FormInputAsyncSelect.defaultProps = {
  label: "",
  subLabel: "",
  tooltip: "",

  cacheOptions: true,
  defaultOptions: [],
  isSearchable: true,
  loadOptions: () => {},

  disabled: false,
  formOnChange: () => {},
  formValue: "",
  isLoading: false,
  placeholder: "- Search -",
  required: false,
  value: "",
  wrapperClassName: "",
};

FormInputAsyncSelect.propTypes = {
  label: PropTypes.string,
  subLabel: PropTypes.string,
  tooltip: PropTypes.string,

  cacheOptions: PropTypes.bool,
  defaultOptions: PropTypes.instanceOf(Array),
  isSearchable: PropTypes.bool,
  loadOptions: PropTypes.func,

  disabled: PropTypes.bool,
  formOnChange: PropTypes.func,
  formValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  isLoading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.instanceOf(Object),
  ]),
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  wrapperClassName: PropTypes.string,
};

export default FormInputAsyncSelect;
