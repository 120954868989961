import React from "react";
import PropTypes from "prop-types";
import { useApiLoading } from "react-reqq";
import { Helmet } from "react-helmet";
import {
  Button,
  Field,
  FloatingInput,
  FormWrapper,
} from "modules/common/core-ui";
import AppBrand from "modules/partial/components/AppBrand";
import AppFooter from "modules/partial/components/AppFooter";
import { handleLoginModal } from "modules/partial/components/PublicHeader";
import * as actions from "../actions";
import * as c from "../constants";

const validationSchema = (yup) =>
  yup.object().shape({
    email: yup.string().required().label("Email"),
  });

function ForgotPasswordModal({ onClose }) {
  const isLoading = useApiLoading(c.FORGOT_PASSWORD, "post");

  const handleSubmit = (values) => {
    actions.forgotPassword(values?.email, () => onClose());
  };

  return (
    <>
      <Helmet>
        <title>Cashbox - Forgot Password</title>
      </Helmet>
      <div className="flex items-center justify-center  flex-col w-full px-2">
        <div className="w-full max-w-lg bg-white rounded-xl relative">
          <div className="w-full pt-10 mb-16">
            <AppBrand />
          </div>

          <div className="-mt-20 pt-10 pb-4 px-12">
            <div className="text-gray-800 font-medium mt-3 text-2xl text-center mb-8">
              Forgot Password
            </div>
            <div className="text-gray-500 mb-3 text-center text-sm">
              Input your e-mail to reset your password
            </div>
            <FormWrapper
              defaultValues={{
                email: "",
              }}
              validation={validationSchema}
              onSubmit={handleSubmit}
            >
              <div className="mb-2">
                <Field
                  component={FloatingInput}
                  name="email"
                  type="email"
                  label="Email"
                  autoFocus
                />
              </div>
              <div className="pt-10 w-48 md:w-96 mx-auto">
                <Button
                  className="w-full rounded-full mb-2"
                  variant="gradient"
                  variantColor="primary"
                  type="submit"
                  disabled={isLoading}
                >
                  Send Link
                </Button>
                <Button
                  className="rounded-full w-full"
                  variant="outline"
                  variantColor="primary"
                  onClick={handleLoginModal}
                  disabled={isLoading}
                >
                  Back
                </Button>
              </div>
            </FormWrapper>
          </div>
        </div>
        <div className="text-gray-200 text-xs mt-4">
          <AppFooter />
        </div>
      </div>
    </>
  );
}

ForgotPasswordModal.propTypes = {
  onClose: PropTypes.instanceOf(Function).isRequired,
};

export default ForgotPasswordModal;
