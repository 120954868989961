import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { dark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { renderData, returnBadge } from "../renderHelper";

const list = [
  {
    title: "message",
    dataType: "string",
    content: () => <>Contains response message of Cashbox</>
  },
  {
    title: "data",
    dataType: "array",
    content: () => <>Contains list of items under selected batch</>
  },
];

function SectionDisbursementItem() {
  return (
    <>
      <div className="w-full flex flex-col md:flex-row md:space-x-8 space-y-8 md:space-y-0">
        <div className="w-full">
          <h2 id="disbursement-item" className="main-title">Get items per disbursement batch</h2>
          <p className="content mb-5">This API will return all items under selected disbursement batch containing details and current status</p>

          <h3 className="subtitle">Response Body</h3>
          {renderData(list)}
        </div>
        <div className="w-full lg:sticky lg:top-0 lg:self-start lg:pt-4 lg:-mt-4">
          <div className="rounded-lg overflow-hidden flex flex-col fira-code text-xs text-white">
            <div className="px-4 py-2 bg-primary-500 flex flex-row items-center">
              {returnBadge('GET')}
              <div className="ml-3 break-all">/private/api/subscriber/disbursement?batch_number={`{batchno}`}</div>
            </div>
            <div className="p-6 code-gray">
              <SyntaxHighlighter style={dark}>
                {
`[
  {
    "id": 65,
    "subscriber_code": "CSHBX",
    "identifier": "UE01NHJX0LKA951C",
    "first_name": "John Michael",
    "middle_name": "Brown",
    "last_name": "Doe",
    "amount": 100,
    "mobile_number": "639123456789",
    "status": "PENDING",
    "batch_number": "XCB5YCSHCPBK4107",
    "disbursement_date": "null",
    "claim_date": "null",
    "service_charge": 0,
  },
  {
    "id": 66,
    "subscriber_code": "CSHBX",
    "identifier": "UE01NHJX0LKA951C",
    "first_name": "John Michael",
    "middle_name": "Brown",
    "last_name": "Doe",
    "amount": 100,
    "mobile_number": "639123456789",
    "status": "PENDING",
    "batch_number": "XCB5YCSHCPBK4107",
    "disbursement_date": "null",
    "claim_date": "null",
    "service_charge": 0,
  },
]`
                }
              </SyntaxHighlighter>
            </div>
          </div>
        </div>

      </div>

    </>
  )
}

export default SectionDisbursementItem
