import React from "react";
import { Element as AnchorElement } from "react-scroll";
import PublicFooter from "modules/partial/components/PublicFooter";
import DevelopersSidebar from "../components/DevelopersSidebar";
import DevelopersTopBar from "../components/DevelopersTopBar";
import ForDevelopersContent from "./ForDevelopersContent";

function DevelopersContainerNew() {
  return (
    <AnchorElement name="for-developers" id="for-developers" className="w-full overflow-auto h-screen">
      <div className="min-h-screen w-full flex flex-col lg:flex-row relative">
        <div className="hidden lg:block bg-footer h-full sticky top-0 min-h-screen z-10" style={{ width: "320px" }}>
          <DevelopersSidebar />
        </div>
        <div className="bg-footer sticky top-0 block lg:hidden z-20">
          <DevelopersTopBar />
        </div>
        <div className="w-full docu-container relative z-0">
          <ForDevelopersContent />
        </div>
      </div>
      <PublicFooter />
    </AnchorElement>
  )
}

export default DevelopersContainerNew
