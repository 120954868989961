export const AUTHENTICATION = "AUTH/authentication";
export const LOGIN = "AUTH/login";
export const FORGOT_PASSWORD = "AUTH/forgot_password";
export const RESET_PASSWORD = "AUTH/reset_password";
export const REGISTER = "AUTH/register";
export const CONFIRM_EMAIL = "AUTH/confirm_email";

export const HASHPASS = "AUTH/PASS/CASHBOX";

export const SUPER_ADMIN = "SUPER ADMIN";
export const SUBSCRIBER_ADMIN = "ADMIN";
export const SUBSCRIBER_APPROVER = "APPROVER";
export const SUBSCRIBER_MAKER = "MAKER";
export const FINANCE = "FINANCE";
export const SUPPORT = "SUPPORT";

export const GENDER_OPTIONS = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];
