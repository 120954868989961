import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Link as AnchorLink } from "react-scroll";
import { useHistory } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import { Button } from "modules/common/core-ui";
import { ROUTES } from "../constants";

function DevelopersSidebar({ type }) {
  const history = useHistory();

  const handleBackPage = () => {
    history.push('/');
  };

  return (
    <div className={clsx("w-full h-full bg-primary-100 flex flex-col", type === "public" && "min-h-screen")}>
      {type === "public" && (
        <>
          <div className="w-full pr-6">
            <Button
              variant="gradient"
              variantColor="primary"
              className="px-5 py-3 rounded-l-none rounded-t-none rounded-br-lg"
              onClick={handleBackPage}
            >
              <FaChevronLeft className="mr-2" />Back to Homepage
            </Button>
          </div>
          <div className="w-full px-5 pt-8">
            <h2 className="text-xl">
              Cashbox <span className="text-primary-500 font-medium">API</span>
            </h2>
          </div>
        </>
      )}
      <div className="h-full px-5 pb-8 overflow-auto pt-8">
        {ROUTES.map(route => (
          <div key={route.label} className="mb-3">
            <AnchorLink
              activeClass="text-primary-500 font-medium"
              to={route.url}
              spy={true}
              smooth={true}
              duration={500}
              delay={0}
              containerId="for-developers"
              offset={-100}
              className="cursor-pointer text-sm text-gray-600 mb-3"
            >
              {route.label}
            </AnchorLink>
            {(route.list || []).map(item => (
              <div key={item.label} className="mt-3 ml-3">
                <AnchorLink
                  activeClass="text-primary-500 font-medium"
                  to={item.url}
                  spy={true}
                  smooth={true}
                  duration={500}
                  delay={0}
                  containerId="for-developers"
                  offset={-100}
                  className="cursor-pointer text-sm text-gray-600"
                >
                  {item.label}
                </AnchorLink>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

DevelopersSidebar.defaultProps = {
  type: "public",
};
DevelopersSidebar.propTypes = {
  type: PropTypes.string,
};
export default DevelopersSidebar;
