import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import Cookie from "js-cookie";
import app_logo from "assets/img/logo.svg";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.warn(error, errorInfo); // eslint-disable-line
  }

  goHome = () => {
    Cookie.remove("_token");
    Cookie.remove("_userType");
  };

  render() {
    const { hasError } = this.state;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div className="h-screen w-screen flex bg-gradient-to-r from-secondary-300 to-primary-300">
          <div className="m-auto text-center text-white">
            <div className="mx-auto inline-block mb-4">
              <img className="w-48" src={app_logo} alt="splash" />
            </div>
            <p className="text-3xl leading-none">Sorry for the inconvenience</p>
            <p className="text-lg mb-3">
              this page is currently under maintenance.
            </p>
            <p className="font-thin">
              Please{" "}
              <a
                className="text-white underline"
                href={get(window, "location.pathname")}
              >
                try again
              </a>{" "}
              later.
            </p>
            <p className="font-thin">
              or{" "}
              <a
                className="text-white underline"
                href="/"
                onClick={this.goHome}
              >
                Go back to home page
              </a>
            </p>
          </div>
        </div>
      );
    }
    const { children } = this.props;
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};

export default ErrorBoundary;
