import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Provider } from "react-redux";
import { FaTimes } from "react-icons/fa";
import { Router } from "react-router-dom";
import { render } from "react-dom";
import FocusTrap from "focus-trap-react";
import store from "setup/store";
import history from "setup/history";
import { modalSize } from "./constants";

const defaultProps = {
  rootIdOverride: "",
  content: () => <>Modal body</>,
  className: "",
  size: "md",
  title: "",
  titleClassName: "modal-title1 text-white",
  withTitleClose: true,
};

export const closeModal = () => {
  setTimeout(() => {
    document.documentElement.className = "";
    const root = document.getElementById("modal-root");
    render(<div id="modal-root" />, root);
  }, 200);
};

export const showModal = ({
  rootIdOverride,
  content,
  className,
  size,
  title,
  titleClassName,
  withTitleClose,
}) => {
  const opt = {
    rootIdOverride: rootIdOverride || defaultProps.rootIdOverride,
    content: content || defaultProps.content,
    className: className || defaultProps.className,
    size: size || defaultProps.size,
    title: title || defaultProps.title,
    titleClassName: titleClassName || defaultProps.titleClassName,
    withTitleClose: !_.isNil(withTitleClose) ? withTitleClose : defaultProps.withTitleClose,
  };

  const root = document.getElementById(opt.rootIdOverride || "modal-root");
  let modalRef;
  const setModalRef = (ref) => {
    modalRef = ref;
  };
  let dialogRef;
  const setDialogRef = (ref) => {
    dialogRef = ref;
  };
  let blurRef;
  const setBlurRef = (ref) => {
    blurRef = ref;
  };
  const prevClassName = document.documentElement.className;
  const prevElem = document.activeElement;
  document.documentElement.className = "overflow-hidden";

  const onClose = () => {
    try {
      modalRef.classList.add("exit");
      dialogRef.classList.add("exit");
      blurRef.classList.add("exit");
    } catch (err) {
      // do nothing...
    }
    setTimeout(() => {
      document.documentElement.className = prevClassName;
      render(<div id="modal-root" />, root);
      setTimeout(() => {
        try {
          prevElem.focus();
        } catch (err) {} // eslint-disable-line
      }, 100);
    }, 200);
  };
  const renderContent = () => opt.content(onClose);

  render(
    <Provider store={store}>
      <Router history={history}>
        <FocusTrap
          focusTrapOptions={{
            allowOutsideClick: () => true,
          }}
        >
          <div
            ref={setModalRef}
            className="modal"
            tabIndex="-1"
            role="dialog"
            aria-modal="true"
          >
            <div
              ref={setDialogRef}
              className={`container mx-auto modal-dialog mb-10 ${modalSize[opt.size]} ${opt.className}`}
              role="document"
            >
              {(opt.title || opt.withTitleClose) && (
                <div className={`flex justify-between align-center p-5 ${opt?.title && opt.titleClassName}`}>
                  {opt?.title}
                  {opt.withTitleClose && (
                    <button type="button" onClick={onClose} className="ml-auto pl-2">
                      <FaTimes />
                    </button>
                  )}
                </div>
              )}
              {renderContent()}
            </div>
          </div>
        </FocusTrap>
        <div ref={setBlurRef} className="modal-blur" />
      </Router>
    </Provider>,
    root
  );
  return <div id="modal-root" />; // TODO remove this
};

export const ModalMarker = () => {
  return <div id="modal-root" />;
};

showModal.defaultProps = defaultProps;

showModal.propTypes = {
  rootIdOverride: PropTypes.string,
  content: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.string,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  withTitleClose: PropTypes.bool,
};
