import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "modules/common/core-ui";
import background1 from "assets/img/hero-background/bg-banner.png";
import BannerIllustrator from "assets/img/banner-illustration.svg";
// import { handlePartnerWithUs } from "modules/partial/components/PublicHeader";

function Banner() {
  const history = useHistory();

  const handleRegisterModal = () => {
    history.push("/contact-us");
  };

  return (
    <div
      className="hero-container w-full h-screen"
      style={{
        backgroundImage: `url(${background1})`,
        backgroundPosition: 'bottom',
      }}
    >
      <div className="container mx-auto">
        <div className="flex items-center h-screen px-10">
          <div className="w-full flex flex-wrap-reverse lg:flex-wrap items-center">
            <div className="w-full lg:w-3/5 xl:w-1/2 pr-0 lg:pr-10 text-center lg:text-left" data-aos="fade-right">
              <h1 className="text-primary-500 tracking-wide font-semibold text-xl md:text-2xl lg:text-3xl xl:text-3xl mb-5">
                Cash disbursement made easy!
              </h1>
              <p className="text-sm font-normal text-gray-600 mb-10">
                CashBox enables businesses to transfer cash from digital channels to
                physical branches and vice versa.
              </p>
              <Button
                className="rounded-full font-medium px-8"
                variant="outline"
                variantColor="secondary"
                onClick={handleRegisterModal}
                size="md"
              >
                Partner with us!
              </Button>
            </div>
            <div className="flex justify-center w-full lg:w-2/5 xl:w-1/2 mb-5 lg:mb-0" data-aos="fade-left">
              <img
                src={BannerIllustrator}
                alt=""
                className="w-full md:w-4/5 lg:w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
