import { useCallback, useEffect, useState } from "react";
import { useApiGet } from "react-reqq";
import { useRouteMatch } from "react-router-dom";
import { verifyToken } from "./actions";
import { VERIFY_EMAIL } from "./constants";

export const useValidateToken = () => {
  const match = useRouteMatch();
  const { token } = match.params;
  const isLoading = useApiGet(VERIFY_EMAIL, "get");
  const [isVerified, setIsVerified] = useState(false);
  const [isNotVerified, setIsNotVerified] = useState(false);

  const setSuccess = useCallback(() => setIsVerified(true)); // eslint-disable-line
  const setError = useCallback(() => setIsNotVerified(true)); // eslint-disable-line

  useEffect(() => {
    verifyToken(token, setSuccess, setError);
  }, []); // eslint-disable-line

  return { isLoading, isVerified, isNotVerified };
};
