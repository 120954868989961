import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { renderLabel } from "./FormHelper";

export const FormInput = ({
  label,
  subLabel,
  tooltip,

  name,
  type,
  disabled,
  formOnChange,
  formValue,
  required,
  wrapperClassName,
  noSymbol,
  maxLength,
  placeholder,
  ...rest
}) => {
  const props = {
    label,
    subLabel,
    tooltip,

    name,
    type,
    disabled,
    formOnChange,
    formValue,
    required,
    wrapperClassName,
    noSymbol,
    maxLength,
    placeholder,
    ...rest,
  };

  const handleOnChange = (e) => {
    if (disabled) return;

    var value = e.target.value;
    if (type === "tel" || type === "number") {
      value = value.replace(/[^0-9]/gi, "");
    }
    if (noSymbol) {
      value = value.replace(/[^a-z0-9_]/gi);
    }
    formOnChange(value, e);
  };

  return (
    <div className={clsx("w-full", wrapperClassName)}>
      {renderLabel(props)}
      <div
        className={`${
          disabled ? `bg-gray-200` : `bg-white border`
        } flex rounded-lg items-center justify-between w-full input-wrapper`}
      >
        <input
          name={name}
          className={disabled ? "text-gray-600" : "text-gray-900"}
          value={formValue || ""}
          type={type}
          onChange={handleOnChange}
          onKeyDown={(e) => {
            if (type === "number" && e.keyCode === 69) e.preventDefault();
          }}
          disabled={disabled}
          maxLength={maxLength}
          placeholder={placeholder}
          {...rest}
        />
      </div>
    </div>
  );
};
FormInput.defaultProps = {
  label: "",
  subLabel: "",
  tooltip: "",

  disabled: false,
  formOnChange: () => {},
  formValue: "",
  noSymbol: false,
  maxLength: "",
  placeholder: "",
  required: false,
  type: "text",
  wrapperClassName: "",
};
FormInput.propTypes = {
  label: PropTypes.string,
  subLabel: PropTypes.string,
  tooltip: PropTypes.string,

  disabled: PropTypes.bool,
  formOnChange: PropTypes.func,
  formValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  noSymbol: PropTypes.bool,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

export default FormInput;
