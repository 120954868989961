export const CHANGE_PASSWORD = "PROFILE/change_password";

export const VERIFY_EMAIL = "VERIFY/email";

export const CONTACT_US = "CONTACT_US/contact_us";

export const LOCATION_LAT_LNG = "LOCATION/location_lat_lng";
export const LOCATION_STATUS = "LOCATION/location_status";
export const SHOULD_UPDATE_LOCATION = "LOCATION/should_update_location";

export const FIREBASE_INSTANCE = "FIREBASE/instance";
export const FIREBASE_STATUS = "FIREBASE/status";
export const FIREBASE_GEOFIRE = "FIREBASE/geofire";

export const PROFILE_TYPE = {
  "SUPER ADMIN": "Super Admin",
  ADMIN: "Subscriber Admin",
  APPROVER: "Approver",
  MAKER: "Maker",
  FINANCE: "Finance",
  SUPPORT: "Support",
};
