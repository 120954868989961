import React from "react";
import { FaExchangeAlt } from "react-icons/fa";
import { Button } from "modules/common/core-ui";

const COMMON_CLASS = "mr-2";

function Buttons() {
  return (
    <div>
      <div className="w-full">
        <h2>Button</h2>
        <div className="w-full mb-4">
          <Button
            className={COMMON_CLASS}
            variant="gradient"
            variantColor="primary"
            onClick={() => {}}
          >
            Button
          </Button>
          <Button
            className={COMMON_CLASS}
            variantColor="primary"
            onClick={() => {}}
          >
            Button
          </Button>
          <Button
            className={COMMON_CLASS}
            variant="outline"
            variantColor="primary"
            onClick={() => {}}
          >
            Button
          </Button>
          <Button
            className={COMMON_CLASS}
            variant="ghost"
            variantColor="primary"
            onClick={() => {}}
          >
            Button
          </Button>
          <Button
            className={COMMON_CLASS}
            variant="light"
            variantColor="primary"
            onClick={() => {}}
          >
            Button
          </Button>
          <Button
            className={COMMON_CLASS}
            variant="lightBordered"
            variantColor="primary"
            onClick={() => {}}
          >
            Button
          </Button>
        </div>
        <div className="w-full mb-4">
          <Button
            className={COMMON_CLASS}
            variant="gradient"
            variantColor="secondary"
            onClick={() => {}}
          >
            Button
          </Button>
          <Button
            className={COMMON_CLASS}
            variantColor="secondary"
            onClick={() => {}}
          >
            Button
          </Button>
          <Button
            className={COMMON_CLASS}
            variant="outline"
            variantColor="secondary"
            onClick={() => {}}
          >
            Button
          </Button>
          <Button
            className={COMMON_CLASS}
            variant="ghost"
            variantColor="secondary"
            onClick={() => {}}
          >
            Button
          </Button>
          <Button
            className={COMMON_CLASS}
            variant="light"
            variantColor="secondary"
            onClick={() => {}}
          >
            Button
          </Button>
          <Button
            className={COMMON_CLASS}
            variant="lightBordered"
            variantColor="secondary"
            onClick={() => {}}
          >
            Button
          </Button>
        </div>
        <div className="w-full">
          <Button
            className={COMMON_CLASS}
            variant="gradient"
            size="xs"
            onClick={() => {}}
          >
            Button
          </Button>
          <Button
            className={COMMON_CLASS}
            variant="gradient"
            size="sm"
            onClick={() => {}}
          >
            Button
          </Button>
          <Button
            className={COMMON_CLASS}
            variant="gradient"
            size="md"
            onClick={() => {}}
          >
            Button
          </Button>
          <Button
            className={COMMON_CLASS}
            variant="gradient"
            size="lg"
            onClick={() => {}}
          >
            Button
          </Button>
          <Button
            // variant="gradient"
            variantColor="gradient"
            size="md"
            rightIcon={FaExchangeAlt}
            onClick={() => {}}
          >
            Button
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Buttons;
