import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Landing from "modules/partial/containers/Landing";
// import ForDevelopersPage from "modules/partial/for-developers/containers/ForDevelopersPage";
import ForDevelopersContainer from "modules/partial/for-developers-new/containers/ForDevelopersContainer";
import ContactUs from "modules/partial/containers/ContactUs";
import UIContainer from "modules/partial/ui-container/UIContainer";
import PublicHeader from "modules/partial/components/PublicHeader";
import PublicFooter from "modules/partial/components/PublicFooter";
// import RegistrationContainer from "modules/auth/containers/registration/";
import VerifyEmail from "modules/partial/containers/VerifyEmail";
import ResetPassword from "modules/auth/containers/ResetPassword";

function Public() {
  return (
    <Switch>
      <Route exact path="/" render={() => (
        <>
          <PublicHeader />
          <Landing />
          <PublicFooter />
        </>
      )} />
      {/* <Route exact path="/for-developers" render={() => ( // TODO
        <>
          <PublicHeader />
          <ForDevelopersPage />
          <PublicFooter />
        </>
      )} /> */}
      <Route path="/for-developers" render={() => <ForDevelopersContainer />} />
      <Route exact path="/contact-us" render={() => (
        <>
          <PublicHeader />
          <ContactUs />
          <PublicFooter />
        </>
      )} />
      <Route path="/reset/:token" render={() => <ResetPassword />} />

      <Route path="/verify/:token" render={() => <VerifyEmail />} />
      <Route exact path="/ui" render={() => (
        <>
          <UIContainer />
        </>
      )} />

      <Route path="/logout">
        <Redirect to="/" />
      </Route>
      <Redirect to="/" />
    </Switch>
  );
}

export default Public;
