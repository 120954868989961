import React from "react";
import _ from "lodash";
import { TooltipIcon } from "modules/common/core-ui";

export const renderLabel = (data, options = {}) =>
  _.get(data, "label") !== "" && (
    <label className="text-gray-700 font-medium text-xs flex" {...options}>
      <div className="whitespace-nowrap">
        {_.get(data, "label")}{" "}
        {_.get(data, "required") && <span className="text-red-400">*</span>}
      </div>
      {data.subLabel && (
        <div className="ml-auto pl-2 text-gray-400 text-xs font-light italic">
          {" "}
          {_.get(data, "subLabel")}
        </div>
      )}
      {data.tooltip && (
        <span className="ml-1">
          <TooltipIcon
            size="sm"
            type="QUESTION"
            name={`${data.label}-tooltip`}
            content={data.tooltip}
          />
        </span>
      )}
    </label>
  );

export const isNumberValid = (value) => {
  const parseNumber = (val) => {
    try {
      return parseFloat(val);
    } catch (err) {
      return NaN;
    }
  };
  const newValue = parseNumber(value);
  return !(Number.isNaN(newValue) && !_.isEmpty(newValue));
};

export const withSingleDecimal = (value) => {
  const hits = [];
  let lastIndex = value.indexOf(".");

  while (lastIndex !== -1) {
    hits.push(".");
    lastIndex = value.indexOf(".", lastIndex + ".".length);
  }
  if (hits.length > 1) return false;
  return true;
};

export const removeNonNumeric = (value) => value.replace(/[^0-9\.]/g, ""); // eslint-disable-line
export const applyValueLimit = (
  value,
  noNegative = false,
  limit = 100000000000
) => {
  const currentValue = +value;
  if (_.isNil(currentValue) || value === "") {
    return 0;
  }
  if (currentValue > limit) {
    return limit;
  }
  // TODO not yet working
  if (currentValue < 0 && noNegative) {
    return 0;
  }
  if (currentValue < -limit) {
    return -limit;
  }
  return value;
};

export const convertToLimitedDecimalNumber = (value, limit = 2) => {
  const currentValue = value.toString();
  const withDecimal = currentValue.indexOf(".") !== -1;
  if (!withDecimal) return currentValue;
  const wholeNum = currentValue.split(".")[0];
  const decimalNum = currentValue.split(".")[1];
  let newValue = "";

  if (!withDecimal) return currentValue;

  if (_.size(decimalNum) > limit) {
    newValue = `${wholeNum}.${decimalNum.substring(0, 2)}`;
    return newValue;
  }
  newValue = `${wholeNum}.${decimalNum}`;
  return newValue;
};

export const removeZeroFromStart = (value) => {
  let newValue = value;
  while (newValue.charAt(0) === "0") {
    newValue = newValue.substring(1);
  }
  return newValue;
};
