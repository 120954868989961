import React from "react";
import { req, useApiGet } from "react-reqq";
import Cookie from "js-cookie";
import store from "setup/store";
import * as c from "./constants";
import { getProfileData } from "./helpers";

export const useAuth = () => {
  const auth = useApiGet(c.AUTHENTICATION, {});
  React.useEffect(() => {
    const token = Cookie.get("_token");
    const type = getProfileData()?.role || "";
    req.set(c.AUTHENTICATION, {
      isAuthenticated: !!token,
      type,
    });
  }, []);
  return [
    typeof auth.isAuthenticated === "boolean", // TODO
    auth.isAuthenticated, // TODO
    auth.type, // TODO
    // true,
    // true,
    // "ROLE_SUBSCRIBER",
  ];
};

export const withAclReq = (requestByType, defaultRequest) => {
  const state = store.getState().api;
  const type = state[c.AUTHENTICATION]?.type;
  return {
    ...defaultRequest,
    ...requestByType(state)[type],
  };
};
