import React, { useEffect, Suspense, lazy } from "react";
import _ from "lodash";
import AOS from "aos";
import { req, useApiGet } from "react-reqq";
import { Link, Switch, Route } from "react-router-dom";
import Cookie from "js-cookie";

import { encryptObjectToString } from "modules/auth/helpers";
import { useAuth } from "modules/auth/hooks";
import { AUTHENTICATION } from "modules/auth/constants";
import { logout } from "modules/auth/actions";
import { showModal } from "modules/common/core-ui";
import ChangePasswordModal from "modules/partial/modals/ChangePasswordModal";
import ErrorBoundary from "modules/partial/containers/ErrorBoundary";
import Splash from "modules/partial/containers/Splash";
import { PROFILE } from "modules/profile/constants";
import Public from "./Public";

const Private = lazy(() => import("./Private"));
const PrivateSubscriber = lazy(() => import("./PrivateSubscriber"));
const PrivateApprover = lazy(() => import("./PrivateApprover"));
const PrivateMaker = lazy(() => import("./PrivateMaker"));
const PrivateInvalidUser = lazy(() => import("./PrivateInvalidUser"));
const PrivateFinance = lazy(() => import("./PrivateFinance"));
const PrivateSupport = lazy(() => import("./PrivateSupport"));

const ROUTE_BY_TYPE = {
  "SUPER ADMIN": Private,
  ADMIN: PrivateSubscriber,
  APPROVER: PrivateApprover,
  MAKER: PrivateMaker,
  FINANCE: PrivateFinance,
  SUPPORT: PrivateSupport,
};

const UnknownUserType = () => {
  return (
    <div className="h-screen w-screen flex bg-primary-500">
      <div className="m-auto p-8 rounded-xl border text-white text-center">
        <div className="mb-5">Your session has expired!</div>
        <Link className="btn light rounded-full lg" to="/" onClick={logout}>
          Go Back to Login Page
        </Link>
      </div>
    </div>
  );
};

function App() {
  const [isAppReady, isAuthenticated, authType] = useAuth();
  const profileData = useApiGet(`${PROFILE}.profileData`);

  const userIsInvalid = _.get(profileData, 'user_is_invalid', false) === true;
  const withInitPassword = (profileData?.init_password_reset ? profileData?.init_password_reset : "N").toUpperCase() === "N"; // still using default password (should reset)

  useEffect(() => {
    AOS.init({
      offset: 10,
      duration: 1000,
    })
  }, [])

  // Force change password user to not use the autogenerated password
  const handleForceChangePasswordModal = () => {
    if (_.isEmpty(profileData)) return;
    if (userIsInvalid) return;
    if (!withInitPassword || !isAuthenticated) return;
    const callback = (res) => {
      const resData = _.get(res, "response.data", {});
      const newData = {
        ...profileData,
        ...resData,
      };
      req.set(PROFILE, newData);
      const dataToken = encryptObjectToString(newData);
      Cookie.set("_profileData", dataToken);
    };
    showModal({
      title: "Change Password",
      align: "justify-center",
      size: "sm",
      content: (onClose) => (
        <ChangePasswordModal
          onClose={onClose}
          type="INITIAL"
          callback={callback}
        />
      ),
    });
  };

  const renderPrivate = React.useMemo(() => {
    const renderRoute = () => {
      const returnPrivate = () => {
        if (userIsInvalid) return PrivateInvalidUser;
        handleForceChangePasswordModal();
        return ROUTE_BY_TYPE[authType] || UnknownUserType;
      }
      const Home = returnPrivate();
      return (
        <div style={{ minWidth: "1280px" }}>
          <Home />
        </div>
      );
    };
    const handleLogout = () => {
      req.set(AUTHENTICATION, {
        isAuthenticated: true,
        type: "ROLE_LEAD_SUPPORT",
      });
      logout();
    };

    return (
      <Switch>
        <Route path="/authenticate">
          <div className="h-screen w-screen flex bg-primary-500">
            <div className="m-auto p-8 rounded-xl border text-white text-center flex flex-col">
              <div className="mb-5">An account is already logged in!</div>
              <Link className="btn light rounded-full lg mb-4" to="/">
                Keep me logged in!
              </Link>
              <Link
                className="btn light rounded-full lg"
                to="/logout"
                onClick={handleLogout}
              >
                Logout
              </Link>
            </div>
          </div>
        </Route>
        <Route>{renderRoute}</Route>
      </Switch>
    );
  }, [authType, userIsInvalid, profileData]); // eslint-disable-line

  if (!isAppReady) return null;

  return (
    <ErrorBoundary>
      {isAuthenticated ? (
        <Suspense fallback={<Splash />}>{renderPrivate}</Suspense>
      ) : (
        <Public />
      )}
    </ErrorBoundary>
  );
}

export default App;
