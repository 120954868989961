import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

export const FormRadioButton = ({
  disabled,
  formOnChange,
  formValue,
  id,
  label,
  name,
  selectionValue,
  subLabel,
  value,
  wrapperClassName,
  ...rest
}) => {
  return (
    <div className={clsx("w-full", wrapperClassName)}>
      <input
        type="radio"
        id={id}
        name={name}
        value={selectionValue}
        className="mr-2"
        onChange={(e) => !disabled && formOnChange(e.target.value)}
        checked={formValue === selectionValue}
        {...rest}
      />
      <label htmlFor={id} className={`${disabled ? "text-gray-400" : "text-gray-700"} text-sm cursor-pointer`}>
        {label}
      </label>
    </div>
  );
};
FormRadioButton.defaultProps = {
  disabled: false,
  formOnChange: () => {},
  formValue: "",
  label: "",
  subLabel: "",
  value: "",
  wrapperClassName: "",
};
FormRadioButton.propTypes = {
  disabled: PropTypes.bool,
  formOnChange: PropTypes.func,
  formValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  selectionValue: PropTypes.string,
  subLabel: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  wrapperClassName: PropTypes.string,
};

export default FormRadioButton;
