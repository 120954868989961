import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import clsx from "clsx";
import { FaCalendarAlt } from "react-icons/fa";
import { IconButton } from "modules/common/core-ui";
import { monthLabels } from "modules/common/dataset";
import { renderLabel } from "./FormHelper";

// const formatDate = (date, def = "", format = "MM-DD-YYYY") => {
//   try {
//     const endDate = moment(date, "MM-DD-YYYY HH:mm").format(format);
//     const defDate = moment(def, "MM-DD-YYYY HH:mm").format(format);

//     if (_.isEmpty(date) && date === "") {
//       return endDate === "";
//     }
//     const newDate = endDate === "Invalid date" ? defDate : endDate;
//     return newDate;
//   } catch (error) {
//     return def;
//   }
// };

const BorderedInput = (value) => {
  const returnFormat = () => {
    if (value.length === 10) {
      return moment(value).format("ll");
    }
    if (value.length === 7) {
      const monthDisplay = monthLabels[+value.substring(0, 2)];
      const yearDisplay = value.substring(3, 7);
      return `${monthDisplay} ${yearDisplay}`;
    }
    return value;
  };
  return (
    <button
      className="bg-white shadow-md border px-3 py-2 rounded-md w-full"
      type="button"
    >
      <div className="flex items-center text-sm w-full">
        <FaCalendarAlt className="text-primary-500" />
        <span className="pt-1 pl-2 flex items-center border-l ml-2 h-5">
          {returnFormat()}
        </span>
      </div>
    </button>
  );
};
const FormInput = (value, disabled) => {
  return (
    <button
      className={`${
        disabled ? `bg-gray-200` : `bg-white border`
      } flex rounded-lg items-center justify-between w-full input-wrapper`}
      type="button"
    >
      <div className="flex items-center text-sm w-full">
        <FaCalendarAlt className="text-primary-500" />
        <span className="pt-1 pl-2 flex items-center border-l ml-2">
          {value}
        </span>
      </div>
    </button>
  );
};

export const FormInputDate = ({
  label,
  subLabel,
  tooltip,

  disabled,
  formOnChange,
  formValue,
  isClearable,
  isLoading,
  name,
  required,
  renderType,
  showMonthYearPicker,
  showYearPicker,
  type,
  withTime,
  wrapperClassName,
  ...rest
}) => {
  const props = {
    label,
    subLabel,
    tooltip,

    disabled,
    formOnChange,
    formValue,
    isClearable,
    isLoading,
    name,
    required,
    renderType,
    showMonthYearPicker,
    showYearPicker,
    type,
    withTime,
    wrapperClassName,
    ...rest
  };
  const handleChangePicker = (e) => {
    formOnChange(e);
  };

  const renderDisplayDate = (date) => {
    if (withTime) {
      return moment(date).format("MM/DD/YYYY HH:mm");
    }
    if (showMonthYearPicker) {
      return moment(date).format("MM/YYYY");
    }
    if (showYearPicker) {
      return moment(date).format("YYYY");
    }
    const newDate = moment(date).format("MM/DD/YYYY");
    return newDate === "Invalid date" ? null : newDate;
  };

  const displayDate = renderDisplayDate(formValue);

  const renderContent = () => {
    switch (renderType) {
      case "BUTTON":
        return (
          <DatePicker
            name={name}
            selected={formValue}
            onChange={formOnChange}
            disabled={disabled || isLoading}
            showMonthYearPicker={showMonthYearPicker}
            showYearPicker={showYearPicker}
            customInput={
              <IconButton
                icon={FaCalendarAlt}
                aria-label="Select Date"
                size="xs"
                variant="gradient"
                variantColor="primary"
                disabled={disabled}
                isLoading={isLoading}
              />
            }
            {...rest}
          />
        );
      case "BORDERED":
        return (
          <DatePicker
            name={name}
            selected={formValue}
            onChange={handleChangePicker}
            disabled={disabled || isLoading}
            customInput={BorderedInput(displayDate)}
            showMonthYearPicker={showMonthYearPicker}
            showYearPicker={showYearPicker}
            {...rest}
          />
        );
      default:
        return (
          <DatePicker
            name={name}
            selected={formValue}
            onChange={handleChangePicker}
            disabled={disabled || isLoading}
            customInput={FormInput(displayDate, disabled)}
            showMonthYearPicker={showMonthYearPicker}
            showYearPicker={showYearPicker}
            {...rest}
          />
        );
    }
  };

  return (
    <div className={clsx("relative", wrapperClassName)}>
      {renderLabel(props)}
      {renderContent()}
    </div>
  );
};
FormInputDate.defaultProps = {
  label: "",
  subLabel: "",
  tooltip: "",

  disabled: false,
  formOnChange: () => {},
  formValue: null,
  isClearable: true,
  isLoading: false,
  renderType: "FORM", // FORM | BUTTON | BORDERED
  required: false,
  showMonthYearPicker: false,
  showYearPicker: false,
  type: "text",
  withTime: false,
  wrapperClassName: "",
};
FormInputDate.propTypes = {
  label: PropTypes.string,
  subLabel: PropTypes.string,
  tooltip: PropTypes.string,

  buttonOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  formOnChange: PropTypes.func,
  formValue: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
    PropTypes.oneOf([null]),
  ]),
  isClearable: PropTypes.bool,
  isLoading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  renderType: PropTypes.string,
  required: PropTypes.bool,
  showMonthYearPicker: PropTypes.bool,
  showYearPicker: PropTypes.bool,
  withTime: PropTypes.bool,
  wrapperClassName: PropTypes.string,
};

export default FormInputDate;
