import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { ModalMarker, ModalAlertMarker } from "modules/common/core-ui";
import ToastMarker from "react-geek-toast";
import App from "App";
import * as serviceWorker from "serviceWorker";
import store from "setup/store";
import history from "setup/history";
import "assets/theme/default.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-input-2/lib/style.css";

import "assets/styles/styles.css";
import "aos/dist/aos.css";

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <div className="text-gray-600">
        <App />
      </div>
      <ToastMarker />
      <ModalAlertMarker />
      <ModalMarker />
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
