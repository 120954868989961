import React from "react";
import Feature1 from "assets/img/feature-icons/feature-1.svg";
import Feature2 from "assets/img/feature-icons/feature-2.svg";
import Feature3 from "assets/img/feature-icons/feature-3.svg";
import LinesBG from "assets/img/banner-instruction-line-bg.svg";

const howCashboxWork = [
  {
    img: Feature1,
    label: "SIGN UP",
    content: "Sign up quickly with just a few details."
  },
  {
    img: Feature2,
    label: "LOAD WALLET",
    content: "Load Wallet to start using the cash disbursement platform."
  },
  {
    img: Feature3,
    label: "DISBURSE CASH",
    content: "Disburse cash using the Cashbox Platform."
  }
];

function Instructions() {
  return (
    <div className="container mx-auto">
      <div className="how-cashbox-works pt-20">
        <div
          className="pb-8 text-center"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <h1 className="text-xl text-secondary-500 font-semibold">
            How CashBox works
            <div className="title-title-underline bg-secondary-500 mx-auto mb-10" />
          </h1>
        </div>
        <div className="flex flex-wrap items-center justify-center text-center">
          {howCashboxWork.map((item, i) => {
            const isReversed = i % 2;
            return (
              <div
                key={item.label}
                className="w-full md:w-1/3 lg:w-1/4 mb-3 md:mb-0 px-5 md:px-3 lg:px-5 lg:flex lg:justify-center lg:py-5 relative"
                data-aos="fade-up"
                data-aos-delay={(100 + (150 * i)).toString()}
              >
                <img
                  className="hidden lg:block absolute m-auto"
                  alt=""
                  src={LinesBG}
                  style={{ maxWidth: "300px" }}
                />
                <div
                  className={`banner-card p-4 md:p-6 flex flex-wrap items-center z-10 ${
                    isReversed ? "flex-row-reverse" : ""
                  }`}
                >
                  <div className="w-1/4 md:w-full">
                    <img
                      className="inline-block h-20 md:h-28"
                      src={item.img}
                      alt=""
                    />
                  </div>
                  <div
                    className={`w-3/4 h-24 md:h-36 md:w-full md:text-center ${
                      isReversed
                        ? "text-right pr-4 md:pr-0"
                        : "text-left pl-4 md:pl-0"
                    }`}
                  >
                    <h3 className="text-lg text-secondary-500 font-semibold pt-0 md:pt-10 pb-2 md:pb-4">
                      {item.label}
                    </h3>
                    <p className="text-sm text-gray-600">{item.content}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Instructions;
