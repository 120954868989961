import React from "react";
import { renderData } from "../renderHelper";

const labelClass = "font-medium text-xs md:text-sm";
const valueClass = "ml-4 text-gray-400";

const cashbox = [
  {
    status: "OPTIONAL",
    title: "callback_url",
    dataType: "string",
    content: () => <><span className={labelClass}>value:</span><span className={valueClass}>URL</span></>,
    subContent: () => <>URL used by Cashbox for sending updates on sent disbursement</>
  },
];

const cebuana = [
  {
    status: "REQUIRED",
    title: "notification_mode",
    dataType: "string",
    content: () => <><span className={labelClass}>value:</span><span className={valueClass}>0</span></>,
    subContent: () => <>Parameter used for sending disbursement with no SMS</>
  },
  {
    status: "REQUIRED",
    title: "notification_mode",
    dataType: "string",
    content: () => <><span className={labelClass}>value:</span><span className={valueClass}>1</span></>,
    subContent: () => <>Parameter used for sending disbursement with SMS</>
  },
  {
    status: "REQUIRED",
    title: "disbursement_mode",
    dataType: "string",
    content: () => <><span className={labelClass}>value:</span><span className={valueClass}>2</span></>,
    subContent: () => <>Parameter used for disbursement thru Cebuana Pera Padala</>
  },
];

function SectionAPI() {
  return (
    <>
      <div className="w-full flex flex-col md:flex-row md:space-x-8 space-y-8 md:space-y-0">
        <div className="w-full">
          {/* HEADER */}
          <h2 id="appendix" className="main-title">Parameter List</h2>

          <h3 className="text-base font-medium text-gray-400">CASHBOX</h3>
          <div className="border-l pl-4 mt-5">
            {renderData(cashbox)}
          </div>

          <hr className="my-5" />

          <h3 className="text-base font-medium text-gray-400">CEBUANA</h3>
          <div className="border-l pl-4 mt-5">
            {renderData(cebuana)}
          </div>
        </div>
      </div>
    </>
  )
}

export default SectionAPI;
