import React from "react";
import PropTypes from "prop-types";
import { Button } from "modules/common/core-ui";
import AccessDeniedImg from "./access-denied.svg";

export const AccessDeniedModal = ({
  onCloseLabel,
  onClose,
  title,
  subtitle,
  content,
}) => {
  const returnContent = () => {
    if (typeof content === "string") {
      return content;
    }
    if (typeof content === "function") {
      return content();
    }
    return content;
  };

  return (
    <div className="w-full p-5 flex flex-col">
      <img alt="" src={AccessDeniedImg} className="h-32 mx-auto my-10" />
      <p className="text-red-500 text-center text-xl font-medium mb-3">{title}</p>
      <p className="text-gray-400 text-center w-72 mx-auto text-sm font-light mb-5">{subtitle}</p>
      <div className="text-sm text-gray-600 text-center w-full px-6 mb-10">{returnContent()}</div>
      <div className="w-1/2 mx-auto mb-4">
        <Button
          className="w-full rounded-full"
          variant="gradient"
          variantColor="primary"
          size="lg"
          onClick={onClose}
        >
          {onCloseLabel}
        </Button>
      </div>
    </div>
  );
}

AccessDeniedModal.defaultProps = {
  onCloseLabel: "Close",
  title: "Access Denied",
  subtitle: "Sorry you do not have permission to access this page",
};
AccessDeniedModal.propTypes = {
  onCloseLabel: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
};
