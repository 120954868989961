import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { Button, showModal } from "modules/common/core-ui";
import CashboxLogo from "assets/img/cashbox-logo.svg";
import TermsAndConditionModal from "modules/auth/modals/TermsAndConditionModal";
// import RegisterModal from "modules/auth/modals/RegisterModal";
import LoginModal from "modules/auth/modals/LoginModal";
import ForgotPasswordModal from "modules/auth/modals/ForgotPasswordModal";

const NAVIGATION = [
  { url: "/", label: "HOME", isExact: true },
  { url: "/for-developers", label: "FOR DEVELOPERS" },
  { url: "/contact-us", label: "CONTACT US" },
];

// export const handleRegisterModal = () => {
//   showModal({
//     title: "Partner with us",
//     className: "auth-modal-md",
//     content: (onClose) => (
//       <RegisterModal onClose={onClose} handleLoginModal={handleLoginModal} />
//     ),
//   });
// };

export const handlePartnerWithUs = (type) => {
  showModal({
    className: "auth-modal-lg",
    content: (onClose) => <TermsAndConditionModal onClose={onClose} type={type} />,
  });
};

export const handleForgotPasswordModal = () => {
  showModal({
    title: "",
    className: "auth-modal-md",
    content: (onClose) => <ForgotPasswordModal onClose={onClose} />,
  });
};

export const handleLoginModal = () => {
  showModal({
    title: "",
    className: "auth-modal-md",
    withTitleClose: false,
    content: (onClose) => <LoginModal onClose={onClose} />,
  });
};

function PublicHeader() {
  const [hasScrolled, setHasScrolled] = useState(false);
  const [toggle, setToggle] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    if (scrollTop > 70) {
      setHasScrolled(true)
      return;
    }
    setHasScrolled(false);
  };

  const location = useLocation();
  const history = useHistory();

  const handleToggleNavbar = (e) => {
    e.preventDefault();
    setToggle(!toggle);
  };

  const handleRegisterModal = () => {
    history.push("/contact-us");
  };

  const isHidden =
    location.pathname === "/login" || location.pathname === "/registration";

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    // document.body.addEventListener('click', handleCloseNavbar);
    // return () => {
    //   document.body.addEventListener('click', handleCloseNavbar);
    // };
  });

  return (
    <>
      {!isHidden && (
        <>
          {/* REGULAR NAV */}
          <div
            className={clsx(
              `fixed top-0 w-full z-50 px-5 hidden md:block`,
              hasScrolled ? 'bg-white h-20 shadow-lg' : 'bg-transparent h-28'
              )}
            style={{ transition: "300ms" }}
          >
            <div className="container mx-auto h-full">
              <div className="flex items-center self-center px-6 h-full">
                <NavLink exact to="/">
                  <img src={CashboxLogo} alt="" className="h-12" />
                </NavLink>
                <div className="flex items-center justify-center text-sm xl:text-sm md:font-normal w-full">
                  {NAVIGATION.map((nav) => (
                    <div key={nav.label} className="mr-5">
                      <NavLink
                        activeClassName="text-primary-500 font-medium"
                        to={nav.url}
                        exact={nav?.isExact}
                        onClick={() => setToggle(false)}
                      >
                        {nav.label}
                      </NavLink>
                    </div>
                  ))}
                </div>
                <div className="flex text-sm xl:text-sm md:font-normal justify-end">
                  <Button
                    className="rounded-full mr-3"
                    variant="solid"
                    variantColor="primary"
                    onClick={handleLoginModal}
                  >
                    LOGIN
                  </Button>
                  <Button
                    className="rounded-full"
                    variant="outline"
                    variantColor="primary"
                    onClick={handleRegisterModal}
                  >
                    PARTNER WITH US
                  </Button>
                </div>
              </div>
            </div>
          </div>

          {/* BURGER NAV */}
          <div
            className={clsx(
              `fixed top-0 w-full z-50 block md:hidden`,
              hasScrolled ? 'bg-white shadow-lg' : 'bg-transparent'
            )}
            style={{ transition: "300ms" }}
          >
            <div className="flex flex-wrap">
              <div
                className={`w-full flex items-center px-5 py-4 ${
                  toggle && "bg-white"
                }`}
              >
                <NavLink exact to="/">
                  <img alt="" src={CashboxLogo} className="h-8" />
                </NavLink>
                <a
                  role="button"
                  href="/"
                  id="hamburger-1"
                  className={`ml-auto hamburger${toggle ? " is-active" : ""}`}
                  onClick={handleToggleNavbar}
                >
                  <span className="line bg-primary-500" />
                  <span className="line bg-primary-500" />
                  <span className="line bg-primary-500" />
                </a>
              </div>
              {toggle && (
                <div
                  className="w-full flex flex-wrap bg-white p-6 pt-0"
                >
                  <div className="w-full">
                    {NAVIGATION.map((nav) => (
                      <NavLink
                        key={nav.label}
                        activeClassName="text-white font-medium bg-primary-500"
                        inactiveClassName="text-primary-500"
                        className="block py-2 px-5 rounded-md"
                        to={nav.url}
                        exact={nav?.isExact}
                        onClick={() => setToggle(false)}
                      >
                        {nav.label}
                      </NavLink>
                    ))}
                  </div>
                  <div className="w-full flex flex-wrap pt-3">
                    <div className="w-1/2 pr-1">
                      <Button
                        className="rounded-full w-full"
                        variantColor="primary"
                        onClick={handleLoginModal}
                      >
                        LOGIN
                      </Button>
                    </div>
                    <div className="w-1/2 pl-1">
                      <Button
                        className="rounded-full w-full"
                        variant="outline"
                        variantColor="primary"
                        onClick={handleRegisterModal}
                      >
                        PARTNER WITH US
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default PublicHeader;
