import React from "react";
import Financial from "assets/img/icon-financial.svg";
import Private from "assets/img/icon-private.svg";
import Government from "assets/img/icon-government.svg";

const list = [
  {
    img: Financial,
    label: "Financial Firm",
    content:
      "It will ease the old finance system to a better way by using Cashbox Platform."
  },
  {
    img: Private,
    label: "Private Companies",
    content:
      "Can be use by private companies to ease the burden on manual disbursement of cash."
  },
  {
    img: Government,
    label: "Government Agencies",
    content:
      "Will be useful not only to private but to government agencies with old manual cash disbursement."
  }
];

function WhoCanUse() {
  return (
    <div className="container mx-auto">
      <div className="py-20 lg:py-28">
        <div
          className="pb-10 text-center font-semibold"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <h1 className="text-xl text-secondary-500 font-semibold">
            Who can use Cashbox
          </h1>
          <div className="title-underline bg-secondary-500 mx-auto mb-10" />
        </div>
        <div className="flex flex-wrap items-center justify-center text-center">
          {list.map((item, i) => (
            <div
              key={item.label}
              className="w-full md:w-1/3 mb-8 md:mb-0 px-5 md:px-3 lg:px-5"
              data-aos="fade-up"
              data-aos-delay={(100 + (150 * i)).toString()}
            >
              <div className="flex flex-wrap">
                <div className="w-full md:w-full lg:w-1/4 md:pr-3 flex justify-center mb-3">
                  <img
                    className="inline-block w-10 md:w-12 lg:w-12"
                    src={item.img}
                    alt=""
                  />
                </div>
                <div className="w-full md:w-full lg:w-3/4 text-center lg:text-left">
                  <h3 className="font-semibold mb-2 color-secondary">
                    {item.label}
                  </h3>
                  <p className="text-sm text-gray-600">{item.content}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default WhoCanUse;
