import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Field,
  FormInputTextArea,
  FormWrapper,
} from "modules/common/core-ui";

const validationSchema = (yup) =>
  yup.object().shape({
    remarks: yup.string().required().label("Remarks"),
  });

export const ConfirmAlertRemarksModal = ({
  label,
  onCloseLabel,
  onYesLabel,
  onClose,
  onYes,
  disabled,
  inputLabel,
  required,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const handleProceed = (formData) => {
    setIsDisabled(true);
    onYes(formData);
    onClose();
  };
  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);
  return (
    <div className="w-full p-5">
      <div className="w-full mb-4 text-sm text-gray-900">{label}</div>
      <FormWrapper
        validation={required ? validationSchema : () => {}}
        onSubmit={handleProceed}
        className="w-full"
      >
        <Field
          component={FormInputTextArea}
          name="remarks"
          label={inputLabel}
          rows={4}
          autoFocus
          required={required}
        />
        <div className="w-full mt-6 flex flex-row space-x-4">
          <Button
            className="w-full rounded-full"
            variant="outline"
            variantColor="primary"
            onClick={onClose}
          >
            {onCloseLabel}
          </Button>
          <Button
            className="w-full rounded-full"
            variant="gradient"
            variantColor="primary"
            disabled={isDisabled}
            type="submit"
          >
            {onYesLabel}
          </Button>
        </div>
      </FormWrapper>
    </div>
  );
}

ConfirmAlertRemarksModal.defaultProps = {
  onCloseLabel: "Cancel",
  onYesLabel: "Proceed",
  disabled: false,
  inputLabel: "Remarks",
  required: true,
};
ConfirmAlertRemarksModal.propTypes = {
  label: PropTypes.string.isRequired,
  onCloseLabel: PropTypes.string.isRequired,
  onYesLabel: PropTypes.string.isRequired,
  onClose: PropTypes.instanceOf(Function).isRequired,
  onYes: PropTypes.instanceOf(Function).isRequired,
  disabled: PropTypes.bool.isRequired,
  inputLabel: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
};
