import React from "react";
import PropTypes from "prop-types";
import has from "lodash/has";
import get from "lodash/get";
import { useFormContext, Controller } from "react-hook-form";
import clsx from "clsx";
import { DisplayErrorMessage } from "./ShowError";

export const Field = ({
  component,
  name,
  hideError,
  renderError,
  containerClass,
  value,
  autoFillOff,
  bottomLabel,
  ...props
}) => {
  const { control, errors } = useFormContext();
  let extraProps = {};
  if (autoFillOff) {
    extraProps = {
      autoComplete: "off",
      tabIndex: "-1",
      autoFocus: false,
    };
  }
  const withError = has(errors, name);
  const displayError = () => {
    if (renderError && typeof renderError === "function")
      renderError({ error: errors[name]?.message || "" });

    return DisplayErrorMessage(get(errors, `${name}.message`, "Error"))
  };

  const renderChild = ({ onChange, ...val }) => {
    const Input = component;
    if (typeof component === "function")
      return (
        <>
          <Input
            formOnChange={onChange}
            formValue={val.value}
            name={name}
            {...props}
          />
          {bottomLabel && (
            <div className="text-gray-400 text-xs font-light italic">
              {bottomLabel}
            </div>
          )}
        </>
      );
    return <div />;
  };

  // FOR TOO HARD TO CONTROL AUTOFILL
  const renderAutoFillBlocker = () => {
    const Input = component;

    return (
      <div className={clsx("fixed opacity-0 h-0 w-0", containerClass)}>
        <Input
          formOnChange={() => {}}
          formValue=""
          name={name}
          {...props}
          {...extraProps}
        />
      </div>
    );
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={value}
        render={(renderProps) => (
          <div className={clsx("w-full", containerClass)}>
            {autoFillOff && renderAutoFillBlocker()}
            {renderChild(renderProps)}
            {withError && !hideError && displayError()}
          </div>
        )}
      />
    </>
  );
}

Field.defaultProps = {
  value: "",
  hideError: false,
  renderError: null,
  containerClass: "",
  autoFillOff: false,
  bottomLabel: "",
};

Field.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  containerClass: PropTypes.string,
  renderError: PropTypes.func,
  hideError: PropTypes.bool,
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  bottomLabel: PropTypes.string,
};
