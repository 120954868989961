import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { FaSpinner, FaCube } from "react-icons/fa";
import {
  defaultButtonStyle,
  buttonBaseStyle,
  buttonVariant,
  buttonSize,
} from "./theme";
import { disabledClass } from "./constants";

const buttonIcon = (Icon, style) => {
  const iconProps = {
    "aria-hidden": true,
    focusable: false,
    className: style,
  };

  return React.isValidElement(Icon) ? (
    React.cloneElement(Icon, iconProps)
  ) : (
    <Icon {...iconProps} />
  );
};

export const IconButton = ({
  size,
  unStyled,
  variant,
  variantColor,

  type,
  className,
  disabled,
  onClick,

  isLoading,
  isLoadingRender,
  icon,
  ...rest
}) => {
  const classNames = !unStyled
    ? clsx(
      defaultButtonStyle,
      buttonBaseStyle,
      buttonVariant(variantColor)[variant],
      buttonSize[size],
      className,
      disabled && disabledClass
  ) : clsx(defaultButtonStyle, className);

  const clickable = !isLoading && !disabled;

  return (
    <button
      type={type}
      className={classNames}
      onClick={clickable ? onClick : () => {}}
      disabled={isLoading || disabled}
      {...rest}
    >
      {isLoading
        ? isLoadingRender()
        : buttonIcon(icon)
      }
    </button>
  );
};

IconButton.defaultProps = {
  size: "md",
  unStyled: false,

  type: "button",
  className: "",
  disabled: false,
  onClick: () => {},

  isLoading: false,
  isLoadingRender: () => <FaSpinner className="animate-spin" />,

  icon: <FaCube />,
};

IconButton.propTypes = {
  size: PropTypes.string,
  unStyled: PropTypes.bool,
  variant: PropTypes.string.isRequired,
  variantColor: PropTypes.string.isRequired,

  type: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,

  isLoading: PropTypes.bool,
  isLoadingRender: PropTypes.func,

  icon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
};
