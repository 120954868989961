import Crypto from "crypto-js";
import has from "lodash/has";
import Cookies from "js-cookie";
import { HASHPASS } from "./constants";

export const encryptObjectToString = (data = "") =>
  Crypto.AES.encrypt(JSON.stringify(data), HASHPASS).toString();

export const decryptStringToObject = (data) => {
  if (!data) return {};
  const bytes = Crypto.AES.decrypt(data, HASHPASS);
  return JSON.parse(bytes.toString(Crypto.enc.Utf8));
};

export const getProfileData = () => {
  const token = Cookies.get("_profileData");
  return decryptStringToObject(token);
};

export const getProfileRole = () => {
  const token = Cookies.get("_profileRole");
  return decryptStringToObject(token);
};

export const checkACL = (allowed = []) => {
  const profileAccess = getProfileRole();
  if (allowed?.includes(profileAccess?.role_name)) return true; // check if allowed by role name
  // console.log(profileAccess);
  const isAllowed = allowed.some((acc) => {
    // per single role.
    if (has(profileAccess, acc)) {
      if (profileAccess[acc] === "Y") {
        return true;
      }
    }
    return false;
  });
  if (isAllowed) return true;
  return false;
};
