import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

export const FormInputOTP = ({ name, value, onChange, pinClassName, ...rest }) => {
  const inputRef = useRef();
  const keepFocus = () => {
    try {
      inputRef.current.focus();
    } catch (err) {
      // do nothing...
    }
  };
  useEffect(() => {
    keepFocus();
  }, []);

  const handleChange = ({ target }) => {
    target.value.length < 7 && onChange(target.value);
  };
  return (
    <div className="flex items-center justify-center">
      <input
        type="number"
        name={name}
        className="absolute opacity-0"
        onBlur={keepFocus}
        ref={inputRef}
        value={value}
        onChange={handleChange}
        {...rest}
      />
      <div className="flex items-center">
        <div className={pinClassName}>{value[0] || ""}</div>
        <div className={pinClassName}>{value[1] || ""}</div>
        <div className={pinClassName}>{value[2] || ""}</div>
        <div className={pinClassName}>{value[3] || ""}</div>
        <div className={pinClassName}>{value[4] || ""}</div>
        <div className={pinClassName}>{value[5] || ""}</div>
      </div>
    </div>
  );
}

FormInputOTP.defaultProps = {
  value: [],
  pinClassName:
    "w-8 text-center h-12 text-3xl mr-2 border-2 rounded border-gray-200 text-gray-800",
};

FormInputOTP.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pinClassName: PropTypes.string,
};

export default FormInputOTP;
