import React from "react";
import { useHistory } from "react-router-dom";
import DevMsys from "assets/img/dev-msys.svg";
import { Button } from "modules/common/core-ui";
import { handlePartnerWithUs } from "modules/partial/components/PublicHeader";

const NAVIGATION = [
  { url: "/", label: "HOME", isExact: true },
  { url: "/for-developers", label: "FOR DEVELOPERS" },
  { url: "/contact-us", label: "CONTACT US" },
  // { url: "/terms-and-condition", label: "TERMS AND CONDITION" }
];

const navClass = "w-full md:w-auto text-white text-sm md:font-normal md:ml-5 mb-2 md:mb-0";

function PublicFooter() {
  const history = useHistory();

  const handleNavigate = (url) => {
    history.push(url);
  };

  return (
    <footer className="bg-primary-500 w-full mx-auto py-5 text-white">
      <div className="container mx-auto">
        <div className="flex flex-wrap items-center self-center px-6 py-3">
          <img src={DevMsys} alt="" className="mb-5 md:mb-0" />
          <div className="w-full flex flex-wrap md:w-auto ml-0 md:ml-auto">
            {NAVIGATION.map((nav) => (
              <Button
                key={nav.label}
                variant="ghost"
                variantColor="white"
                size="custom"
                className={navClass}
                onClick={() => handleNavigate(nav.url)}
              >
                {nav.label}
              </Button>
            ))}
            <Button
              variant="ghost"
              variantColor="white"
              size="custom"
              className={navClass}
              onClick={() => handlePartnerWithUs('view')}
            >
              TERMS AND CONDITION
            </Button>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default PublicFooter;
