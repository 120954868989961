import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { TooltipIcon } from "modules/common/core-ui";

const minWidth = 200;

export const DropdownButton = ({ label, options, width, className }) => {
  const [open, setOpen] = React.useState(false);
  const close = () => setOpen(false);
  return (
    <div className="relative table">
      <button
      type="button"
        style={{ width, minWidth }}
        onClick={() => setOpen(!open)}
        className={clsx(
          "rounded-lg bg-primary-500",
          className
        )}
      >
        <div className="flex justify-between items-center">
          <span className="text-white pr-2 text-sm">{label}</span>
          <div className="text-white h-full w-6 outline-none px-2 border-l border-gray-200"            >
            {open ? <FaCaretUp /> : <FaCaretDown />}
          </div>
        </div>
      </button>
      {open && options?.length > 0 && (
        <div
          className="w-auto py-1 absolute bg-white border rounded shadow-lg"
          style={{ minWidth, top: 50, right: 0, zIndex: 9999 }}
        >
          {options?.map((opt, i) => (
            <button
              key={i}
              type="button"
              className="w-full px-2 py-1 outline-none flex items-center relative hover:bg-gray-100"
              onClick={() =>
                typeof opt?.onClick === "function" ? opt?.onClick(close) : null
              }
              style={{
                outline: "none",
                outlineColor: "none",
              }}
            >
              {opt?.label || "--"}
              {opt?.tooltip && (
                <div className="ml-auto pl-2">
                  <TooltipIcon
                    size="sm"
                    content={opt?.tooltip}
                    key={opt?.tooltip}
                    tooltipType="QUESTION"
                    name={`${opt?.tooltip}-tooltip`}
                  />
                </div>
              )}
            </button>
          )) || null}
        </div>
      )}
    </div>
  );
}

DropdownButton.defaultProps = {
  options: [],
  width: null,
  tooltip: "",
  className: "p-2",
};

DropdownButton.propTypes = {
  width: PropTypes.number,
  tooltip: PropTypes.string,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.element,
      ]),
      onClick: PropTypes.func,
      tooltip: PropTypes.string,
    })
  ),
  className: PropTypes.string,
};
