import React, { useContext } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { SkeletonLoader } from "modules/common/core-ui";
import { renderLabel, ViewContext } from "./GenericView";
import { defaultProps } from "./constants";

export const ViewItem = ({
  label,
  labelClassName,
  name,
  className,
  cellClassName,
  renderContent,
  renderValue,
  isLoading,
}) => {
  const { data, labelWidth } = useContext(ViewContext);
  const val =
    typeof _.get(data, name) === "undefined" ? "-" : _.get(data, name);
  const value = typeof name === "function" ? name(data) : val;

  const render = () => {
    if (renderContent) return renderContent();
    if (renderValue) return renderValue(value);
    return value;
  };

  return (
    <tr>
      {renderLabel({
        cellClassName,
        label,
        labelClassName,
        labelWidth,
      })}

      <td className={`${className} ${cellClassName}`}>
        {(_.isEmpty(`${value}`) || typeof value === "object") && typeof name !== "function"
          ? <span>--</span>
          : (
          <div style={{ wordBreak: "break-word" }}>
            {isLoading
              ? <SkeletonLoader isLoaded={false} className="h-5 w-full rounded" />
              : render()
            }
          </div>
        )}
      </td>
    </tr>
  );
};

ViewItem.defaultProps = defaultProps;

ViewItem.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  labelClassName: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  className: PropTypes.string,
  cellClassName: PropTypes.string,
  isLoading: PropTypes.bool,
  renderContent: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
};
