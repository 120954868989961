import React from "react";
import PropTypes from "prop-types";

export const ViewContext = React.createContext();

export const renderLabel = (props, rest) => {
  const cellClassName = props?.cellClassName || "";
  const label = props?.label || "";
  const labelClassName = props?.labelClassName || "";
  const labelWidth = props?.labelWidth || "";

  return label
    ? (
      <td
        className={`${labelClassName} ${cellClassName}`}
        width={labelWidth}
        {...rest}
      >
        {typeof label === "function" && <>{label()}</>}
        {typeof label === "string" && <>{label}</>}
      </td>
    ) : <td />
};

export const GenericView = ({ data, labelWidth, children }) => {
  return (
    <ViewContext.Provider value={{ data, labelWidth }}>
      <table className="table-auto w-full">
        <tbody>{children}</tbody>
      </table>
    </ViewContext.Provider>
  );
}

GenericView.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.instanceOf(Object),
  labelWidth: PropTypes.string,
};

GenericView.defaultProps = {
  children: null,
  labelWidth: "auto",
};
