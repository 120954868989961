import { configureApi } from "react-reqq";
import Cookie from "js-cookie";
import { get } from "lodash";
import { toastError, toastWarning } from "react-geek-toast";
import history from "./history";

export const rootApi = process.env.REACT_APP_END_POINT;
const store = configureApi({
  endpoint: process.env.REACT_APP_END_POINT,
  requestHeaders: () => {
    const token = Cookie.get("_token");
    if (!token) return {};
    return {
      Authorization: `${token}`,
    };
  },
  timeout: 180000,
  // cacheStorage: "WEBSQL",
  onError: (err) => {
    const err_message = get(err, "response.message");
    if (err.status === 422) {
      const key = Object.keys(get(err, "response.errors"))[0];
      const message = ((err?.response?.errors || {})[key] || {})[0];
      toastError(message || "Unprocessable Entity!");
      return;
    }
    if (err.status === 400) {
      toastError(err_message || "Bad Request!");
      return;
    }
    if (err.status === 401) {
      toastError(err_message || "Unauthorized!");
      history.push("/logout?delay=3000");
      return;
    }
    if (err.status === 429) {
      toastWarning("You are going to fast! Please wait and try again.");
      return;
    }
    if (err.status === 500) {
      toastError(err_message || "Internal Server Error!");
      return;
    }
    if (err.status === 403) {
      toastError(err_message || "Forbidden!");
      if (
        get(err, "response.error") === "token_expired" ||
        get(err, "response.error") === "token_invalid" ||
        // get(err, 'response.error') === 'token_mismatch' ||
        get(err, "response.error") === "token_missing"
      )
        history.push("/logout?delay=3000");
      return;
    }
    if (err.status === 0) {
      toastError("Unable to connect to web service");
      return;
    }
    toastError(err_message);
  },
});

export default store;
