import React from "react";

export const returnBadge = (status) => {
  switch (status) {
    case "REQUIRED":
      return <span className="bg-orange-100 text-orange-400 text-xxs md:text-xs px-2 md:px-3 py-1 rounded-md font-medium">REQUIRED</span>;
    case "OPTIONAL":
      return <span className="bg-gray-200 text-gray-500 text-xxs md:text-xs px-2 md:px-3 py-1 rounded-md font-medium">OPTIONAL</span>;
    case "GET":
      return <span className="bg-lime-500 text-white px-3 py-1 rounded">GET</span>;
    case "POST":
      return <span className="bg-blue-400 text-white px-3 py-1 rounded">POST</span>;
    default:
      return '';
  }
};

export const renderData = (list) => (
  list.map((item, index) => (
    <div key={`main-${item.title}-${index}`}>
      {(index > 0 && !item?.noBar) &&  <hr className="my-5" />}
      <div className="mb-5">
        {item?.status && <span className="mr-4">{returnBadge(item.status.toUpperCase())}</span>}
        {item?.title && <span className="mr-4 font-medium text-xs md:text-sm">{item.title}</span>}
        {item?.dataType && <span className="mr-4 text-gray-400 text-xs md:text-sm">{item.dataType}</span>}
      </div>
      {item?.content && <p className="content">{item.content()}</p>}
      {item?.subContent && <p className="content mt-5">{item.subContent()}</p>}
      {item?.list && (
        <div className="border-l pl-4 mt-5">
          <h3 className="text-sm font-medium text-gray-400 mb-5">CHILD ATTRIBUTES</h3>
          {item.list.map((i, j) => (
            <div key={`content-${i.title}-${j}`}>
              <p className="text-sm mb-8">
                {j?.status && <span className="mr-4">{returnBadge(item.j.toUpperCase())}</span>}
                {j?.title && <span className="mr-4 font-medium text-xs md:text-sm">{item.j}</span>}
                {j?.dataType && <span className="mr-4 text-gray-400 text-xs md:text-sm">{item.j}</span>}
              </p>
              {i?.content && <p className="content">{i.content()}</p>}
            </div>
          ))}
        </div>
      )}
    </div>
  ))
);
// status
// title
// dataType
// content
// noBar